/** @jsx jsx */
import React, { useRef } from 'react';
import { ClassNames, css, jsx } from '@emotion/core';
import { CSSTransition } from 'react-transition-group';
import { pageTransitionFadeIn, pageTransitionFadeOut } from 'style/animation';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import { RootState } from '../../../store/store';
import step1AnimationData from './lottie/step1.json';
import step2AnimationData from './lottie/step2.json';
import step3AnimationData from './lottie/step3.json';

const transitionFadeIn = css(pageTransitionFadeIn);
const transitionFadeOut = css(pageTransitionFadeOut);
const transitionContentsWrapper = css`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface IProgressImageProps {
  isStep1Visible: boolean;
  isStep2Visible: boolean;
  isStep3Visible: boolean;
}

interface IStepNode {
  show: boolean;
  callBack?: {
    onEntered: () => void;
    onExit: () => void;
    onExiting: () => void;
    onExited: () => void;
  };
}

const wrapper = css`
  width: 100%;
  height: 100%;
`;

const imgWrapper = css`
  width: 52%;
  height: 74%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
`;

// heightは最終的に手で調整(AfterAffectsの余白との調整)
const lottieWrapper = css`
  ${imgWrapper}
  width: 90%;
  height: 100%;
  z-index: 100;
`;

const imageWrap = css`
  ${imgWrapper}
`;

export const ProgressImage: React.FC<IProgressImageProps> = (props) => {
  const { isStep1Visible, isStep2Visible, isStep3Visible } = props;

  const imageData = useSelector((state: RootState) => state.image.imageData);

  const defaultOptions = {
    loop: true,
    autoplay: true,
  };

  // transitionアニメーションを参照するエレメントを参照する
  const targetRef1 = useRef(null);
  const targetRef2 = useRef(null);
  const targetRef3 = useRef(null);

  return (
    <div css={wrapper}>
      <ClassNames>
        {({ css: className }) => (
          <CSSTransition
            key="step1"
            appear
            in={isStep1Visible}
            timeout={1000}
            // 参照するDOMを指定
            nodeRef={targetRef1}
            classNames={{
              appearActive: className(transitionFadeIn),
              enterActive: className(transitionFadeIn),
              exitActive: className(transitionFadeOut),
            }}
            // inがtrueになるまでマウントしない
            mountOnEnter
            // inがfalseになったらアンマウントする
            unmountOnExit
          >
            <div css={transitionContentsWrapper}>
              <div css={lottieWrapper} ref={targetRef1}>
                <Lottie
                  options={{
                    ...defaultOptions,
                    animationData: step1AnimationData,
                  }}
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </CSSTransition>
        )}
      </ClassNames>
      <ClassNames>
        {({ css: className }) => (
          <CSSTransition
            key="step2"
            appear
            in={isStep2Visible}
            timeout={1000}
            // 参照するDOMを指定
            nodeRef={targetRef2}
            classNames={{
              appearActive: className(transitionFadeIn),
              enterActive: className(transitionFadeIn),
              exitActive: className(transitionFadeOut),
            }}
            // inがtrueになるまでマウントしない
            mountOnEnter
            // inがfalseになったらアンマウントする
            unmountOnExit
          >
            <div css={transitionContentsWrapper}>
              <div css={lottieWrapper} ref={targetRef2}>
                <Lottie
                  options={{
                    ...defaultOptions,
                    animationData: step2AnimationData,
                  }}
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </CSSTransition>
        )}
      </ClassNames>
      <ClassNames>
        {({ css: className }) => (
          <CSSTransition
            key="step3"
            appear
            in={isStep3Visible}
            timeout={1000}
            // 参照するDOMを指定
            nodeRef={targetRef3}
            classNames={{
              appearActive: className(transitionFadeIn),
              enterActive: className(transitionFadeIn),
              exitActive: className(transitionFadeOut),
            }}
            // inがtrueになるまでマウントしない
            mountOnEnter
            // inがfalseになったらアンマウントする
            unmountOnExit
          >
            <div css={transitionContentsWrapper}>
              <div css={lottieWrapper} ref={targetRef3}>
                <Lottie
                  options={{
                    ...defaultOptions,
                    animationData: step3AnimationData,
                  }}
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </CSSTransition>
        )}
      </ClassNames>
      <img css={imageWrap} src={imageData} alt="logo" />
    </div>
  );
};
