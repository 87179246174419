import axios from 'axios';
import { convertCamelToSnake, convertSnakeToCamel } from './ObjectUtils';

const Axios = axios.create();
Axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;

// リクエストの共通処理
Axios.interceptors.request.use((request) => {
  if (request.params) {
    convertCamelToSnake(request.params);
  }
  if (request.data) {
    convertCamelToSnake(request.data);
  }

  return request;
});

// レスポンスの共通処理
Axios.interceptors.response.use((response) => {
  if (response.data) {
    convertSnakeToCamel(response.data);
  }

  return response;
});

export default Axios;
