/** @jsx jsx */
import React, {
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  memo,
} from 'react';
import { css, jsx } from '@emotion/core';
import { HEADER_HEIGHT_SP, HEADER_HEIGHT_PC } from 'style/common';
import useMedia from 'hooks/useMedia';
import { BackgroundVideo } from 'components/pages/top/BackgroundVideo';
import { Contents } from 'components/pages/top/Contents';

const videoWrapper = css`
  position: fixed;
  top: 0;
  height: 100%;
  z-index: -100;
`;

interface ITopProps {
  setIsHeaderTransparent: Dispatch<SetStateAction<boolean>>;
  openYoutubePopup: () => void;
  openYourFlowerPopup: () => void;
}

const Top: React.FC<ITopProps> = memo((props) => {
  /** 背景動画の透明度 */
  const [backgroundOpacity, setBackgroundOpacity] = useState<number>(1);
  /** ページ下部に固定リンクボタンを表示するかどうか. SPのみ. */
  const [isVisibleFixedLinkButton, setIsVisibleFixedLinkButton] = useState<
    boolean
  >(false);
  /** SPサイズかどうか */
  const { isSP } = useMedia();

  /** ファーストビューの動画の透明度を変化させるためにスクロールを監視する */
  const watchScroll = useCallback(() => {
    // 現在のスクロール位置.ブラウザによって取る値が違うので全部取って最大値を算出.
    const scroll = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    );

    // 一番上のときはヘッダ透明、下にスクロールしたらヘッダは黒色にする
    if (scroll > (isSP ? HEADER_HEIGHT_SP : HEADER_HEIGHT_PC)) {
      props.setIsHeaderTransparent(false);
    } else {
      props.setIsHeaderTransparent(true);
    }

    // (SPのみ)ファーストビューのときは画面下部のボタンを固定表示する.
    if (isSP) {
      const shouldVisibleFixedLink = scroll > window.innerHeight;
      setIsVisibleFixedLinkButton(shouldVisibleFixedLink);
    }

    // 背景動画の透明度を算出する
    const opacity = 1 - scroll / window.innerHeight;
    const opacityCeild = Math.ceil(opacity * 10) / 10;
    setBackgroundOpacity(opacityCeild);
  }, [isSP, props]);

  /**
   * スクロールを監視する
   * アンマウント時には監視を止める
   */
  useEffect(() => {
    window.addEventListener('scroll', watchScroll, true);

    return () => {
      window.removeEventListener('scroll', watchScroll, true);
    };
  }, [watchScroll]);

  /**
   * TODO: 適切なdeps設定
   * TOPに入った時にヘッダの背景を透明にして、出た時に黒に戻したくてこう書いているがESLintの警告が出ているので本当にこれでいいのか確認する.
   * ちなみにdeps書いたら、スクロールで再レンダリングが起きてチカチカする
   **/
  useEffect(() => {
    props.setIsHeaderTransparent(true);

    return () => {
      props.setIsHeaderTransparent(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={css({ height: '100%' })}>
      <div
        css={videoWrapper}
        style={{
          opacity: backgroundOpacity,
        }}
      >
        <BackgroundVideo />
      </div>
      <Contents
        isVisibleFixedLinkButton={isVisibleFixedLinkButton}
        openYoutubePopup={props.openYoutubePopup}
        openYourFlowerPopup={props.openYourFlowerPopup}
      />
    </div>
  );
});

export default Top;
