/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { PROCESS_TYPE } from '../../../constants/enum';
import { UserAgentTab } from './UserAgentTab';

interface IUserAgentTabsSectionProps {
  userAgent: PROCESS_TYPE;
  changeAndroid: () => void;
  changeIos: () => void;
  changePrint: () => void;
}

const tabs = css`
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`;

export const UserAgentTabsSection: React.FC<IUserAgentTabsSectionProps> = memo(
  (props) => {
    const { userAgent, changeAndroid, changeIos, changePrint } = props;
    /** 多言語文言用 */
    const { t } = useTranslation();

    return (
      <div css={tabs}>
        <UserAgentTab
          text={t('process.tab_list.ios')}
          selectUserAgent={userAgent === PROCESS_TYPE.IOS}
          handleClick={changeIos}
        />
        <UserAgentTab
          text={t('process.tab_list.android')}
          selectUserAgent={userAgent === PROCESS_TYPE.ANDROID}
          handleClick={changeAndroid}
        />
        <UserAgentTab
          text={t('process.tab_list.print')}
          selectUserAgent={userAgent === PROCESS_TYPE.PRINT}
          handleClick={changePrint}
        />
      </div>
    );
  },
);
