/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import {
  HeadingSection,
  TextureList,
  FixedLinkButtons,
} from 'components/partials';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import useTexture from 'hooks/useTexture';
import { pageTransitionFadeIn } from 'style/animation';
import { useTranslation } from 'react-i18next';
import useMedia from 'hooks/useMedia';

const container = css`
  ${pageTransitionFadeIn}
`;

const wrapper = css`
  margin: 64px 120px;
  width: calc(100% - 120px * 2);
  ${MEDIA_QUERY.TB} {
    margin: 64px 60px;
    width: calc(100% - 60px * 2);
  }
  ${MEDIA_QUERY.SP} {
    margin: 50px 20px 70px;
    width: calc(100% - 20px * 2);
  }
`;

interface ITexturesProps {
  openYoutubePopup: () => void;
}

const Textures: React.FC<ITexturesProps> = (props) => {
  const { textures, loadTextures } = useTexture();
  /** 多言語文言用 */
  const { t } = useTranslation();
  /** SPサイズかどうか */
  const { isSP } = useMedia();

  /**
   * 最新の画像が表示されるようにマウント後に画像をサーバから再取得しておく
   */
  useEffect(() => {
    loadTextures();
  }, [loadTextures]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={container}>
      <HeadingSection title={t('live.title')} isWide />
      <div css={wrapper}>
        <TextureList textures={textures} />
      </div>
      {isSP && (
        <FixedLinkButtons openYoutubePopup={props.openYoutubePopup} isVisible />
      )}
    </div>
  );
};

export default Textures;
