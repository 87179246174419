import { useEffect, useRef } from 'react';

/**
 * Queue確認用のpolling処理
 * 参考(https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197)
 */
const useInterval = (
  callback: () => void,
  delay: number,
  isIntervalFinished: boolean,
  canExec: boolean,
): void => {
  /** useRefでからの初期値をsavedCallbackにセットする */
  const savedCallback = useRef<() => void>();

  /** useRef内に引数でもらったcallbackを保持しておく */
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  /** インターバル処理 */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) savedCallback.current();
    };

    /* 未完了で、かつdelayの値が入っていて、かつ、goサイン出た時のみsetIntervalする */
    if (delay !== null && !isIntervalFinished && canExec) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [canExec, delay, isIntervalFinished]);
};

export default useInterval;
