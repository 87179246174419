/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';

interface IProcessContentsProps {
  icon?: string;
  text: string;
}

const contents = css`
  margin-top: 10px;
  font-size: 15px;
  line-height: 2;
  color: #fff;
  white-space: break-spaces;
`;

const inlineImg = css`
  width: 20px;
  height: 20px;
  margin: 0 5px;
  vertical-align: middle;
`;

/** 文字列の中に組み込むimgの箇所を示す値 */
const IMAGE_POINT = 'img';

export const ProcessContents: React.FC<IProcessContentsProps> = memo(
  (props) => {
    const { text, icon } = props;

    const splitForInsertImg = text.split('$');

    const fixedText = () => {
      return splitForInsertImg
        .filter((str) => str)
        .map((x: string, index: number) => {
          return (
            <span key={`${x}${index.toString()}`}>
              {x !== IMAGE_POINT ? (
                <span>{x}</span>
              ) : (
                <img
                  css={inlineImg}
                  /* TODO: #53 他のuserAgentの対応 */
                  src={icon}
                  alt="inlineImg"
                />
              )}
            </span>
          );
        });
    };

    return <div css={contents}>{fixedText()}</div>;
  },
);
