/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

interface IButtonProps {
  text: string;
  handleClick: () => void;
  disable?: boolean;
  width?: string;
}

const wrapperBase = css`
  width: 28%;
  height: 20px;
  line-height: 20px;
  padding: 15px 60px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #141414;
  font-weight: bold;
  font-size: 15px;

  ${MEDIA_QUERY.SP} {
    padding: 10px 20px;
    width: calc(100% - 20px * 2);
  }
`;

// TODO: サイト全体の余白を知ってしまったSPのwidth計算をどこかに移す
const disableWrapper = css`
  ${wrapperBase}
  cursor: not-allowed;
  opacity: 0.3;
`;

const wrapper = css`
  ${wrapperBase}
  cursor: pointer;
`;

export const Button: React.FC<IButtonProps> = memo((props) => {
  const { handleClick, text, disable, width } = props;

  if (disable) {
    return (
      <div css={disableWrapper} style={{ width }}>
        {text}
      </div>
    );
  }

  return (
    <div css={wrapper} onClick={handleClick} style={{ width }}>
      {text}
    </div>
  );
});
