/** @jsx jsx
 * Artworks
 */
import React, { memo, useState } from 'react';
import { jsx, css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { MDMotion } from 'style/animation';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import playIcon from 'images/top/artworks/play-icon.svg';

interface IArtworkItemProps {
  id: number;
  image: string;
  youtubeVideoId: string;
  title: string;
  text: string;
  domain: string;
  link: string;
}

const itemWrapper = css`
  width: 100vw;
  height: 480px;
  background-color: #222422;
  ${MEDIA_QUERY.TB} {
    width: calc(100vw - 40px * 2);
    height: auto;
    margin: 0 40px;
  }
  ${MEDIA_QUERY.SP} {
    width: 100vw;
    height: auto;
  }
`;

const videoArea = css`
  display: inline-grid;
  width: 60%;
  height: 100%;
  float: left;
  ${MEDIA_QUERY.TB} {
    width: calc(100vw - 40px * 2);
    height: 380px;
    float: none;
  }
  ${MEDIA_QUERY.SP} {
    width: 100vw;
    height: 100vw;
    float: none;
  }
`;

const thumbnailWrapper = css`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.36s ${MDMotion}, transform 0.6s ${MDMotion};
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.9;
      transform: scale(1.02);
    }
  }
`;

const thumbnail = css`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

// beforeが黒三角で、afterが白丸
const playButton = css`
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 2px;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1.5em;
    height: 1.5em;
    margin: auto;
    background-image: url(${playIcon});
  }
  &:after {
    content: '';
    position: absolute;
    width: 70px;
    height: 70px;
    top: 50%;
    left: 50%;
    margin: -35px;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

const infoArea = css`
  position: relative;
  width: calc(40% - 80px * 2);
  height: calc(100% - 80px * 2);
  padding: 80px;
  float: right;
  ${MEDIA_QUERY.TB} {
    width: auto;
    height: auto;
    padding: 30px 30px 60px;
    float: none;
  }
  ${MEDIA_QUERY.SP} {
    width: auto;
    height: auto;
    padding: 30px 30px 60px;
    float: none;
  }
`;

const title = css`
  font-size: 18px;
  line-height: 1.61;
  cursor: pointer;
`;

const text = css`
  margin-top: 30px;
  opacity: 0.5;
  font-size: 13px;
  line-height: 2;
  max-height: 180px;
  overflow-y: hidden;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  ${MEDIA_QUERY.TB} {
    margin-top: 20px;
    max-height: initial;
  }
  ${MEDIA_QUERY.SP} {
    margin-top: 20px;
    max-height: initial;
  }
`;

const viewMoreButton = css`
  position: absolute;
  margin-top: 40px;
  width: 140px;
  height: 45px;
  border: solid 1px rgba(255, 255, 255, 0.3);
  text-align: center;
  cursor: pointer;
  transition: all 0.36s ${MDMotion};
  &:hover {
    background-color: #fff;
    color: #0e0e0e;
    border-color: #fff;
    &:after {
      border-color: #000;
    }
  }
  ${MEDIA_QUERY.TB} {
    display: none;
  }
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const viewMoreButtonText = css`
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 45px;
`;

export const ArtworkItem: React.FC<IArtworkItemProps> = memo((props) => {
  // ARTWORKSの画像をクリックしてYoutubeを表示したかどうか
  // YoutubeじゃないArtworksがくるとこのStateが使えなくなるのでその時はなんとかしてください
  const [isYoutubeVisible, setIsYoutubeVisible] = useState<boolean>(false);
  /** 多言語文言用 */
  const { t, i18n } = useTranslation();

  /** クリックするまではサムネイル画像を表示して、クリックしたらYoutube埋め込み */
  const video = isYoutubeVisible ? (
    <YouTube
      videoId={props.youtubeVideoId}
      opts={{
        width: '100%',
        height: '100%',
        playerVars: {
          modestbranding: 1, // Youtubeロゴを表示しない
          rel: 0, // 表示される関連動画を同チャンネルのみにする
          playsinline: 1, // iOSでインライン再生する
        },
      }}
      onReady={(event) => {
        const player = event.target;
        player.mute(); // 消音しないと自動再生できない
        player.playVideo(); // 自動再生させる
      }}
    />
  ) : (
    <div css={thumbnailWrapper}>
      <div css={thumbnail} style={{ backgroundImage: `url(${props.image})` }} />
      <div css={playButton} />
    </div>
  );

  return (
    <li css={itemWrapper} key={props.id}>
      <div css={videoArea} onClick={() => setIsYoutubeVisible(true)}>
        {video}
      </div>
      <div css={infoArea}>
        <a
          href={`${props.domain}/${i18n.language}${props.link}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span css={title}>{t(props.title)}</span>
        </a>
        <p css={text}>{t(props.text)}</p>
        <a
          href={`${props.domain}/${i18n.language}${props.link}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div css={viewMoreButton}>
            <span css={viewMoreButtonText}>{t('view_more')}</span>
          </div>
        </a>
      </div>
    </li>
  );
});
