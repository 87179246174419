/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

const title = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.42;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
  ${MEDIA_QUERY.SP} {
    font-size: 17px;
    line-height: 1.8;
    letter-spacing: 0.45px;
  }
`;

export const PopupTitle: React.FC = (props) => {
  return <h1 css={title}>{props.children}</h1>;
};
