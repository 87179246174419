import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, compose } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { reducer as scanReducer } from './scan/reducer';
import { reducer as imageReducer } from './image/reducer';
import { IScanState } from './scan/scanState';
import { IImageState } from './image/imageState';

/** localの場合はredux-devtool使えるようにする */
const enhancers =
  process.env.REACT_APP_ENV === 'local' ? composeWithDevTools() : compose;

/** グローバルステートの型定義 */
interface IGlobaState {
  scan: IScanState;
  image: IImageState;
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['scan'],
};

/** reducerをまとめる */
const rootReducers = combineReducers<IGlobaState>({
  scan: scanReducer,
  image: imageReducer,
});

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, rootReducers);

/** store定義   */
const store = createStore(persistedReducer, enhancers);

// 複数Reducer,複数Stateを型安全にTypeScriptで扱う方法
// https://qiita.com/hat3/items/9d9d2de9f956f0f008a6
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
export default store;
