/** @jsx jsx
 * SP用のヘッダ
 */
import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { css, jsx } from '@emotion/core';
import { HEADER_HEIGHT_SP } from 'style/common';
import { MDMotion } from 'style/animation';
import { HamburgerIcon } from './HamburgerIcon';
import { HamburgerMenu } from './HamburgerMenu';
import { Logo } from './Logo';
import { LanguageSelect } from './LanguageSelect';

interface IHeaderProps {
  isHamburgerOpen: boolean;
  setIsHamburgerOpen: Dispatch<SetStateAction<boolean>>;
  transparent: boolean;
  setTransparent: Dispatch<SetStateAction<boolean>>;
  openYoutubePopup: () => void;
  openYourFlowerPopup: () => void;
}

const wrapper = css`
  position: fixed;
  top: 0;
  z-index: 1000;
`;

const headerWrapper = css`
  position: relative;
  width: 100vw;
  text-align: center;
  height: ${HEADER_HEIGHT_SP}px;
  line-height: ${HEADER_HEIGHT_SP}px;
  background-color: #000000;
  transition: background-color 0.36s ${MDMotion};
`;

const headerWrapperTransparent = css`
  ${headerWrapper}
  background-color: transparent;
`;

const hamburgerIconWrapper = css`
  position: absolute;
  left: 22px;
  top: calc((${HEADER_HEIGHT_SP}px - 18px) / 2);
  margin: 0;
  width: 20px;
  height: 18px;
`;

const languageSelectWrapper = css`
  position: absolute;
  right: 22px;
  top: calc((${HEADER_HEIGHT_SP}px - 18px) / 2);
`;

export const SPHeader: React.FC<IHeaderProps> = (props) => {
  /**
   * ハンバーガーメニューを開閉する
   */
  const handleHamburgerClick = useCallback(() => {
    props.setIsHamburgerOpen(!props.isHamburgerOpen);
  }, [props]);

  return (
    <div css={wrapper}>
      <div
        css={
          props.transparent && !props.isHamburgerOpen
            ? headerWrapperTransparent
            : headerWrapper
        }
      >
        <div css={hamburgerIconWrapper} onClick={handleHamburgerClick}>
          <HamburgerIcon onClose={props.isHamburgerOpen} />
        </div>
        <Logo />
        <div css={languageSelectWrapper}>
          <LanguageSelect />
        </div>
      </div>
      <HamburgerMenu
        handleLinkClick={handleHamburgerClick}
        openYoutubePopup={props.openYoutubePopup}
        openYourFlowerPopup={props.openYourFlowerPopup}
        isVisible={props.isHamburgerOpen}
      />
    </div>
  );
};
