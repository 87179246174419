/** @jsx jsx
 * 画面サイズいっぱいでバックグラウンド再生するビデオ
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import useMedia from 'hooks/useMedia';

const wrapper = css`
  position: relative;
`;

const sp = css`
  display: none;
  ${MEDIA_QUERY.SP} {
    display: block;
  }
`;

const pc = css`
  display: block;
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const video = css`
  filter: blur(0px);
  opacity: 1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const mask = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BackgroundVideo: React.FC = memo(() => {
  /** SPサイズかどうか */
  const { isSP } = useMedia();

  return (
    <div css={wrapper}>
      {isSP ? (
        <div css={sp}>
          <video
            key="sp_video"
            css={video}
            preload="none"
            muted
            loop
            autoPlay
            playsInline
          >
            <source
              src="https://flowers-bombing-home.teamlab.art/video/sp.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      ) : (
        <div css={pc}>
          <video
            key="pc_video"
            css={video}
            preload="none"
            muted
            loop
            autoPlay
            playsInline
          >
            <source
              src="https://flowers-bombing-home.teamlab.art/video/bg.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      )}
      <div css={mask} />
    </div>
  );
});
