/** @jsx jsx
 * Artworks
 */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import useMedia from 'hooks/useMedia';
import { PCExhibition } from './PCExhibition';
import { SPExhibition } from './SPExhibition';

export const Exhibition: React.FC = memo(() => {
  const { isSP } = useMedia();

  if (isSP) {
    return <SPExhibition />;
  }

  return <PCExhibition />;
});
