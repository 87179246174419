/** @jsx jsx */
import React, { Dispatch, SetStateAction } from 'react';
import { css, jsx } from '@emotion/core';
import AutoComplete from 'react-places-autocomplete';
import pinIcon from 'images/location/pin-icon.svg';
import { useTranslation } from 'react-i18next';
import searchIcon from 'images/location/search-icon.svg';

interface IPlacesAutoCompleteProps {
  inputAddress: string;
  setInputAddress: Dispatch<SetStateAction<string>>;
  geoCodeFromAddress: (address: string) => void;
}

const inputAreaWrapper = css`
  width: 70%;
  border: none;
  padding: 20px 0px 15px 10px;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
`;

const inputArea = css`
  border: none;
  overflow: hidden;
  background: #000;
  color: #fff;
  font-size: 15px;
  outline: none;
`;

const autocompleteDropdownContainer = css`
  width: 100%;
  padding-top: 10px;
  background: #000;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
`;

const suggestionItem = css`
  width: 100%;
  height: 55px;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const suggestionWrapper = css`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const iconImg = css`
  width: 12px;
  height: 16px;
`;

const pinIconImg = css`
  ${iconImg}
  margin: 0 20px;
`;

const searchIconImg = css`
  ${iconImg}
  margin-right: 6px;
`;

const arrow = css`
  position: relative;
  display: inline-block;
  padding-left: 20px;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border: 0px;
    border-top: solid 1px #fff;
    border-right: solid 1px #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
  }
`;

export const PlacesAutoComplete: React.FC<IPlacesAutoCompleteProps> = (
  props,
) => {
  const { t } = useTranslation();

  const handleChange = (inputAddress: string) => {
    props.setInputAddress(inputAddress);
  };

  const handleSelect = (inputAddress: string) => {
    props.setInputAddress(inputAddress);
    props.geoCodeFromAddress(inputAddress);
  };

  return (
    <AutoComplete
      // (regions)をOptionsに渡すことでautoCompleteの結果を首都レベルに制限することができる 詳細:https://developers.google.com/places/supported_types#table3
      searchOptions={{ types: ['(regions)'] }}
      value={props.inputAddress}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = {
          ...getInputProps({
            placeholder: t('location.search'),
          }),
        };

        return (
          <div>
            <div css={inputAreaWrapper}>
              <img src={searchIcon} css={searchIconImg} alt="pin-icon" />
              <input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                css={inputArea}
              />
            </div>
            <div css={autocompleteDropdownContainer}>
              {loading && <div>Loading...</div>}

              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? { backgroundColor: 'gray' }
                  : { backgroundColor: '#000' };

                const suggestionItemProps = {
                  ...getSuggestionItemProps(suggestion, {
                    style,
                  }),
                  key: suggestion.placeId,
                };

                return (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...suggestionItemProps}
                    css={suggestionItem}
                  >
                    <div css={suggestionWrapper}>
                      <img src={pinIcon} css={pinIconImg} alt="pin-icon" />
                      <p>{suggestion.description}</p>
                    </div>
                    <div css={arrow} />
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </AutoComplete>
  );
};
