/** @jsx jsx
 * Artworks
 */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import { ArtworkItem } from './ArtWorkItem';

interface IArtWorksProps {
  artworksList: Array<{
    id: number;
    image: string;
    youtubeVideoId: string;
    title: string;
    text: string;
    domain: string;
    link: string;
  }>;
}

export const Artworks: React.FC<IArtWorksProps> = memo((props) => {
  const list = props.artworksList.map((i) => {
    const { id, image, youtubeVideoId, title, text, domain, link } = i;

    return (
      <ArtworkItem
        key={id}
        id={id}
        image={image}
        youtubeVideoId={youtubeVideoId}
        title={title}
        text={text}
        domain={domain}
        link={link}
      />
    );
  });

  return <ul>{list}</ul>;
});
