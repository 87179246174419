import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from './action';
import { imageState } from './imageState';

/** reducer (アクション事にcaseで分岐し定義する) */
export const reducer = reducerWithInitialState(imageState)
  .case(actions.setInitial, () => ({
    ...imageState,
  }))
  .case(actions.setImageData, (state, imageData) => ({
    ...state,
    imageData,
  }))
  .case(actions.setCityName, (state, cityName) => ({
    ...state,
    cityName,
  }))
  .case(actions.setImageMeta, (state, payload) => ({
    ...state,
    nickname: payload.nickname,
    cityName: payload.cityName,
  }));
