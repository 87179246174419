/** @jsx jsx */
import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { css, jsx } from '@emotion/core';
import {
  HeadingSection,
  FixedButton,
  PageTransition,
} from 'components/partials';
import { DownloadList } from 'components/pages/download/DownloadList';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import { PAINT_PAGE } from '../../constants/enum';
import '../../index.css';

interface IDownloadProps {
  setCurrentPage: Dispatch<SetStateAction<PAINT_PAGE>>;
  currentPage: PAINT_PAGE;
}

const container = css`
  height: 100%;
`;

// Fixed Buttonの高さが120pxで固定なのでmarginBottom120px当ててる
// 最下部スクロール時にFixedButtonが被ってこないように下にもマージンは必須
const wrapper = css`
  padding: 75px 20vw 120px;
  width: calc(100% - 20vw * 2);

  ${MEDIA_QUERY.TB} {
    padding: 75px 120px 120px;
    width: calc(100% - 120px * 2);
  }

  ${MEDIA_QUERY.SP} {
    padding: 40px 5.3vw 0;
    width: calc(100% - 5.3vw * 2);
    padding-bottom: 70px;
  }
`;

const showFixedButton = css`
  display: block;
`;

const hideFixedButton = css`
  display: none;
`;

const Download: React.FC<IDownloadProps> = (props) => {
  /** 多言語文言用 */
  const { t } = useTranslation();

  const { setCurrentPage, currentPage } = props;

  /** 画面遷移する */
  const handleNext = useCallback(() => {
    setCurrentPage(PAINT_PAGE.PROCESS);
  }, [setCurrentPage]);

  return (
    <div css={container}>
      <PageTransition
        shouldMount={currentPage === PAINT_PAGE.DOWNLOAD}
        initialMountPage
      >
        <HeadingSection
          title={t('download.title')}
          contents={t('download.contents')}
        />
        <div css={wrapper}>
          <DownloadList />
        </div>
      </PageTransition>
      <div
        css={
          currentPage === PAINT_PAGE.DOWNLOAD
            ? showFixedButton
            : hideFixedButton
        }
      >
        <FixedButton handleClick={handleNext} />
      </div>
    </div>
  );
};

export default Download;
