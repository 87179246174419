/** @jsx jsx */
import React, { useState, useEffect, memo } from 'react';
import { css, jsx } from '@emotion/core';
import ITexture from 'types/Texture';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { fadeIn, MDMotion } from 'style/animation';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PAGE_PATH } from 'constants/enum';
import { Texture } from './Texture';

interface ITextureListProps {
  /** 画像のリスト */
  textures: Array<ITexture>;
  /**
   * 指定した場合は、指定した枚数で表示を止めて「もっとみる」ボタンを配置する
   * 指定しなければ、全画像を一度に表示する
   */
  firstViews?: number;
}

const wrapper = css`
  display: flex;
  flex-wrap: wrap;
`;

// padding-rightの%値はTextureのpaddingと合うように計算した
// FIX ME: メンテしやすい値にする
const textureWrapper = css`
  ${fadeIn}
  animation: fadeIn 1s ${MDMotion} 0s;
  width: calc((100% - 2.3% * 5) / 6);
  padding-bottom: 32px;
  padding-right: 2.3%;
  :nth-of-type(6n) {
    padding-right: 0px;
  }
  ${MEDIA_QUERY.TB} {
    width: calc((100% - 4.7% * 2) / 3);
    padding-right: 4.7%;
    :nth-of-type(6n) {
      padding-right: 20px;
    }
    :nth-of-type(3n) {
      padding-right: 0px;
    }
  }
  ${MEDIA_QUERY.SP} {
    width: calc((100% - 4.7% * 2) / 3);
    padding-right: 4.7%;
    :nth-of-type(6n) {
      padding-right: 20px;
    }
    :nth-of-type(3n) {
      padding-right: 0px;
    }
  }
`;

const viewMoreButton = css`
  position: relative;
  width: 200px;
  height: 45px;
  margin: 57px auto 80px;
  border: solid 1px rgba(255, 255, 255, 0.3);
  text-align: center;
  cursor: pointer;
  transition: all 0.36s ${MDMotion};
  ${MEDIA_QUERY.PC} {
    &:hover {
      background-color: #fff;
      color: #0e0e0e;
      border-color: #fff;
      &:after {
        border-color: #000;
      }
    }
  }
  ${MEDIA_QUERY.SP} {
    width: 150px;
  }
`;

const viewMoreButtonText = css`
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 45px;
`;

export const TextureList: React.FC<ITextureListProps> = memo((props) => {
  /** ページ遷移用 */
  const history = useHistory();
  /** 多言語文言用 */
  const { t } = useTranslation();
  /** 初期描画で全画像を表示するかどうか. 全画像を表示しない場合は「もっとみる」ボタンを表示する. */
  const [shouldShowAll, setShouldShowAll] = useState<boolean>(false);
  /** { 初期描画で表示する枚数, 画像のリスト } */
  const { firstViews, textures } = props;

  /**
   * 初期表示枚数が指定されていて、画像の枚数が指定枚数以上あれば、「もっとみる」ボタンを表示する
   */
  useEffect(() => {
    if (firstViews && textures.length > firstViews) {
      setShouldShowAll(false);
    } else {
      setShouldShowAll(true);
    }
  }, [firstViews, textures.length]);

  /**
   * 全ての画像をだす条件は、初期表示枚数が未指定のとき
   * 全ての画像を出さない場合は、初期表示枚数以降の画像を非表示
   */
  const textureItems = textures.map((tex, index) => {
    if (!firstViews || shouldShowAll || index < firstViews) {
      return <Texture key={tex.id} tex={tex} wrapper={textureWrapper} />;
    }

    return null;
  });

  return (
    <div css={wrapper}>
      {textureItems}
      {firstViews && !shouldShowAll && (
        <div
          css={viewMoreButton}
          onClick={() => history.push(PAGE_PATH.FLOWERS)}
        >
          <span css={viewMoreButtonText}>{t('view_more')}</span>
        </div>
      )}
    </div>
  );
});
