/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { Title } from './Title';
import { Contents } from './Contents';
import { SubTitle } from './SubTitle';

interface IHeadingSectionProps {
  title: string;
  contents?: string;
  isWide?: boolean;
  subTitle?: string;
}

const wrapper = css`
  margin: 75px 20vw 0;
  width: calc(100% - 20vw * 2);

  ${MEDIA_QUERY.TB} {
    margin: 75px 120px 0;
    width: calc(100% - 120px * 2);
  }

  ${MEDIA_QUERY.SP} {
    margin: 30px 8vw 0;
    width: calc(100% - 8vw * 2);
  }
`;

const wideWrapper = css`
  margin: 75px 120px 0;
  width: calc(100% - 120px * 2);

  ${MEDIA_QUERY.TB} {
    margin: 75px 60px 0;
    width: calc(100% - 60px * 2);
  }
  ${MEDIA_QUERY.SP} {
    margin: 20px 30px 0;
    width: calc(100% - 30px * 2);
  }
`;

const titleWrapper = css`
  margin-bottom: 20px;
`;

export const HeadingSection: React.FC<IHeadingSectionProps> = (props) => {
  return (
    <div css={props.isWide ? wideWrapper : wrapper}>
      <div css={titleWrapper}>
        <Title>{props.title}</Title>
      </div>
      {props.contents && (
        <div>
          <Contents>{props.contents}</Contents>
        </div>
      )}
      {props.subTitle && (
        <div>
          <SubTitle>{props.subTitle}</SubTitle>
        </div>
      )}
    </div>
  );
};
