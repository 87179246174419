/** @jsx jsx
 * PCの作品の参加方法
 */
import React, { memo, useState } from 'react';
import { jsx, css } from '@emotion/core';
import { TOP_HOW_TO_LIST } from 'constants/staticData';
import { MDMotion } from 'style/animation';
import { useTranslation } from 'react-i18next';

/** 作品の参加方法全体の左右にとるマージンvw */
const SLIDER_DEFAULT_MARGIN_X = 8.3;
/** 各画像の間のマージンpx */
const SLIDER_IMAGE_MARGIN = 47;
/** 画像の数 */
const SLIDER_IMAGE_LENGTH = TOP_HOW_TO_LIST.length;
/** 一度に見える画像の数 */
const SLIDER_IMAGE_VISIBLE_NUMBER = 3;
/** 各画像の横px */
const SLIDER_IMAGE_WIDTH = `calc((100vw - ${SLIDER_DEFAULT_MARGIN_X}vw * 2 - ${SLIDER_IMAGE_MARGIN}px * 2) / ${SLIDER_IMAGE_VISIBLE_NUMBER})`;
/** 各画像の縦px */
const SLIDER_IMAGE_HEIGHT = `calc(${SLIDER_IMAGE_WIDTH} * 0.66)`;

const slider = css`
  display: flex;
  transition: transform 0.6s ${MDMotion};
`;

const sliderWrapper = css`
  position: relative;
`;

const slideIcon = css`
  position: absolute;
  top: 0;
  bottom: 50px;
  z-index: 20;
  padding: calc((${SLIDER_IMAGE_HEIGHT} - 22px) / 2) 10px;
  width: 22px;
  height: 22px;
  opacity: 1;
  transition: opacity 0.36s ${MDMotion};
`;

const slideIconPrev = css`
  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    border-left: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
  }
  ${slideIcon}
  left: 4vw;
`;

const slideIconNext = css`
  &:before {
    position: absolute;
    right: 10px;
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
  ${slideIcon}
  right: 4vw;
`;

const sliderIconEnable = css`
  opacity: 1;
  cursor: pointer;
`;

const sliderIconDisable = css`
  opacity: 0.5;
`;

const sliderItem = css`
  position: relative;
  margin-right: ${SLIDER_IMAGE_MARGIN}px;
  &:last-of-type {
    margin-left: 0;
  }
`;

const sliderItemImageWrapper = css`
  position: relative;
  width: ${SLIDER_IMAGE_WIDTH};
  height: ${SLIDER_IMAGE_HEIGHT};
  opacity: 1;
  transiton: opacity 0.36ms ${MDMotion};
`;

const sliderItemImageWrapperBlurred = css`
  ${sliderItemImageWrapper}
  opacity: 0.5;
`;

const sliderItemImage = css`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const sliderItemNumber = css`
  font-size: 13px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 1.15px #fff;
  background-color: #0e0f0e;
  text-align: center;
  line-height: 20px;
`;

const sliderItemTitle = css`
  margin: 30px 0 10px 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.73;
`;

const sliderItemText = css`
  font-size: 15px;
  line-height: 2;
`;

export const PCContentsHowTo: React.FC = memo(() => {
  /** 作品の参加方法のスライダーで今見えている先頭の画像. 1なら1枚目が先頭. */
  const [slideImagePositon, setSlideImagePositon] = useState<number>(1);
  /** 多言語文言用 */
  const { t } = useTranslation();

  /**
   * 作品の参加方法のスライダーをX軸マイナス方向に動かす
   * 1枚目より前には動かせない
   **/
  const slidePrev = () => {
    setSlideImagePositon((s) => {
      if (s > 1) {
        return s - 1;
      }

      return s;
    });
  };

  /**
   * 作品の参加方法のスライダーをX軸プラス方向に動かす
   * スライダー内にある画像の数以上には動かせない
   */
  const slideNext = () => {
    setSlideImagePositon((s) => {
      if (s < SLIDER_IMAGE_LENGTH - SLIDER_IMAGE_VISIBLE_NUMBER + 1) {
        return s + 1;
      }

      return s;
    });
  };

  /** 作品の参加方法の部品群 */
  const sliderItems = TOP_HOW_TO_LIST.map((i) => {
    return (
      <div css={sliderItem} key={i.id}>
        <div
          css={
            slideImagePositon > i.id ||
            slideImagePositon + SLIDER_IMAGE_VISIBLE_NUMBER <= i.id
              ? sliderItemImageWrapperBlurred
              : sliderItemImageWrapper
          }
        >
          <div
            css={css(sliderItemImage, {
              backgroundImage: `url(${i.src})`,
            })}
          />
        </div>
        <p css={sliderItemNumber}>{i.id}</p>
        <p css={sliderItemTitle}>{t(i.title)}</p>
        <p css={sliderItemText}>{t(i.text)}</p>
      </div>
    );
  });

  return (
    <div css={sliderWrapper}>
      <div
        css={css(
          slideIconPrev,
          slideImagePositon === 1 ? sliderIconDisable : sliderIconEnable,
        )}
        onClick={slidePrev}
      />
      <div
        css={css(
          slideIconNext,
          slideImagePositon ===
            SLIDER_IMAGE_LENGTH - SLIDER_IMAGE_VISIBLE_NUMBER + 1
            ? sliderIconDisable
            : sliderIconEnable,
        )}
        onClick={slideNext}
      />
      <div
        css={css(slider, {
          transform: `translate3d(calc(${SLIDER_DEFAULT_MARGIN_X}vw -
            (${SLIDER_IMAGE_WIDTH} + ${SLIDER_IMAGE_MARGIN}px) * (${slideImagePositon} - 1)
          ), 0px, 0px)`,
        })}
      >
        {sliderItems}
      </div>
    </div>
  );
});
