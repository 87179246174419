/**
 * 花束関連のAPI
 */
import Axios from 'utils/AxiosUtils';
import { format } from 'date-fns';
import {
  IBouquetsResponse,
  IBouquet,
  ICredit,
} from '../types/api/response/bouquets.d';

/**
 * 花束取得
 */
export const getBouquetsAPI = async (
  textureId: string,
): Promise<IBouquetsResponse> => {
  const response = await Axios.get(
    `${process.env.REACT_APP_BOMING_SERVER_PREFIX}/api/bouquets/${textureId}`,
    {
      baseURL: process.env.REACT_APP_BOMING_SERVER_HOST,
    },
  )
    .then((res) => {
      const fixedData = res.data.bouquets.map((bouquet: IBouquet) => {
        const credits = bouquet.credits.map((credit: ICredit) => {
          const formattedDate: string = format(
            new Date(credit.time * 1000),
            'MM.dd hh:mm a',
          );

          return {
            ...credit,
            date: formattedDate,
          };
        });

        return {
          ...bouquet,
          credits,
        };
      });

      return {
        bouquets: fixedData,
      };
    })
    .catch((e) => {
      return Promise.reject(new Error(e.response?.data));
    });

  return response;
};
