/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { MDMotion } from 'style/animation';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';

const downloadButton = css`
  width: 150px;
  height: 36px;
  line-height: 36px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  cursor: pointer;
  transition: all 0.36s ${MDMotion};
  background-color: #fff;
  color: #141414;

  ${MEDIA_QUERY.PC} {
    &:hover {
      background-color: #000;
      color: #fff;
      border-color: #fff;
      &:after {
        border-color: #000;
      }
    }
  }
`;

const downloadButtonText = css`
  font-size: 15px;
  font-weight: bold;
`;

interface IDounLoadButtonProps {
  targetUrl: string;
  fileName: string;
}

export const DownLoadButton: React.FC<IDounLoadButtonProps> = memo((props) => {
  const { t } = useTranslation();

  return (
    <a href={props.targetUrl} download={`${props.fileName}.png`}>
      <div css={downloadButton}>
        <span css={downloadButtonText}>{t('download.button')}</span>
      </div>
    </a>
  );
});
