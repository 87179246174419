/** @jsx jsx
 * Exhibition
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import linkIcon from 'images/top/external-link-icon-gray.svg';
import { useTranslation } from 'react-i18next';

const dividerWide = css`
  width: 100%;
  content: '';
  height: 10px;
  background-color: #f7f7f7;
`;

const content = css`
  margin: 30px;
`;

const primaryItem = css`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const divider = css`
  width: 100%;
  content: '';
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const secondaryItem = css`
  margin: 30px 0;
`;

const label = css`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const bold = css`
  margin-top: 6px;
  font-weight: bold;
`;

const tertiaryItem = css`
  margin: 30px 0;
`;

const linkIconCss = css`
  width: 12px;
  height: 12px;
  margin-left: 6px;
`;

const hover = css`
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.5;
  }
`;

export const SPExhibition: React.FC = memo(() => {
  const c = 'top.exhibition_content';
  const { t } = useTranslation();

  return (
    <div>
      <div css={dividerWide} />
      <div css={content}>
        <div css={primaryItem}>{t(`${c}.info`)}</div>
        <div css={divider} />
        <div css={secondaryItem}>
          <div css={label}>{t(`${c}.label_1`)}</div>
          <div>{t(`${c}.name_1`)}</div>
          <div css={bold}>{t(`${c}.name_2`)}</div>
        </div>
        <div css={divider} />
        <div css={tertiaryItem}>
          <div css={label}>{t(`${c}.label_2`)}</div>
          <div>{t(`${c}.date`)}</div>
        </div>
      </div>
      <div css={dividerWide} />
      <div css={content}>
        <div css={primaryItem}>{t(`${c}.inquiry`)}</div>
        <div css={divider} />
        <div css={secondaryItem}>
          <div css={label}>{t(`${c}.label_3`)}</div>
          <a
            href="https://www.dropbox.com/sh/djtx2ixfg2depad/AAC2PolKFYeXipEmADAJ9d4Ta?dl=0"
            target="_blank"
            rel="noopener noreferrer"
            css={hover}
          >
            <span>{t(`${c}.press_kit`)}</span>
            <img src={linkIcon} css={linkIconCss} alt="icon" />
          </a>
        </div>
      </div>
    </div>
  );
});
