/** @jsx jsx */
import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import uploadIcon from 'images/upload/upload-icon.svg';
import uploadChangeIcon from 'images/upload/change-icon.svg';
import uploadImage from 'images/upload/upload.svg';
import { useTranslation } from 'react-i18next';

interface IUploadFormProps {
  imageSrc: string;
  setImageSrc: Dispatch<SetStateAction<string>>;
}

// TODO: 押した感がでるアニメーションをつける
const formLabel = css`
  display: block;
  width: 316px;
  height: 443px;
  margin: 0 auto;
  background-image: url(${uploadImage});
  background-size: cover;
  position: relative;
  ${MEDIA_QUERY.SP} {
    width: 186px;
    height: 260px;
  }
`;

const formInput = css`
  display: none;
`;

const uploadIconWrapper = css`
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const uploadIconStyle = css`
  width: 30px;
`;

const thumbnail = css`
  object-fit: contain;
  display: block;
  width: 316px;
  height: 443px;
  margin: 0 auto;
  border: none;
  ${MEDIA_QUERY.SP} {
    max-width: 186px;
    max-height: 260px;
  }
`;

const changeImageLabelWrapper = css`
  margin-top: 35px;
  text-align: center;
`;

const changeImageIcon = css`
  vertical-align: middle;
`;

const changeImageLabel = css`
  cursor: pointer;
`;

const changeImageText = css`
  font-size: 15px;
  margin-left: 8px;
`;

export const UploadForm: React.FC<IUploadFormProps> = (props) => {
  const { imageSrc, setImageSrc } = props;
  const reader = new FileReader();
  const { t } = useTranslation();

  const handleUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      reader.onload = (el) => {
        if (!el.target || !el.target.result) {
          return;
        }
        setImageSrc(el.target.result.toString());
      };
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    },
    [reader, setImageSrc],
  );

  /** 画像選択後 */
  if (imageSrc) {
    return (
      <div>
        <div>
          <img css={thumbnail} src={imageSrc} alt="sample" />
          <div css={changeImageLabelWrapper}>
            <label css={changeImageLabel} htmlFor="imageInput">
              <img
                css={changeImageIcon}
                src={uploadChangeIcon}
                alt="changeImageIcon"
              />
              <input
                id="imageInput"
                css={formInput}
                type="file"
                onChange={handleUpload}
              />
              <span css={changeImageText}>{t('upload.change')}</span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  /** 画像選択前 */
  return (
    <div>
      <label css={formLabel} htmlFor="imageInput">
        <div css={uploadIconWrapper}>
          <img css={uploadIconStyle} src={uploadIcon} alt="imageInputIcon" />
        </div>
        <input
          id="imageInput"
          css={formInput}
          type="file"
          onChange={handleUpload}
        />
      </label>
    </div>
  );
};
