/** 共通 */
// 多言語対応している言語のリスト
export enum LANGUAGE_SHORTHAND {
  ENGLISH = 'en',
  JAPANESE = 'jp',
}

// 言語のリスト（表示用）
export enum LANGUAGE {
  ENGLISH = 'English',
  JAPANESE = '日本語',
}

// パス一覧
export enum PAGE_PATH {
  TOP = '/',
  PAINT = `/paint`,
  LIVE = '/live',
  FLOWERS = '/flowers',
  MAINTENANCE = '/maintenance',
  TERMS_OF_USE = '/terms-of-use',
}

/** PAINT */
// PAINTページが管理するページ一覧
export enum PAINT_PAGE {
  DOWNLOAD = 'DOWNLOAD',
  PROCESS = 'PROCESS',
  UPLOAD = 'UPLOAD',
  NICKNAME = 'NICKNAME',
  LOCATION = 'LOCATION',
}

/** PROCESS */
// 色ぬり方法のタブ切り替えで使用する機種リスト
export enum PROCESS_TYPE {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  PRINT = 'PRINT',
}

/** LIVE */
// スキャンステータス管理
export enum SCAN_STATUS {
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  SUCCESS = 'SUCCESS',
  SCAN_ERROR = 'SCAN_ERROR',
  POLICY_ERROR = 'POLICY_ERROR',
}

/** LIVE */
// 花束取得ステータス管理
export enum BOUQUETS_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WAITING = 'WAITING',
}

/** LOCATION */
// Googleマップのタイプ一覧
export enum GOOGLE_MAP_TYPE {
  INDEX = 'index',
  NAVIGATOR = 'NAVIGATOR',
  AUTO_COMPLETE = 'AUTO_COMPLETE',
}
