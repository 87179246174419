/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_SHORTHAND } from 'constants/enum';

interface IProcessImageProps {
  imgSrc: {
    en: string;
    jp: string;
  };
  processNum: number;
}

const contents = css`
  font-size: 15px;
  color: #fff;
  width: 100%;
  position: relative;
`;

const image = css`
  width: 100%;
  height: auto;
`;

const num = css`
  font-size: 13px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 1.15px #fff;
  background-color: #0e0f0e;
  text-align: center;
  line-height: 20px;
`;

export const ProcessImage: React.FC<IProcessImageProps> = memo((props) => {
  const { imgSrc, processNum } = props;
  /** 現在選択中の言語 */
  const { i18n } = useTranslation();

  return (
    <div css={contents}>
      <img
        css={image}
        src={
          i18n.language === LANGUAGE_SHORTHAND.JAPANESE ? imgSrc.jp : imgSrc.en
        }
        alt="processImag"
      />
      <p css={num}>{processNum}</p>
    </div>
  );
});
