/** @jsx jsx */
import React, { useCallback, Dispatch, SetStateAction, useState } from 'react';
import { css, jsx } from '@emotion/core';
import {
  HeadingSection,
  FixedButton,
  PageTransition,
} from 'components/partials';
import { UploadForm } from 'components/pages/upload/UploadForm';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { PAINT_PAGE } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setImageData } from 'store/image/operations';
import { Link } from 'react-router-dom';

interface IUploadProps {
  setCurrentPage: Dispatch<SetStateAction<PAINT_PAGE>>;
  currentPage: PAINT_PAGE;
}

const container = css`
  height: 100%;
`;

const wrapper = css`
  padding: 75px 20vw 180px;
  width: calc(100% - 20vw * 2);

  ${MEDIA_QUERY.TB} {
    padding: 75px 120px 150px;
    width: calc(100% - 120px * 2);
  }

  ${MEDIA_QUERY.SP} {
    padding: 50px 0 100px;
    width: 100%;
  }
`;

const copyrightWrapper = css`
  margin-top: 45px;
  text-align: center;

  ${MEDIA_QUERY.SP} {
    margin-top: 80px;
    padding: 20px 5.3vw 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const copyright = css`
  font-size: 13px;
  color: #fff;
  opacity: 0.5;
`;

const link = css`
  ${copyright}
  color: #fff;
  opacity: 1;
`;

const showFixedButton = css`
  display: block;
`;

const hideFixedButton = css`
  display: none;
`;

const Upload: React.FC<IUploadProps> = (props) => {
  const { setCurrentPage, currentPage } = props;
  /** アップロードする画像のデータ  */
  const [imageSrc, setImageSrc] = useState<string>('');
  /** reduxのアクションを呼び出す */
  const dispatch = useDispatch();

  /** 多言語文言用 */
  const { t } = useTranslation();

  /** 画面遷移する */
  const handleNext = useCallback(() => {
    dispatch(setImageData(imageSrc));
    setCurrentPage(PAINT_PAGE.LOCATION);
  }, [setCurrentPage, imageSrc, dispatch]);

  return (
    <div css={container}>
      <PageTransition shouldMount={currentPage === PAINT_PAGE.UPLOAD}>
        <HeadingSection
          title={t('upload.title')}
          contents={t('upload.contents')}
        />
        <div css={wrapper}>
          <UploadForm imageSrc={imageSrc} setImageSrc={setImageSrc} />
          <div css={copyrightWrapper}>
            <span css={copyright}>{t('upload.copyright_unlink_1')}</span>
            <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
              <span css={link}>{t('upload.copyright_link')}</span>
            </Link>
            <span css={copyright}>{t('upload.copyright_unlink_2')}</span>
          </div>
        </div>
      </PageTransition>
      <div
        css={
          currentPage === PAINT_PAGE.UPLOAD ? showFixedButton : hideFixedButton
        }
      >
        <FixedButton handleClick={handleNext} disable={!imageSrc} />
      </div>
    </div>
  );
};

export default Upload;
