/** @jsx jsx */
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { jsx, css } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { fadeIn, MDMotion } from 'style/animation';
import { PopupButton } from './PopupButton';
import { PopupTitle } from './PopupTitle';
import { PopupContents } from './PopupContents';

interface IPopupSectionProps {
  title: string;
  contents: string;
  buttonText: string;
  src: string;
  handleClick: () => void;
  error?: boolean;
  bouquetHashLink?: boolean;
}

const wrapper = css`
  ${fadeIn}
  animation: fadeIn 0.36s ${MDMotion} 0s;
  position: absolute;
  background: #222422;
  padding: 50px 0 40px 0;
  margin: auto;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  left: 50%;
  width: calc(100% - 24% * 2);
  border-radius: 4px;
  ${MEDIA_QUERY.TB} {
    width: calc(100% - 16% * 2);
  }
  ${MEDIA_QUERY.SP} {
    width: calc(100% - 8% * 2);
  }
`;

const wrapperBackground = css`
  position: relative;
  background: #000;
  margin: 0 auto;
  border-radius: 8px;
  width: calc(200 / 1440 * 100vw);
  height: calc(200 / 1440 * 100vw);
  ${MEDIA_QUERY.TB} {
    width: calc(200 / 740 * 100vw);
    height: calc(200 / 740 * 100vw);
  }
  ${MEDIA_QUERY.SP} {
    width: calc(200 / 375 * 100vw);
    height: calc(200 / 375 * 100vw);
  }
`;

// TODO APIレスポンスのaspectでwidthとheightを決める。
const imageWrapper = css`
  position: absolute;
  display: block;
  margin: auto;
  width: calc(97 / 1440 * 100vw);
  height: calc(149 / 1440 * 100vw);
  border-radius: 8px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  ${MEDIA_QUERY.TB} {
    width: calc(102 / 740 * 100vw);
    height: calc(157 / 740 * 100vw);
  }

  ${MEDIA_QUERY.SP} {
    width: calc(98 / 375 * 100vw);
    height: calc(152 / 375 * 100vw);
  }
`;

const errorImageWrapper = css`
  margin: 0 30%;
  width: calc(100% - 30% * 2);
  background: transparent;
  ${MEDIA_QUERY.SP} {
    margin: 0 20%;
    width: calc(100% - 20% * 2);
  }
`;

const image = css`
  width: 100%;
  height: 100%;
`;

const errorImage = css`
  width: 50%;
  height: auto;
  margin: 0 auto;
  display: block;
  padding: 24px;
  padding: 0;
`;

const heading = css`
  text-align: center;
  margin: 30px 10%;
`;

const buttonWrapper = css`
  margin: 0 25%;
`;

export const PopupSection: React.FC<IPopupSectionProps> = (props) => {
  const { title, contents, buttonText, src, handleClick, error } = props;

  return (
    <div css={wrapper}>
      <div css={!error && wrapperBackground}>
        <div css={error ? errorImageWrapper : imageWrapper}>
          <img css={error ? errorImage : image} src={src} alt="dialog" />
        </div>
      </div>
      <div css={heading}>
        <PopupTitle>{title}</PopupTitle>
        <PopupContents>{contents}</PopupContents>
      </div>
      {props.bouquetHashLink ? (
        <HashLink smooth to="live#bouquets">
          <div css={buttonWrapper}>
            <PopupButton handleClick={handleClick} text={buttonText} />
          </div>
        </HashLink>
      ) : (
        <div css={buttonWrapper}>
          <PopupButton handleClick={handleClick} text={buttonText} />
        </div>
      )}
    </div>
  );
};
