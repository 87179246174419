/** @jsx jsx
 * SPとPCでヘッダを切り替えるためのWrapperコンポーネント
 */
import React, { Dispatch, SetStateAction, memo } from 'react';
import { jsx } from '@emotion/core';
import useMedia from 'hooks/useMedia';
import { PCHeader } from './PCHeader';
import { SPHeader } from './SPHeader';
import { TBHeader } from './TBHeader';

interface IHeaderProps {
  isHamburgerOpen: boolean;
  setIsHamburgerOpen: Dispatch<SetStateAction<boolean>>;
  transparent: boolean;
  setTransparent: Dispatch<SetStateAction<boolean>>;
  openYoutubePopup: () => void;
  openYourFlowerPopup: () => void;
}

export const Header: React.FC<IHeaderProps> = memo((props) => {
  const { isSP, isTB } = useMedia();

  if (isSP) {
    return (
      <SPHeader
        isHamburgerOpen={props.isHamburgerOpen}
        setIsHamburgerOpen={props.setIsHamburgerOpen}
        transparent={props.transparent}
        setTransparent={props.setTransparent}
        openYoutubePopup={props.openYoutubePopup}
        openYourFlowerPopup={props.openYourFlowerPopup}
      />
    );
  }

  if (isTB) {
    return (
      <TBHeader
        transparent={props.transparent}
        openYoutubePopup={props.openYoutubePopup}
        openYourFlowerPopup={props.openYourFlowerPopup}
      />
    );
  }

  return (
    <PCHeader
      transparent={props.transparent}
      openYoutubePopup={props.openYoutubePopup}
      openYourFlowerPopup={props.openYourFlowerPopup}
    />
  );
});
