import { actions } from './action';

/** コンポーネントからのアクション発行はoperation経由にする(actionとviewの分離) */
export const {
  setImageId,
  setUploaded,
  setUploadError,
  setScanned,
  setWait,
  incrementProgressTime,
  setBouquetCreated,
  setBouquetWait,
  incrementBouquetProgressTime,
  initializeScanState,
} = actions;
