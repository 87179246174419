/** @jsx jsx
 * Links
 */
import React, { memo, useState, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import { MDMotion } from 'style/animation';
import flowersBombingHomeImgPC from 'images/links/flowers-bombing-home-PC.jpg';
import flowersBombingHomeImgSP from 'images/links/flowers-bombing-home-SP.jpg';
import yourFlowerArtImgPC from 'images/links/your-flower-art-PC.jpg';
import yourFlowerArtImgSP from 'images/links/your-flower-art-SP.jpg';
import { HashLink } from 'react-router-hash-link';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import useMedia from 'hooks/useMedia';
import { useTranslation } from 'react-i18next';

const linksWrapper = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #222422;

  ${MEDIA_QUERY.SP} {
    flex-direction: column;
    background-color: #000;
  }
`;

const link = css`
  width: 50%;
  list-style: none;
  border: 1px solid #000;
  box-sizing: border-box;

  ${MEDIA_QUERY.SP} {
    flex-direction: column;
    width: 100%;
  }
`;

const linkImage = css`
  width: 100%;
  height: 414px;
  object-fit: cover;

  ${MEDIA_QUERY.SP} {
    height: 281px;
  }
`;

const captionTitle = css`
  margin: 48px 120px 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.45px;
  white-space: pre-wrap;

  ${MEDIA_QUERY.SP} {
    margin: 30px 30px 20px;
    font-size: 17px;
    line-height: 1.41;
    letter-spacing: 0.47px;
  }
`;

const captionContent = css`
  margin: 0px 120px 40px;
  font-family: HiraginoSans-W3;
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
  letter-spacing: normal;
  white-space: pre-wrap;

  ${MEDIA_QUERY.SP} {
    margin: 0px 30px 30px;
    font-size: 15px;
    line-height: 2;
  }
`;

const anchorWrapper = css`
  margin: 48px 120px 60px;
  width: 200px;
  height: 45px;
  border: solid 1px rgba(255, 255, 255, 0.3);
  text-align: center;
  cursor: pointer;
  transition: all 0.36s ${MDMotion};
  &:hover {
    background-color: #fff;
    color: #0e0e0e;
    border-color: #fff;
    &:after {
      border-color: #000;
    }
  }

  ${MEDIA_QUERY.SP} {
    margin: 0px 30px 60px;
    width: calc(100% - 30px * 2);
  }
`;

const anchorWrapperFlower = css`
  ${anchorWrapper}
  width: 220px;
`;

const anchorWrapperYoutube = css`
  ${anchorWrapper}
`;

const anchorText = css`
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 45px;
`;

const progressBarWrapper = css`
  margin: 48px 120px 60px;
  width: calc(100% - 120px * 2);

  ${MEDIA_QUERY.SP} {
    margin: 0px 30px 60px;
    width: calc(100% - 30px * 2);
  }
`;

const progressTitle = css`
  font-family: HiraKakuPro-W6;
  font-size: 15px;
  letter-spacing: 0.34px;
  color: #ffffff;
`;

const progressFilter = css`
  width: 100%;
  height: 3px;
  border-radius: 4.5px;
  background-color: gray;
  margin-top: 17px;
`;

const filter = (progress?: number) => css`
  width: ${progress ? progress * 100 : 0}%;
  height: 100%;
  border-radius: 4.5px;
  background-color: #fff;
  transition: width 0.5s ease;
`;

interface ILinksProps {
  openYoutubePopup: () => void;
  isFromLive?: boolean;
  progress?: number;
  maxWaitMinute?: number;
}

export const Links: React.FC<ILinksProps> = memo((props) => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const { isSP } = useMedia();
  const { t } = useTranslation();

  useEffect(() => {
    // 100%になった瞬間を見せたいのでtransitionと同じ値の0.5s後に変更する
    if (props.progress === 1) {
      setTimeout(() => {
        setIsCompleted(true);
      }, 500);
    }
  }, [props.progress]);

  const progressBar = (() => {
    if (isCompleted) {
      return (
        <HashLink smooth to="live#bouquets">
          <div css={anchorWrapperFlower}>
            <span css={anchorText}>{t('top.get')}</span>
          </div>
        </HashLink>
      );
    }

    const { maxWaitMinute } = props;

    return (
      <div css={progressBarWrapper}>
        <div css={progressTitle}>
          {maxWaitMinute && t('live.bouquet_wait_time', { maxWaitMinute })}
        </div>
        <div css={progressFilter}>
          <div css={filter(props.progress)} />
        </div>
      </div>
    );
  })();

  const youTubeAnchor = (
    <div css={anchorWrapperYoutube} onClick={() => props.openYoutubePopup()}>
      <span css={anchorText}>{t('live.youtube_button')}</span>
    </div>
  );

  return (
    <ul css={linksWrapper}>
      <li css={link}>
        <img
          css={linkImage}
          src={isSP ? flowersBombingHomeImgSP : flowersBombingHomeImgPC}
          alt="icon"
        />
        <p css={captionTitle}>{t('live.caption_title_1')}</p>
        <p css={captionContent}>{t('live.caption_text_1')}</p>
        {props.isFromLive && youTubeAnchor}
      </li>
      <li css={link}>
        <img
          css={linkImage}
          src={isSP ? yourFlowerArtImgSP : yourFlowerArtImgPC}
          alt="icon"
        />
        <div css={captionTitle}>{t('live.caption_title_2')}</div>
        <div css={captionContent}>{t('live.caption_text_2')}</div>
        {props.isFromLive && progressBar}
      </li>
    </ul>
  );
});
