import { useCallback, useState } from 'react';
import GeoCode from 'react-geocode';
import Axios from 'utils/AxiosUtils';
import { IAddress, ILatLng, IUseGoogleMap } from 'types/GoogleMap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// 言語を統一する
GeoCode.setLanguage('en');

// デフォルト値はチームラボオフィス本社所在地にしておく
const DEFAULT_POSITION = {
  lat: 35.69516788837963,
  lng: 139.76491913777159,
};

// 都市を指定する値(参照: https://developers.google.com/maps/documentation/geocoding/overview?hl=en#Types)
const TYPE_CODE_CITY = 'administrative_area_level_1';

/** GeoCode APIのレスポンスから都市名のみを取得する */
const parseAddress = (addressArray: Array<IAddress>) => {
  const cityName = (() => {
    const candidate = addressArray.filter((address: IAddress) => {
      return address.types[0] && address.types[0] === TYPE_CODE_CITY;
    });

    // eslint-disable-next-line camelcase
    return candidate[0]?.long_name;
  })();

  return cityName;
};

const getCurrentPosition = (): Promise<Position> => {
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0,
  };

  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  );
};

const useGoogleMap = (): IUseGoogleMap => {
  const { t } = useTranslation();

  // 経度緯度のデフォルト値
  const [latLng, setLatLng] = useState<ILatLng>(DEFAULT_POSITION);
  // 最終的に保存するアドレス
  const [userAddress, setUserAddress] = useState<string>('');
  // 入力用のアドレス
  const [inputAddress, setInputAddress] = useState<string>('');
  const [marker, setMarker] = useState<any>({});
  const [mapsObject, setMapsObject] = useState<any>({});
  const [isGettingUserPosition, setIsGettingUserPosition] = useState<boolean>(
    false,
  );
  const [isGettingUserPositionError, setIsGettingUserPositionError] = useState<
    boolean
  >(false);

  /** 変数リセット用関数 */
  const resetAll = () => {
    setUserAddress('');
    setInputAddress('');
    setLatLng(DEFAULT_POSITION);
    setIsGettingUserPosition(false);
    setIsGettingUserPositionError(false);
  };

  /** google map描画時にmarkerとmapsオブジェクトを初期化しておく */
  const initMap = (map: any, maps: any) => {
    const newMarker = new maps.Marker({
      position: latLng,
      map,
      draggable: true,
      animation: maps.Animation.DROP,
      title: 'your location',
    });

    setMarker(newMarker);
    setMapsObject(maps);
  };

  /** 緯度経度から土地の名前を取得する */
  const geoCodeFromLatLng = useCallback((lat: number, lng: number) => {
    GeoCode.fromLatLng(String(lat), String(lng)).then(
      (response) => {
        const addressArray = (() => {
          // 「都市」を示すレスポンスが帰ってきた場合、それを使用する
          const candidate = response.results.find((result: any) => {
            return result.types.some((type: string) => type === TYPE_CODE_CITY);
          });

          // 帰ってこない場合、レスポンスの初めの値を採用する
          if (_.isEmpty(candidate)) {
            return response.results[0].address_components;
          }

          return candidate.address_components;
        })();

        const result = parseAddress(addressArray);

        setLatLng({ lat, lng });
        setUserAddress(result);
      },
      (e) => {
        console.error(e);
      },
    );
  }, []);

  /** 土地の名前から経度・緯度を取得する */
  const geoCodeFromAddress = useCallback(
    (address: string) => {
      GeoCode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;

          // 引数でaddressをもらっているのでそのままaddressをセットしてもいいのだが、英語以外で入力された時のことを考慮し、必ず、経度緯度を基準にアドレスの文字列を取得するようにする
          geoCodeFromLatLng(lat, lng);
        },
        (e) => {
          console.error(e);
        },
      );
    },
    [geoCodeFromLatLng],
  );

  /** 現在地情報からバックエンドAPI経由で都市名を取得する(国によってはgoogle map APIが使用できない。ここだけバックエンドを経由することによって取得できる道を残す) */
  const getUserPosition = useCallback(async () => {
    setIsGettingUserPosition(true);

    try {
      const currentPosition: Position = await getCurrentPosition();
      const { latitude, longitude } = currentPosition.coords;

      const response = await Axios.get(
        `${process.env.REACT_APP_BOMING_SERVER_PREFIX}/api/city`,
        {
          baseURL: process.env.REACT_APP_BOMING_SERVER_HOST,
          params: {
            latitude,
            longitude,
          },
        },
      );

      setLatLng({ lat: latitude, lng: longitude });
      setUserAddress(response.data.cityName);
    } catch (e) {
      console.error('getUserPosition error', e);
      // eslint-disable-next-line no-alert
      alert(t('location.get_position_failure'));
      setUserAddress('');
      setIsGettingUserPositionError(true);
    }

    setIsGettingUserPosition(false);
  }, [t]);

  return {
    resetAll,
    userAddress,
    inputAddress,
    setInputAddress,
    latLng,
    marker,
    mapsObject,
    initMap,
    geoCodeFromLatLng,
    geoCodeFromAddress,
    isGettingUserPosition,
    isGettingUserPositionError,
    getUserPosition,
  };
};

export default useGoogleMap;
