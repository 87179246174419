/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useSelector, useDispatch } from 'react-redux';
import { incrementProgressTime } from 'store/scan/operations';
// import { useTranslation } from 'react-i18next';
import { ProgressImage } from './ProgressImage';
import { ProgressText } from './ProgressText';
import { ProgressBar } from './ProgressBar';
import { RootState } from '../../../store/store';
import { scanMinuteRoundUp } from '../../../constants/staticData';

const wrapper = css`
  background-color: #fff;
  width: 48.62%;
  min-width: 375px;
  border-radius: 6px;
  display: flex;
  justify-content: center;

  ${MEDIA_QUERY.SP} {
    height: 80px;
    min-width: 250px;
    width: 90%;
  }
`;

const inner = css`
  display: flex;
  margin: 10px 9.285% 10px 10px;
  width: 100%;
  ${MEDIA_QUERY.SP} {
    margin: 10px 5.797% 10px 10px;
  }
`;

const rightContent = css`
  height: 60px;
  margin-left: 15px;
  flex: 1 1 0;
`;

const leftContent = css`
  height: 60px;
  width: 60px;
  background: #f2f2f2;
  position: relative;
  border-radius: 4px;
`;

const rightContentText = css`
  color: #000;
  font-size: 15px;
  font-weight: bold;
  margin: 13px 0 15px;
  text-align: left;
  line-height: 15px;

  ${MEDIA_QUERY.SP} {
    font-size: 13px;
    letter-spacing: -0.1px;
  }
`;

const STEP_1_SECOND = 3;
const STEP_2_SECOND = 30;

export const ProgressSnackBar: React.FC = () => {
  const dispatch = useDispatch();

  /** 合計待ち時間(分)  */
  const waitScanTime = useSelector((state: RootState) => state.scan.wait);

  /** 経過時間(分) 1分おきに更新される */
  const progressTime = useSelector(
    (state: RootState) => state.scan.progressTime,
  );

  /** 残り時間(分) */
  const minutesRemaining = Math.max(
    0,
    waitScanTime + scanMinuteRoundUp - progressTime,
  );

  const isUploaded = useSelector((state: RootState) => state.scan.uploaded);

  useEffect(() => {
    /** アップロードが完了した時のみprogressTimeのカウントを開始 */
    if (!isUploaded) return () => undefined;

    /** 1分ごとにprogressTimeをインクリメントする */
    const currentInterval = setInterval(() => {
      dispatch(incrementProgressTime());
    }, 60000);

    /** サーバーからもらった待ち時間(分)経過後にclearIntervalする */
    const finishIntervalTimer = setTimeout(() => {
      clearInterval(currentInterval);
    }, (waitScanTime - progressTime + scanMinuteRoundUp) * 60000);

    return () => {
      clearTimeout(finishIntervalTimer);
      clearInterval(currentInterval);
    };

    // waitTimeをdepsに持つと残り時間変更ごとにレンダリング走ってしまうので外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitScanTime, dispatch, isUploaded]);

  /** step1Sec, step2Sec, step3Secはそれぞれ各フェーズの所要時間を表し、mount時にsetされてから基本的に更新されない
   * このそれぞれの値がprogress-filterのcss-animationの所要時間になる */
  const [step1Sec, setStep1Sec] = useState<number>(STEP_1_SECOND);
  const [step2Sec, setStep2Sec] = useState<number>(STEP_2_SECOND);
  const [step3Sec, setStep3Sec] = useState<number>(
    (() => {
      const secondsRemaining = minutesRemaining * 60;
      const result = secondsRemaining - (step1Sec + step2Sec);

      // 一応エラーハンドリングしておく
      if (result < 0) {
        return 0;
      }

      return result;
    })(),
  );
  const [isStep1Visible, setIsStep1Visible] = useState<boolean>(true);
  const [isStep2Visible, setIsStep2Visible] = useState<boolean>(false);
  const [isStep3Visible, setIsStep3Visible] = useState<boolean>(false);
  const [activeStepNum, setActiveStepNum] = useState<number>(1);

  // スキャンが終わったかどうか
  const isScanned = useSelector((state: RootState) => state.scan.scanned);

  useEffect(() => {
    const step1UnVisibleTimer = setTimeout(() => {
      setIsStep1Visible(false);
    }, (step1Sec - 1) * 1000);

    const step2VisibleTimer = setTimeout(() => {
      setIsStep2Visible(true);
      setActiveStepNum(2);
    }, step1Sec * 1000);

    const step2UnVisibleTimer = setTimeout(() => {
      setIsStep2Visible(false);
    }, (step1Sec + step2Sec - 1) * 1000);

    const step3VisibleTimer = setTimeout(() => {
      setIsStep3Visible(true);
      setActiveStepNum(3);
    }, (step1Sec + step2Sec) * 1000);

    // スキャン完了したら、progressFilterの進捗を100%にしたい
    if (isScanned) {
      clearTimeout(step1UnVisibleTimer);
      clearTimeout(step2UnVisibleTimer);
      clearTimeout(step2VisibleTimer);
      clearTimeout(step3VisibleTimer);

      setIsStep2Visible(false);
      setIsStep3Visible(true);
      setActiveStepNum(3);

      setStep1Sec(0);
      setStep2Sec(0);
      setStep3Sec(1);
    }

    return () => {
      clearTimeout(step1UnVisibleTimer);
      clearTimeout(step2UnVisibleTimer);
      clearTimeout(step2VisibleTimer);
      clearTimeout(step3VisibleTimer);
    };
  }, [step1Sec, step2Sec, isScanned]);

  return (
    <div css={wrapper}>
      <div css={inner}>
        <div css={leftContent}>
          <ProgressImage
            isStep1Visible={isStep1Visible}
            isStep2Visible={isStep2Visible}
            isStep3Visible={isStep3Visible}
          />
        </div>
        <div css={rightContent}>
          <div css={rightContentText}>
            <ProgressText activeStepNum={activeStepNum} />
          </div>
          <ProgressBar
            step1Sec={step1Sec}
            step2Sec={step2Sec}
            step3Sec={step3Sec}
            activeStepNum={activeStepNum}
          />
        </div>
      </div>
    </div>
  );
};
