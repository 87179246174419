/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import logo from 'images/header/logo.svg';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

const icon = css`
  vertical-align: middle;
  width: 136px;
  height: 42px;
  ${MEDIA_QUERY.SP} {
    width: 102px;
    height: 31px;
  }
`;

/**
 * ヘッダに表示するロゴ
 * リンク先設定なし。タップ不可
 */
export const Logo: React.FC = () => {
  return <img src={logo} css={icon} alt="logo" />;
};
