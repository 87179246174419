import { useCallback, useState } from 'react';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { getImageStatusAPI } from '../actions/ImageAction';
import { SCAN_STATUS } from '../constants/enum';

interface IUseScanStatusCheck {
  scanStatus: SCAN_STATUS;
  checkScanStatus: () => Promise<void>;
}

const useScanStatusCheck = (): IUseScanStatusCheck => {
  /** アップロードした画像のimageId   */
  const imageId = useSelector((state: RootState) => state.scan.imageId);

  /* スキャンステータス */
  const [scanStatus, setScanStatus] = useState<SCAN_STATUS>(
    SCAN_STATUS.NOT_FOUND,
  );

  /* スキャンステータスを取得する */
  const checkScanStatus = useCallback(async (): Promise<void> => {
    try {
      /* スキャンステータス確認API */
      const statusResponse = await getImageStatusAPI(imageId);

      /*  分岐パターン
       *  - status === NOT_FOUND
       *  - status === SUCCESS
       *  - status === SCAN_ERROR
       *  - status === POLICY_ERROR
       */
      if (statusResponse.status === SCAN_STATUS.SUCCESS) {
        setScanStatus(SCAN_STATUS.SUCCESS);

        return;
      }
      if (statusResponse.status === SCAN_STATUS.POLICY_ERROR) {
        setScanStatus(SCAN_STATUS.POLICY_ERROR);

        return;
      }
      if (statusResponse.status === SCAN_STATUS.SCAN_ERROR) {
        setScanStatus(SCAN_STATUS.SCAN_ERROR);
      }
    } catch (e) {
      // noop
    }
  }, [imageId]);

  return {
    scanStatus,
    checkScanStatus,
  };
};

export default useScanStatusCheck;
