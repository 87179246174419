/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { MDMotion } from 'style/animation';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { PAGE_PATH } from 'constants/enum';
import i18n from 'locales/i18n';
import { HEADER_HEIGHT_SP } from 'style/common';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface IHamburgerMenuProps {
  isVisible: boolean;
  handleLinkClick: () => void;
  openYoutubePopup: () => void;
  openYourFlowerPopup: () => void;
}

const wrapper = css`
  position: fixed;
  top: ${HEADER_HEIGHT_SP}px;
  z-index: 10;
  width: 100vw;
  height: 100%;
  background-color: #000000;
  overflow: overlay;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.36s ${MDMotion}, visibility 0.36s ${MDMotion};
`;

const hiddenWrapper = css`
  ${wrapper}
  opacity: 0;
  visibility: hidden;
`;

const borderWrapper = css`
  margin: 0 30px;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  &:first-of-type {
    padding: 25px 0 15px;
  }
`;

const linkText = css`
  padding: 15px 0;
  font-size: 15px;
  line-height: 0.8;
  letter-spacing: 0.3px;
`;

/**
 * SP用ハンバーガメニュー
 */
export const HamburgerMenu: React.FC<IHamburgerMenuProps> = (props) => {
  const history = useHistory();
  /** 多言語文言用 */
  const { t } = useTranslation();

  const handleTransferPage = (pagePath: PAGE_PATH) => {
    history.push(pagePath);
    props.handleLinkClick();
  };

  const handleOpenYoutubePopup = () => {
    props.openYoutubePopup();
    props.handleLinkClick();
  };

  const handleOpenYourFlowerPopup = () => {
    props.openYourFlowerPopup();
    props.handleLinkClick();
  };

  const handleLinkClick = () => {
    props.handleLinkClick();
  };

  // ハンバーガーメニューDOM
  const targetRef = useRef<HTMLDivElement>(null);

  // ハンバーガー表示時のスクロール制御
  useEffect(() => {
    if (targetRef.current && props.isVisible) {
      // 背景スクロール禁止
      disableBodyScroll(targetRef.current);
    } else if (targetRef.current && !props.isVisible) {
      enableBodyScroll(targetRef.current);
    }
  }, [props.isVisible, targetRef]);

  return (
    <div ref={targetRef} css={props.isVisible ? wrapper : hiddenWrapper}>
      <ul css={borderWrapper}>
        <HashLink smooth to="/#top" onClick={handleLinkClick}>
          <li css={linkText}>{t('header.top')}</li>
        </HashLink>
        <HashLink smooth to="/#how-to" onClick={handleLinkClick}>
          <li css={linkText}>{t('header.how_to')}</li>
        </HashLink>
        <HashLink smooth to="/#textures" onClick={handleLinkClick}>
          <li css={linkText}>{t('header.pics')}</li>
        </HashLink>
      </ul>
      <ul css={borderWrapper}>
        <li css={linkText} onClick={handleOpenYoutubePopup}>
          {t('top.watch')}
        </li>
        <li css={linkText} onClick={handleOpenYourFlowerPopup}>
          {t('top.get')}
        </li>
        <li css={linkText} onClick={() => handleTransferPage(PAGE_PATH.PAINT)}>
          {t('top.paint')}
        </li>
      </ul>
      <ul css={borderWrapper}>
        <a
          href={`https://www.teamlab.art/${i18n.language}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <li css={linkText}>{t('header.teamlab')}</li>
        </a>
      </ul>
    </div>
  );
};
