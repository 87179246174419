/** @jsx jsx
 * MainVideo
 */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import YouTube from 'react-youtube';
import { MDMotion } from 'style/animation';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import playIcon from 'images/top/artworks/play-icon.svg';

interface IMainVideoProps {
  videoId: string;
  thumbnail: string;
}

const mainVideoInner = css`
  width: 100vw;
  height: 100%;
`;

const mainVideoArea = css`
  width: calc(100vw - 8.3vw * 2);
  height: 100%;
  margin: 0 auto;
  display: grid;
  ${MEDIA_QUERY.TB} {
    width: calc(100vw - 40px * 2);
    height: 380px;
    float: none;
  }
  ${MEDIA_QUERY.SP} {
    width: 100vw;
    height: 100vw;
    float: none;
  }
`;

const thumbnailWrapper = css`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.36s ${MDMotion}, transform 0.6s ${MDMotion};
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.8;
    }
  }
`;

const thumbnail = css`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

// beforeが黒三角で、afterが白丸
const playButton = css`
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 2px;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1.5em;
    height: 1.5em;
    margin: auto;
    background-image: url(${playIcon});
  }
  &:after {
    content: '';
    position: absolute;
    width: 70px;
    height: 70px;
    top: 50%;
    left: 50%;
    margin: -35px;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

export const MainVideo: React.FC<IMainVideoProps> = (props) => {
  const [isYoutubeVisible, setIsYoutubeVisible] = useState<boolean>(false);

  /** クリックするまではサムネイル画像を表示して、クリックしたらYoutube埋め込み */
  const video = isYoutubeVisible ? (
    <YouTube
      videoId={props.videoId}
      opts={{
        width: '100%',
        height: '100%',
        playerVars: {
          modestbranding: 1, // Youtubeロゴを表示しない
          rel: 0, // 表示される関連動画を同チャンネルのみにする
          playsinline: 1, // iOSでインライン再生する
        },
      }}
      onReady={(event) => {
        const player = event.target;
        player.mute(); // 消音しないと自動再生できない
        player.playVideo(); // 自動再生させる
      }}
    />
  ) : (
    <div css={thumbnailWrapper}>
      <div
        css={thumbnail}
        style={{ backgroundImage: `url(${props.thumbnail})` }}
      />
      <div css={playButton} />
    </div>
  );

  return (
    <div css={mainVideoInner}>
      <div css={mainVideoArea} onClick={() => setIsYoutubeVisible(true)}>
        {video}
      </div>
    </div>
  );
};
