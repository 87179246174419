/** @jsx jsx */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jsx, css } from '@emotion/core';
import { pageTransitionFadeIn } from 'style/animation';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import {
  Links,
  TexturePopup,
  BouquetPopup,
  ProgressSnackBar,
  BouquetListPC,
  BouquetListSP,
  Footer,
  Loading,
  FadeInOutTransition,
} from 'components/partials';
import { RootState } from 'store/store';
import {
  setScanned,
  setBouquetCreated,
  setBouquetWait,
  incrementBouquetProgressTime,
} from 'store/scan/operations';
import useInterval from 'hooks/useInterval';
import useScanStatusCheck from 'hooks/useScanStatusCheck';
import useBouquetsCheck from 'hooks/useBouquetsCheck';
import useMedia from 'hooks/useMedia';
import { PAGE_PATH, SCAN_STATUS } from 'constants/enum';
import { BOUQUETS_STATUS } from '../constants/enum';
import { BOUQUETS_TIMEOUT_MINUTES } from '../constants/api';
import { scanMinuteRoundUp } from '../constants/staticData';

const container = css`
  ${pageTransitionFadeIn}
`;

const barWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${MEDIA_QUERY.SP} {
    margin-top: 0px;
  }
`;

const title = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  margin-top: 50px;
`;

const linksWrapper = css`
  margin-top: 50px;
`;

const loadingWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  ${MEDIA_QUERY.SP} {
    height: 160px;
  }
`;

interface ILiveProps {
  openYoutubePopup: () => void;
}

const INTERVAL_MILLISECOND = 5000;
const SHOW_DELAY_MILLISECOND = 1600;

const Live: React.FC<ILiveProps> = (props) => {
  // 基礎情報定義
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { isSP } = useMedia();

  // useState関係定義
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isBouquetPopupOpen, setIsBouquetPopupOpen] = useState<boolean>(false);

  // redux関係定義
  /** 合計待ち時間(分)  */
  const waitScanTime = useSelector((state: RootState) => state.scan.wait);

  /** 経過時間(分) 1分おきに更新される */
  const progressTime = useSelector(
    (state: RootState) => state.scan.progressTime,
  );
  const isUploaded = useSelector((state: RootState) => state.scan.uploaded);
  const isUploadError = useSelector(
    (state: RootState) => state.scan.uploadError,
  );
  const isScanned = useSelector((state: RootState) => state.scan.scanned);
  const isBouquetCreated = useSelector(
    (state: RootState) => state.scan.bouquetCreated,
  );
  const bouquetWait = useSelector((state: RootState) => state.scan.bouquetWait);
  const bouquetProgressTime = useSelector(
    (state: RootState) => state.scan.bouquetProgressTime,
  );

  // hooks関係呼び出し
  const { scanStatus, checkScanStatus } = useScanStatusCheck();
  const {
    bouquetsResponse,
    bouquetsStatus,
    bouquetsCheck,
  } = useBouquetsCheck();

  // interval処理実行関係
  useInterval(() => checkScanStatus(), INTERVAL_MILLISECOND, isScanned, true);
  useInterval(
    () => bouquetsCheck(INTERVAL_MILLISECOND),
    INTERVAL_MILLISECOND,
    isBouquetCreated,
    true,
  );

  // useEffect関係定義
  useEffect(() => {
    // マウント時、タイムアウト時間設定しておく
    dispatch(setBouquetWait(BOUQUETS_TIMEOUT_MINUTES));
  }, [dispatch]);

  useEffect(() => {
    // NOT_FOUNDの場合はAPIの仕様的にまだ処理中のためポップアップは開かない
    if (scanStatus === SCAN_STATUS.NOT_FOUND) {
      setIsPopupOpen(false);
    } else {
      dispatch(setScanned(true));
    }
  }, [dispatch, scanStatus]);

  useEffect(() => {
    // Waitingの時は取得中だから何もしない
    if (bouquetsStatus === BOUQUETS_STATUS.WAITING) {
      setIsBouquetPopupOpen(false);
    } else {
      dispatch(setBouquetCreated(true));
      setIsBouquetPopupOpen(true);

      // 完了したらprogressBarを100%にしたい
      const progressRestMinutes = bouquetWait - bouquetProgressTime;
      dispatch(incrementBouquetProgressTime(progressRestMinutes));
    }
  }, [bouquetProgressTime, bouquetWait, bouquetsStatus, dispatch, scanStatus]);

  useEffect(() => {
    // 成功後にリロードした時などに備え、isBouquetCreated後にも一度bouquetCheckを実行しておく
    if (isBouquetCreated) {
      bouquetsCheck(0);
    }
  }, [bouquetsCheck, isBouquetCreated]);

  // Live遷移時スクロール位置を一番上にする
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** スキャン中かどうかの判定 */
  useEffect(() => {
    if (isUploaded && isScanned) {
      if (progressTime < waitScanTime + scanMinuteRoundUp) {
        // アニメーションが終了していない場合、ProgressSnackBarが100%になった瞬間を見せたいのでtimeoutで少し遅らせて実行する
        setTimeout(() => {
          setIsScanning(false);
          setIsPopupOpen(true);
        }, SHOW_DELAY_MILLISECOND);
      } else {
        // アニメーションが終了している場合はそのまま
        setIsScanning(false);
        setIsPopupOpen(true);
      }
    } else {
      setIsScanning(true);
    }
    // progressTimeとwaitScanTimeが変化したときに以上の処理を行う必要はないので,依存配列から外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanned, isUploaded]);

  // handle系定義
  const handleClose = useCallback(() => {
    setIsPopupOpen(false);
  }, []);
  const handleBouquetClose = useCallback(() => {
    setIsBouquetPopupOpen(false);
  }, []);
  const handleTransferPaint = useCallback(() => {
    setIsPopupOpen(false);
    history.push(PAGE_PATH.PAINT);
  }, [history]);

  const bouquetAvailable =
    isBouquetCreated && bouquetsResponse.bouquets.length > 0;
  const bouquetArea = () => {
    if (bouquetAvailable && isSP) {
      return <BouquetListSP bouquetsResponse={bouquetsResponse} />;
    }

    if (bouquetAvailable && !isSP) {
      return <BouquetListPC bouquetsResponse={bouquetsResponse} />;
    }

    return (
      <div css={loadingWrapper}>
        <Loading />
      </div>
    );
  };

  return (
    <div css={container}>
      <Prompt
        when={!isScanned && isUploaded}
        message={t('live_paging_alert')}
      />

      {isPopupOpen && isScanned && (
        <TexturePopup
          handleClick={
            scanStatus === SCAN_STATUS.SUCCESS
              ? handleClose
              : handleTransferPaint
          }
          scanStatus={scanStatus}
        />
      )}

      {isUploadError && (
        <TexturePopup
          handleClick={handleTransferPaint}
          scanStatus={SCAN_STATUS.UPLOAD_ERROR}
        />
      )}

      {isBouquetPopupOpen && isBouquetCreated && (
        <BouquetPopup
          handleClick={
            bouquetsStatus === BOUQUETS_STATUS.SUCCESS
              ? handleBouquetClose
              : handleTransferPaint
          }
          scanStatus={bouquetsStatus}
        />
      )}
      <FadeInOutTransition shouldMount={isScanning} shouldAppear>
        <div css={barWrapper}>
          <ProgressSnackBar />
        </div>
      </FadeInOutTransition>
      <div css={title}>{t('live.bouquet_title')}</div>
      <div css={linksWrapper}>
        <Links
          isFromLive
          openYoutubePopup={props.openYoutubePopup}
          progress={bouquetProgressTime / bouquetWait}
          maxWaitMinute={bouquetWait}
        />
      </div>
      {bouquetArea()}
      <Footer />
    </div>
  );
};

export default Live;
