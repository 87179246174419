/** @jsx jsx
 * フッタ
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import facebook from 'images/footer/icon_facebook.svg';
import instagram from 'images/footer/icon_instagram.svg';
import twitter from 'images/footer/icon_twitter.svg';
import youtube from 'images/footer/icon_youtube.svg';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import i18n from 'locales/i18n';

const footer = css`
  background-color: #222422;
  width: 100vw;
  height: 136px;
  ${MEDIA_QUERY.SP} {
    height: 168px;
    padding-bottom: 70px;
  }
`;

const menuArea = css`
  float: left;
  padding: 56px 0 0 120px;
  text-align: left;
  ${MEDIA_QUERY.TB} {
    padding-left: 40px;
  }
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const menuUl = css`
  display: flex;
`;

const menuItem = css`
  margin-right: 30px;
`;

const menuLink = css`
  font-size: 12px;
  font-family: 'ff-din-web', sans-serif;
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.5;
    }
  }
`;

const snsArea = css`
  float: right;
  padding: 56px 120px 0 0;
  ${MEDIA_QUERY.TB} {
    padding-right: 40px;
  }
  ${MEDIA_QUERY.SP} {
    float: none;
    padding: 55px 0 30px;
  }
`;

const snsUl = css`
  display: flex;
  ${MEDIA_QUERY.SP} {
    margin: 0 auto;
    width: 240px;
  }
`;

const sns = css`
  margin-left: 30px;
  ${MEDIA_QUERY.SP} {
    margin-left: 0px;
  }
`;

const snsLink = css`
  ${MEDIA_QUERY.SP} {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const snsIcon = css`
  width: 20px;
  height: 20px;
`;

// インスタのSVGが縦長なのでheightで調整
const snsIconInstagram = css`
  width: 20px;
  height: 35px;
`;

const contents = css`
  display: block;
  width: 200px;
  margin: 0 auto;
  opacity: 0.5;
  text-align: center;
`;

const teamlab = css`
  line-height: 136px;
  font-size: 10px;
  font-family: 'ff-din-web', sans-serif;
  ${MEDIA_QUERY.SP} {
    line-height: initial;
  }
`;

export const Footer: React.FC = memo(() => {
  /** 多言語文言用 */
  const { t } = useTranslation();

  return (
    <footer css={footer}>
      <aside css={menuArea}>
        <ul css={menuUl}>
          <li css={menuItem}>
            <a
              css={menuLink}
              href={`https://www.teamlab.art/${i18n.language}/press`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('top.footer.news')}
            </a>
          </li>
          <li css={menuItem}>
            <a
              css={menuLink}
              href={`https://www.teamlab.art/${i18n.language}/jobs`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('top.footer.careers')}
            </a>
          </li>
        </ul>
      </aside>
      <aside css={snsArea}>
        <ul css={snsUl}>
          <li css={sns}>
            <a
              css={snsLink}
              href="https://www.facebook.com/TEAMLAB.inc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img css={snsIcon} src={facebook} alt="facebook" />
            </a>
          </li>
          <li css={sns}>
            <a
              css={snsLink}
              href="https://twitter.com/teamlab_news"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img css={snsIcon} src={twitter} alt="twitter" />
            </a>
          </li>
          <li css={sns}>
            <a
              css={snsLink}
              href="https://www.instagram.com/teamlab/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img css={snsIconInstagram} src={instagram} alt="instagram" />
            </a>
          </li>
          <li css={sns}>
            <a
              css={snsLink}
              href="https://www.youtube.com/channel/UCeVjFSwBvoncOqqEcO98hgQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img css={snsIcon} src={youtube} alt="youtube" />
            </a>
          </li>
        </ul>
      </aside>
      <div css={contents}>
        <small css={teamlab}>© teamLab</small>
      </div>
    </footer>
  );
});
