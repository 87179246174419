import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LANGUAGE_SHORTHAND } from 'constants/enum';
import enTranslation from './en/translation.json';
import jpTranslation from './jp/translation.json';

const resources = {
  [LANGUAGE_SHORTHAND.ENGLISH]: {
    translation: enTranslation,
  },
  [LANGUAGE_SHORTHAND.JAPANESE]: {
    translation: jpTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // デフォルトで表示する言語
    fallbackLng: LANGUAGE_SHORTHAND.ENGLISH,
    // コンソールにログを表示するかどうか. 開発時以外はfalseとする.
    debug: process.env.REACT_APP_ENV === 'local',
    // https://www.i18next.com/translation-function/interpolation#unescape
    interpolation: {
      escapeValue: true,
    },
    // Set to true if you like to wait for loaded in every translated hoc
    react: {
      wait: true,
    },
    // 翻訳文言を読み込ませる
    resources,
    // パスで取得対象にする言語のホワイトリスト. 通常全言語を対象とする.
    whitelist: [LANGUAGE_SHORTHAND.ENGLISH, LANGUAGE_SHORTHAND.JAPANESE],
    // パスから言語指定を取得する設定
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
  });

export default i18n;
