export interface IScanState {
  imageId: string;
  uploaded: boolean;
  uploadError: boolean;
  scanned: boolean;
  wait: number;
  progressTime: number;
  bouquetCreated: boolean;
  bouquetWait: number;
  bouquetProgressTime: number;
}

/** state初期値 */
export const scanState: IScanState = {
  imageId: '',
  uploaded: false,
  uploadError: false,
  scanned: false,
  wait: 0,
  progressTime: 0,
  bouquetCreated: false,
  bouquetWait: 0,
  bouquetProgressTime: 0,
};
