/** @jsx jsx */
import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { jsx, css } from '@emotion/core';
import { ProcessSectionWrapper } from 'components/pages/process';
import {
  FixedButton,
  HeadingSection,
  PageTransition,
} from 'components/partials';
import useUserAgent from 'hooks/useUserAgent';
import { PAINT_PAGE } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import '../../index.css';

interface IProcessProps {
  setCurrentPage: Dispatch<SetStateAction<PAINT_PAGE>>;
  currentPage: PAINT_PAGE;
}

const container = css`
  height: 100%;
`;

const showFixedButton = css`
  display: block;
`;

const hideFixedButton = css`
  display: none;
`;

const Process: React.FC<IProcessProps> = (props) => {
  const { setCurrentPage, currentPage } = props;
  /** 多言語文言用 */
  const { t } = useTranslation();

  const { userAgent, changeAndroid, changeIos, changePrint } = useUserAgent();

  /** 画面遷移する */
  const handleNext = useCallback(() => {
    setCurrentPage(PAINT_PAGE.UPLOAD);
  }, [setCurrentPage]);

  return (
    <div css={container}>
      <PageTransition shouldMount={currentPage === PAINT_PAGE.PROCESS}>
        <HeadingSection
          title={t('process.title')}
          contents={t('process.contents')}
          subTitle={t('process.sub_title')}
        />
        <ProcessSectionWrapper
          userAgent={userAgent}
          changeAndroid={changeAndroid}
          changeIos={changeIos}
          changePrint={changePrint}
        />
      </PageTransition>

      <div
        css={
          currentPage === PAINT_PAGE.PROCESS ? showFixedButton : hideFixedButton
        }
      >
        <FixedButton handleClick={handleNext} />
      </div>
    </div>
  );
};

export default Process;
