/** @jsx jsx
 * 言語を切り替えるコンポーネント
 */
import React, { useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import globalLogo from 'images/header/global.svg';
import { MDMotion } from 'style/animation';
import { useHistory } from 'react-router-dom';
import { LANGUAGE_SHORTHAND, LANGUAGE } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

const wrapper = css`
  position: relative;
`;

const globalLogoWrapper = css`
  width: 30px;
  height: 26px;
`;

const globalIcon = css`
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 16px;
`;

const globalSelectToggle = css`
  position: absolute;
  top: 9px;
  right: 1px;
  width: 4px;
  height: 4px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
  transition: transform 0.35s ${MDMotion};
`;

const globalSelectToggleIsSelected = css`
  ${globalSelectToggle}
  transform: translate3d(0, 3px, 0) rotateX(180deg) rotate(45deg);
`;

const languageListOuter = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
`;

const languageListOuterHidden = css`
  display: none;
`;

const languageList = css`
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: -13px;
  padding: 10px 0;
  background-color: #171717;
  line-height: 40px;
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
  transition: opacity 0.36s ${MDMotion}, visibility 0.36s ${MDMotion},
    transform 0.36s ${MDMotion};
`;

const languageListHidden = css`
  ${languageList}
  transform: initial;
  opacity: 0;
  visibility: hidden;
`;

const languageItem = css`
  display: block;
`;

const languageItemLink = css`
  padding: 10px 10px;
  font-size: 12px;
  color: #fff;
  font-family: 'ff-din-web', sans-serif;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.36s ${MDMotion};
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.5;
    }
  }
`;

const selectedLanguageItemLink = css`
  ${languageItemLink}
  font-weight: bold;
`;

export const LanguageSelect: React.FC = () => {
  /** 言語リストが表示されているかどうか */
  const [isLanguageListVisible, setIsLanguageListVisible] = useState<boolean>(
    false,
  );
  /** 現在表示されているURLを言語切り替え後も引き継ぐために使う */
  const history = useHistory();
  /** 現在選択中の言語 */
  const { i18n } = useTranslation();

  /** 言語リストの表示/非表示を切り替える */
  const toggleLanguageListVisibility = useCallback(
    () => setIsLanguageListVisible((s) => !s),
    [],
  );

  /** 言語リストを非表示にする */
  const hideLanguageList = useCallback(
    () => setIsLanguageListVisible(false),
    [],
  );

  return (
    <div css={wrapper}>
      <div css={globalLogoWrapper} onClick={toggleLanguageListVisibility}>
        <img src={globalLogo} css={globalIcon} alt="logo" />
        <div
          css={
            isLanguageListVisible
              ? globalSelectToggleIsSelected
              : globalSelectToggle
          }
        />
      </div>
      <div
        css={
          isLanguageListVisible ? languageListOuter : languageListOuterHidden
        }
        onClick={hideLanguageList}
      />
      <ul css={isLanguageListVisible ? languageList : languageListHidden}>
        <li css={languageItem}>
          <a
            css={
              i18n.language === LANGUAGE_SHORTHAND.ENGLISH
                ? selectedLanguageItemLink
                : languageItemLink
            }
            href={`/${LANGUAGE_SHORTHAND.ENGLISH}${history.location.pathname}`}
          >
            {LANGUAGE.ENGLISH}
          </a>
        </li>
        <li css={languageItem}>
          <a
            css={
              i18n.language === LANGUAGE_SHORTHAND.JAPANESE
                ? selectedLanguageItemLink
                : languageItemLink
            }
            href={`/${LANGUAGE_SHORTHAND.JAPANESE}${history.location.pathname}`}
          >
            {LANGUAGE.JAPANESE}
          </a>
        </li>
      </ul>
    </div>
  );
};
