/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { fadeIn, MDMotion } from 'style/animation';

interface INicknameFormProps {
  nickname: string;
  handleChange: (text: string) => void;
  isValidNickname: boolean;
  isLengthValidNickname: boolean;
  isErrorName: boolean;
}

const inputWrapper = css`
  width: 100%;
`;

const inputArea = css`
  width: 100%;
  border: none;
  padding: 0;
  overflow: hidden;
  background: #000;
  color: #fff;
  font-size: 20px;
  outline: none;
  height: 50px;
`;

const bar = css`
  border-bottom: 1px solid #fff;
`;

const error = css`
  ${fadeIn}
  animation: fadeIn 0.24s ${MDMotion};
  width: fit-content;
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: #ca3f3f;
  ${MEDIA_QUERY.SP} {
    width: calc(100% - 30px);
    margin-top: 15px;
  }
`;

const errorText = css`
  font-size: 15px;
  line-height: 1.5;
`;

export const NicknameForm: React.FC<INicknameFormProps> = (props) => {
  const {
    nickname,
    handleChange,
    isErrorName,
    isValidNickname,
    isLengthValidNickname,
  } = props;
  /** 多言語文言用 */
  const { t } = useTranslation();

  const updateNickName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e.target.value);
    },
    [handleChange],
  );

  const errorTextArea = useMemo(() => {
    const errors = [];
    // 文字数がオーバーしている場合はエラー文言
    if (isLengthValidNickname) {
      errors.push(
        <div css={error} key="nickname.lengthInvalid">
          <span css={errorText}>{t('nickname.lengthInvalid')}</span>
        </div>,
      );
    }

    // ニックネームが入力ずみで、バリデーションを通っていない場合はエラー文言
    if (nickname && !isValidNickname) {
      errors.push(
        <div css={error} key="nickname.invalid">
          <span css={errorText}>{t('nickname.invalid')}</span>
        </div>,
      );
    }

    // 不適切な文言と判定されていれば不適切エラー文言
    if (isErrorName) {
      errors.push(
        <div css={error} key="nickname.error">
          <span css={errorText}>{t('nickname.error')}</span>
        </div>,
      );
    }

    return errors;
  }, [isLengthValidNickname, nickname, isValidNickname, isErrorName, t]);

  return (
    <div css={inputWrapper}>
      <input
        css={inputArea}
        type="text"
        inputMode="url"
        value={nickname}
        onChange={updateNickName}
        placeholder={t('nickname.placeholder')}
      />
      <div css={bar} />
      {errorTextArea}
    </div>
  );
};
