import { camelCase, snakeCase } from 'change-case';

export const isObject = (target: any): boolean =>
  Object.prototype.toString.call(target).slice(8, -1).toLowerCase() ===
  'object';

export const convertSnakeToCamel = (target: any): void => {
  if (Array.isArray(target)) {
    target.forEach((t) => convertSnakeToCamel(t));
  }
  if (isObject(target)) {
    Object.keys(target).forEach((key) => {
      if (isObject(target[key]) || Array.isArray(target[key])) {
        convertSnakeToCamel(target[key]);
      }
      // スネークケースをキャメルケースに変換する
      if (key.includes('_')) {
        // eslint-disable-next-line no-param-reassign
        target[camelCase(key)] = target[key];
        // eslint-disable-next-line no-param-reassign
        delete target[key];
      }
    });
  }
};

export const convertCamelToSnake = (target: any): void => {
  if (Array.isArray(target)) {
    target.forEach((t) => convertCamelToSnake(t));
  }
  if (isObject(target)) {
    Object.keys(target).forEach((key) => {
      if (isObject(target[key]) || Array.isArray(target[key])) {
        convertCamelToSnake(target[key]);
      }
      // keyが大文字を含めばキャメルケースと見なしてスネークケースに変換する
      if (!/^[a-z]+$/g.test(key)) {
        // eslint-disable-next-line no-param-reassign
        target[snakeCase(key)] = target[key];
        // eslint-disable-next-line no-param-reassign
        delete target[key];
      }
    });
  }
};
