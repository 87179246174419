/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { fadeIn, MDMotion } from 'style/animation';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { ICredit } from '../../../types/api/response/bouquets.d';
// import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';

const wrapper = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  ${MEDIA_QUERY.SP} {
    width: 100vw;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin-left: 10px;
  }
`;

const textureWrapper = css`
  ${fadeIn}
  animation: fadeIn 0.5s ${MDMotion} 0s;
  width: 75px;
  margin-top: 20px;
  margin-right: 10px;

  ${MEDIA_QUERY.SP} {
    flex: 0 0 100px;
    min-width: 100px;
    margin-bottom: 0;
  }
`;

const textureImageWrapper = css`
  margin: auto;
  width: 43px;
  height: 66px;

  ${MEDIA_QUERY.SP} {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const image = css`
  width: 100%;
  height: 100%;

  ${MEDIA_QUERY.SP} {
    width: 43px;
    height: 66px;
  }
`;

const spanWrapper = css`
  display: inline-grid;
  margin: 19px 0 0 0;

  ${MEDIA_QUERY.SP} {
    margin: 0 0 0 15px;
  }
`;

const span = css`
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.58;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IBouquetTextureListProps {
  credits: Array<ICredit>;
}

export const BouquetTextureList: React.FC<IBouquetTextureListProps> = memo(
  (props) => {
    return (
      <div css={wrapper}>
        {props.credits.map((credit) => (
          <div css={textureWrapper} key={credit.textureUrl}>
            <div css={textureImageWrapper}>
              <img css={image} src={credit.textureUrl} alt="texture" />
            </div>
            <div css={spanWrapper}>
              <span css={span}>{credit.date}</span>
              <span css={span}>{credit.cityName}</span>
              <span css={span}>{credit.nickname}</span>
            </div>
          </div>
        ))}
      </div>
    );
  },
);
