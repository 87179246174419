/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { HashLink } from 'react-router-hash-link';
import { fadeIn, MDMotion } from 'style/animation';
import { useTranslation } from 'react-i18next';
import { BouquetTextureList } from './BouquetTextureList';
import { DownLoadButton } from '../downloadButton/DownLoadButton';
import { IBouquetsResponse } from '../../../types/api/response/bouquets.d';

interface IBouquetListProps {
  bouquetsResponse: IBouquetsResponse;
}

const wrapper = css`
  ${fadeIn}
  animation: fadeIn 1s ${MDMotion} 0s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px 0;
  background-color: #0e0f0e;
`;

const bouquetWrapper = css`
  margin: 60px 20px 37px;
`;

const subTitle = css`
  font-size: 13px;
  line-height: 1.73;
  letter-spacing: 0.36px;
  text-align: center;
  margin-bottom: 10px;
`;

const title = css`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.6px;
  text-align: center;
  width: 250px;
  margin: 0 auto 34px;
`;

const imageWrapper = css`
  width: 240px;
  height: 480px;
  margin: 0 auto;
`;

const image = css`
  width: 100%;
  height: 100%;
`;

const downloadButtonWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 40px;
`;

const creditTitle = css`
  font-size: 13px;
  margin: 0px 30px 5px;
`;

const creditWrapper = css`
  width: 100%;
`;

export const BouquetListSP: React.FC<IBouquetListProps> = memo((props) => {
  const { t } = useTranslation();

  return (
    <div css={wrapper} id="bouquets">
      {props.bouquetsResponse.bouquets.map((bouquet, index) => {
        const titleIndex = index + 1;

        return (
          <div key={bouquet.bouquetUrl} css={bouquetWrapper}>
            <HashLink smooth to="#bouquets">
              <div css={subTitle}>
                {t('live.you_flower_art_title', { titleIndex })}
              </div>
              <div css={title}>
                {bouquet.isCity
                  ? t('live.you_flower_art_city')
                  : t('live.you_flower_art_world')}
              </div>
            </HashLink>

            <div css={imageWrapper}>
              <img css={image} src={bouquet.bouquetUrl} alt="bouquet" />
            </div>

            <div css={downloadButtonWrapper}>
              <DownLoadButton
                targetUrl={bouquet.bouquetUrl}
                fileName={t('live.you_flower_art_title', { titleIndex })}
              />
            </div>

            <div css={creditTitle}>{t('live.credits_title')}</div>

            <div css={creditWrapper}>
              <BouquetTextureList credits={bouquet.credits} />
            </div>
          </div>
        );
      })}
    </div>
  );
});
