/** @jsx jsx
 * SPの作品の参加方法
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import { TOP_HOW_TO_LIST } from 'constants/staticData';
import { useTranslation } from 'react-i18next';

const wrapper = css`
  display: flex;
  margin: 0 20px;
  overflow-x: scroll;
`;

const item = css`
  position: relative;
  &:not(:first-of-type) {
    margin-left: 15px;
  }
`;

const imageWrapper = css`
  position: relative;
`;

const image = css`
  width: 295px;
  height: 196px;
  max-height: 222px;
  background-size: cover;
  background-position: center;
`;

const number = css`
  font-size: 13px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 1.15px #fff;
  background-color: #0e0f0e;
  text-align: center;
  line-height: 20px;
`;

const title = css`
  margin: 25px 0 5px 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.73;
`;

const text = css`
  font-size: 15px;
  line-height: 2;
`;

export const SPContentsHowTo: React.FC = memo(() => {
  /** 多言語文言用 */
  const { t } = useTranslation();

  const items = TOP_HOW_TO_LIST.map((i) => {
    return (
      <div css={item} key={i.id}>
        <div css={imageWrapper}>
          <div
            css={image}
            style={{
              backgroundImage: `url(${i.src})`,
            }}
          />
        </div>
        <p css={number}>{i.id}</p>
        <p css={title}>{t(i.title)}</p>
        <p css={text}>{t(i.text)}</p>
      </div>
    );
  });

  return <div css={wrapper}>{items}</div>;
});
