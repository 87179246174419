/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import GoogleMapReact from 'google-map-react';
import GeoCode from 'react-geocode';
import _ from 'lodash';
import { IGoogleMapAutoComplete } from 'types/GoogleMap';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_SHORTHAND, GOOGLE_MAP_TYPE } from 'constants/enum';
import arrowIcon from 'images/location/arrow-icon.svg';
import { PageTransition } from 'components/partials';
import { PlacesAutoComplete } from '../nickname/PlacesAutoComplete';

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;
GeoCode.setApiKey(apiKey);

const flexStart = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const wrapper = css`
  width: 100%;
`;

const inputWrapper = css`
  display: flex;
  flex-direction: column;
`;

const googleMap = css`
  height: 410px;

  ${MEDIA_QUERY.SP} {
    margin-right: 0px;
    margin-bottom: 30px;
    height: 210px;
  }
`;

const autoComplete = css`
  margin-top: 13px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 13px;
`;

const inputCloser = css`
  padding: 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  ${flexStart}
  cursor: pointer;
`;

const iconImgWrapper = css`
  width: 12px;
  height: 16px;
  margin-right: 8px;
`;

const iconImg = css`
  width: 100%;
  height: 100%;
`;

const addressViewer = css`
  ${flexStart}
  font-size: 15px;
  padding: 20px 10px;
`;

const dot = css`
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 8px;
`;

// 5くらいのzoomを初期設定にしておく
const DEFAULT_ZOOM = 5;

export const GoogleMapAutoComplete: React.FC<IGoogleMapAutoComplete> = (
  props,
) => {
  const { t, i18n } = useTranslation();
  const isJapanese = i18n.language === LANGUAGE_SHORTHAND.JAPANESE;

  const {
    resetAll,
    userAddress,
    inputAddress,
    setInputAddress,
    latLng,
    marker,
    mapsObject,
    initMap,
    geoCodeFromLatLng,
    geoCodeFromAddress,
    currentGoogleMapType,
    setCurrentGoogleMapType,
  } = props;

  /** googleMapを閉じる */
  const handleCloseMap = () => {
    setCurrentGoogleMapType(GOOGLE_MAP_TYPE.INDEX);
    resetAll();
  };

  /** google map内クリックしたら経度緯度取得 */
  const handleClick = ({ lat, lng }: { lat: number; lng: number }) => {
    geoCodeFromLatLng(lat, lng);
  };

  /** marker更新 */
  useEffect(() => {
    if (_.isEmpty(mapsObject)) {
      return;
    }

    const mapsObjectLatLng = new mapsObject.LatLng(latLng);
    marker.setPosition(mapsObjectLatLng);
  }, [mapsObject, mapsObject.LatLng, marker, latLng]);

  return (
    <PageTransition
      shouldMount={currentGoogleMapType === GOOGLE_MAP_TYPE.AUTO_COMPLETE}
    >
      <div css={wrapper}>
        <div css={inputWrapper}>
          <div css={inputCloser} onClick={handleCloseMap}>
            <div css={iconImgWrapper}>
              <img src={arrowIcon} css={iconImg} alt="pin-icon" />
            </div>
            <p>{t('location.back')}</p>
          </div>
          <div css={autoComplete}>
            {!_.isEmpty(mapsObject) && (
              <PlacesAutoComplete
                inputAddress={inputAddress}
                setInputAddress={setInputAddress}
                geoCodeFromAddress={geoCodeFromAddress}
              />
            )}
          </div>
          {userAddress && (
            <div css={addressViewer}>
              <div css={dot} />
              <p>{userAddress}</p>
            </div>
          )}
          <div css={googleMap}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: apiKey,
                libraries: ['places', 'geometry'],
                language: isJapanese
                  ? LANGUAGE_SHORTHAND.JAPANESE
                  : LANGUAGE_SHORTHAND.ENGLISH,
              }}
              options={{ fullscreenControl: false }}
              defaultZoom={DEFAULT_ZOOM}
              center={latLng}
              onGoogleApiLoaded={({ map, maps }) => initMap(map, maps)}
              yesIWantToUseGoogleMapApiInternals
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </PageTransition>
  );
};
