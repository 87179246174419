/** @jsx jsx */
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { css, jsx } from '@emotion/core';
import errorImage from 'images/live/error.png';
import { useTranslation } from 'react-i18next';
import useTextureMe from 'hooks/useTextureMe';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import noImage from 'images/live/no_image.png';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { PopupSection } from './PopupSection';
import { BOUQUETS_STATUS } from '../../../constants/enum';

interface ITexturePopupProps {
  scanStatus: BOUQUETS_STATUS;
  handleClick: () => void;
}

const wrapper = css`
  height: 100%;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2000;
`;

const mask = css`
  opacity: 0.7;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  background: #000;
  z-index: 1999;
`;

export const BouquetPopup: React.FC<ITexturePopupProps> = memo((props) => {
  const imageId = useSelector((state: RootState) => state.scan.imageId);
  const { texture, loadTexture } = useTextureMe();
  const { handleClick, scanStatus } = props;

  /** 多言語文言用 */
  const { t } = useTranslation();

  useEffect(() => {
    if (scanStatus === BOUQUETS_STATUS.SUCCESS) {
      loadTexture(imageId);
    }
  }, [imageId, loadTexture, scanStatus]);

  // ポップアップDOM
  const targetRef = useRef<HTMLDivElement>(null);

  // ポップアップ表示時のスクロール制御
  useEffect(() => {
    if (targetRef.current) {
      // 背景スクロール禁止
      disableBodyScroll(targetRef.current);
    }

    // 背景スクロール禁止解除
    return () => clearAllBodyScrollLocks();
  }, [targetRef]);

  const popUpContents = useMemo(() => {
    switch (scanStatus) {
      case BOUQUETS_STATUS.SUCCESS:
        return (
          <PopupSection
            title={t('live.popup_bouquet_title')}
            contents={t('live.popup_bouquet_contents')}
            src={texture?.imageUrl || noImage}
            buttonText={t('live.popup_button')}
            handleClick={handleClick}
            bouquetHashLink
          />
        );
      case BOUQUETS_STATUS.ERROR:
        return (
          <PopupSection
            title={t('live.error_bouquet_popup_title')}
            contents={t('live.scan_error_bouquet_popup_contents')}
            src={errorImage}
            buttonText={t('live.error_popup_button')}
            handleClick={handleClick}
            error
          />
        );
      default:
        return (
          <PopupSection
            title={t('live.error_popup_title')}
            contents={t('live.policy_error_popup_contents')}
            src={errorImage}
            buttonText={t('live.error_popup_button')}
            handleClick={handleClick}
            error
          />
        );
    }
  }, [handleClick, scanStatus, t, texture]);

  return (
    <div ref={targetRef}>
      <div css={wrapper}>{popUpContents}</div>
      <div css={mask} />
    </div>
  );
});
