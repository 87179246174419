/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import GoogleMapReact from 'google-map-react';
import GeoCode from 'react-geocode';
import _ from 'lodash';
import { IGoogleMapNavigator } from 'types/GoogleMap';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_SHORTHAND, GOOGLE_MAP_TYPE } from 'constants/enum';
import { PageTransition } from 'components/partials';
import arrowIcon from 'images/location/arrow-icon.svg';

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;
GeoCode.setApiKey(apiKey);

const flexStart = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const wrapper = css`
  width: 100%;
`;

const inputWrapper = css`
  display: flex;
  flex-direction: column;
`;

const googleMap = css`
  height: 410px;

  ${MEDIA_QUERY.SP} {
    margin-right: 0px;
    margin-bottom: 30px;
    height: 210px;
  }
`;

const inputCloser = css`
  padding: 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  ${flexStart}
  cursor: pointer;
`;

const inputCloserDisable = css`
  ${inputCloser}
  opacity: 0.6;
  cursor: auto;
`;

const iconImgWrapper = css`
  width: 12px;
  height: 16px;
  margin-right: 8px;
`;

const iconImg = css`
  width: 100%;
  height: 100%;
`;

const addressViewer = css`
  ${flexStart}
  font-size: 15px;
  padding: 20px 10px;
`;

const dot = css`
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 8px;
`;

// 5くらいのzoomを初期設定にしておく
const DEFAULT_ZOOM = 5;

export const GoogleMapNavigator: React.FC<IGoogleMapNavigator> = (props) => {
  const { t, i18n } = useTranslation();
  const isJapanese = i18n.language === LANGUAGE_SHORTHAND.JAPANESE;

  const {
    resetAll,
    userAddress,
    latLng,
    marker,
    mapsObject,
    initMap,
    getUserPosition,
    isGettingUserPosition,
    isGettingUserPositionError,
    currentGoogleMapType,
    setCurrentGoogleMapType,
  } = props;

  // このページが選択されたかどうか
  const isPageActive = currentGoogleMapType === GOOGLE_MAP_TYPE.NAVIGATOR;

  /** 遷移時、現在地情報を取得する */
  useEffect(() => {
    if (isPageActive) {
      getUserPosition();
    }
  }, [getUserPosition, isPageActive]);

  /** googleMapを閉じる */
  const handleCloseMap = () => {
    // 取得中はdisable
    if (isGettingUserPosition) {
      return;
    }

    setCurrentGoogleMapType(GOOGLE_MAP_TYPE.INDEX);
    resetAll();
  };

  /** marker更新 */
  useEffect(() => {
    if (_.isEmpty(mapsObject)) {
      return;
    }

    const mapsObjectLatLng = new mapsObject.LatLng(latLng);
    marker.setPosition(mapsObjectLatLng);
  }, [mapsObject, mapsObject.LatLng, marker, latLng]);

  const userAddressEle = (() => {
    let text = null;

    if (isGettingUserPosition) {
      text = <p>{t('location.loading')}</p>;
    }

    if (userAddress) {
      text = <p>{userAddress}</p>;
    }

    if (isGettingUserPositionError) {
      text = <p>{t('location.error')}</p>;
    }

    return (
      <div css={addressViewer}>
        <div css={dot} />
        {text}
      </div>
    );
  })();

  return (
    <PageTransition shouldMount={isPageActive}>
      <div css={wrapper}>
        <div css={inputWrapper}>
          <div
            css={isGettingUserPosition ? inputCloserDisable : inputCloser}
            onClick={handleCloseMap}
          >
            <div css={iconImgWrapper}>
              <img src={arrowIcon} css={iconImg} alt="pin-icon" />
            </div>
            <p>{t('location.back')}</p>
          </div>
          {userAddressEle}
          <div css={googleMap}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: apiKey,
                libraries: ['places', 'geometry'],
                language: isJapanese ? 'jp' : 'en',
              }}
              options={{
                fullscreenControl: false,
                draggingCursor: 'pointer',
                draggableCursor: 'default',
              }}
              defaultZoom={DEFAULT_ZOOM}
              center={latLng}
              onGoogleApiLoaded={({ map, maps }) => initMap(map, maps)}
              draggable={false}
              yesIWantToUseGoogleMapApiInternals
            />
          </div>
        </div>
      </div>
    </PageTransition>
  );
};
