/** @jsx jsx */
import React, { memo, useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { MDMotion } from 'style/animation';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { HEADER_HEIGHT_SP } from 'style/common';
import { PAGE_PATH } from 'constants/enum';
import { useHistory } from 'react-router-dom';
import yourFlowerArtImgPC from 'images/yourFlowerPopup/your-flower-popup-pc.jpg';
import yourFlowerArtImgSP from 'images/yourFlowerPopup/your-flower-popup-sp.jpg';
import useMedia from 'hooks/useMedia';
import { useTranslation } from 'react-i18next';

const mask = css`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1099;
  background: #000;
  opacity: 0.7;
`;

const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 47%;
  min-width: 680px;
  height: fit-content;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: auto;
  z-index: 1100;
  opacity: 1;
  visibility: visible;
  border-radius: 4px;
  background-color: #0e0f0e;
  transition: opacity 0.36s ${MDMotion}, visibility 0.36s ${MDMotion};
  ${MEDIA_QUERY.SP} {
    top: ${HEADER_HEIGHT_SP}px;
    bottom: 0;
    width: 100%;
    min-width: unset;
    max-height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: auto;
    margin: 0;
    transform: translateY(0);
    transition: opacity 0.36s ${MDMotion}, visibility 0.36s ${MDMotion},
      transform 0.36s ${MDMotion};
  }
`;

const wrapperHidden = css`
  ${wrapper}
  opacity: 0;
  visibility: hidden;
  ${MEDIA_QUERY.SP} {
    transform: translateY(76vh);
  }
`;

const inner = css`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

// SPの鑑賞するポップアップはスクロールありなので、閉じるボタンを別で用意して固定する
const spFixedcloseButtonWrapper = css`
  display: none;
  ${MEDIA_QUERY.SP} {
    display: block;
    position: fixed;
    top: ${HEADER_HEIGHT_SP + 15}px;
    right: 15px;
    padding: 5px;
    z-index: 1200;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.36s ${MDMotion}, visibility 0.36s ${MDMotion},
      transform 0.36s ${MDMotion};
  }
`;

const spFixedcloseButtonWrapperHidden = css`
  display: none;
  ${MEDIA_QUERY.SP} {
    display: block;
    opacity: 0;
    visibility: hidden;
    height: 0;
    transform: translateY(76vh);
  }
`;

const closeButtonWrapper = css`
  position: fixed;
  cursor: pointer;
  margin-right: 22px;
  margin-top: 22px;
  cursor: pointer;
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const closeButtonLine = css`
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 10%;
  background: #fff;
`;

const closeButton = css`
  width: 20px;
  height: 20px;
  &:before {
    ${closeButtonLine}
    transform: rotate(-45deg);
  }
  &:after {
    ${closeButtonLine}
    transform: rotate(45deg);
  }
`;

const contents = css`
  margin: 10px 10px 0;
  ${MEDIA_QUERY.SP} {
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
  }
`;

const imageWrapper = css`
  text-align: center;
  width: 100%;
  height: auto;
  ${MEDIA_QUERY.SP} {
    height: auto;
    max-height: unset;
  }
`;

const image = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const textArea = css`
  margin: 40px 60px 0;
  max-height: 267px;
  ${MEDIA_QUERY.SP} {
    height: auto;
    margin: 30px 30px 0 30px;
  }
`;

const title = css`
  font-size: 17px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.47px;
`;

const text = css`
  margin-top: 30px;
  font-size: 15px;
  line-height: 2;
  white-space: pre-wrap;
  ${MEDIA_QUERY.SP} {
    margin-top: 1vh;
  }
`;

const buttonContainer = css`
  margin: 40px 0 0;
  padding: 20px 0 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  bottom: 0;
  background: #0e0f0e;
  ${MEDIA_QUERY.SP} {
    border-top: none;
    position: relative;
    flex-direction: column;
    margin: 0 auto;
  }
`;

const buttonWrapper = css`
  width: 240px;
  height: 45px;
  border: solid 1px rgba(255, 255, 255, 0.3);
  margin: auto;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  transition: background-color 0.36s ${MDMotion}, color 0.36s ${MDMotion};
  ${MEDIA_QUERY.PC} {
    &:hover {
      background-color: #000;
      color: #fff;
      border-color: #fff;
      &:after {
        border-color: #000;
      }
    }
  }
  ${MEDIA_QUERY.SP} {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
`;

const buttonText = css`
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 45px;
  ${MEDIA_QUERY.SP} {
    line-height: 45px;
  }
`;

interface IYourFlowerPopupProps {
  isVisible: boolean;
  closeYourFlowerPopup: () => void;
}

export const YourFlowerPopup: React.FC<IYourFlowerPopupProps> = memo(
  (props) => {
    const { isVisible } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const targetRef = useRef<HTMLDivElement>(null);
    const { isSP } = useMedia();

    // ポップアップ表示時のスクロール制御
    useEffect(() => {
      if (targetRef.current && isVisible) {
        // 背景スクロール禁止
        disableBodyScroll(targetRef.current);
      } else if (targetRef.current && !isVisible) {
        enableBodyScroll(targetRef.current);
      }
    }, [isVisible, targetRef]);

    return (
      <div>
        <div
          css={isVisible && mask}
          onClick={() => props.closeYourFlowerPopup()}
        />
        <div
          css={
            isVisible
              ? spFixedcloseButtonWrapper
              : spFixedcloseButtonWrapperHidden
          }
          onClick={() => props.closeYourFlowerPopup()}
        >
          <div css={{ position: 'relative' }}>
            <div css={closeButton} />
          </div>
        </div>
        <div ref={targetRef} css={isVisible ? wrapper : wrapperHidden}>
          <div css={inner}>
            <div
              css={closeButtonWrapper}
              onClick={() => props.closeYourFlowerPopup()}
            >
              <div css={{ position: 'relative' }}>
                <div css={closeButton} />
              </div>
            </div>
            <div css={contents}>
              <div css={imageWrapper}>
                <img
                  css={image}
                  src={isSP ? yourFlowerArtImgSP : yourFlowerArtImgPC}
                  alt="icon"
                />
              </div>
              <div css={textArea}>
                <p css={title}>{t('your_flower_popup.title')}</p>
                <p css={text}>{t('your_flower_popup.text')}</p>
              </div>
            </div>
          </div>
          <div css={buttonContainer}>
            <div
              css={buttonWrapper}
              onClick={() => {
                history.push(PAGE_PATH.PAINT);
                props.closeYourFlowerPopup();
              }}
            >
              <span css={buttonText}>{t('your_flower_popup.button')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
