/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const contents = css`
  font-size: 15px;
  line-height: 2;
  white-space: break-spaces;
`;

export const Contents: React.FC = (props) => {
  return <span css={contents}>{props.children}</span>;
};
