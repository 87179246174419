/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { processLinkAnimation } from 'style/animation';

interface IUserAgentTabProps {
  text: string;
  selectUserAgent: boolean;
  handleClick: () => void;
}

const currentTab = css`
  position: relative;
  background: inherit;
  outline: none;
  padding: 13px;
  transition: 0.3s;
  font-weight: bold;
  cursor: pointer;
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.5;
    }
  }
  &:after {
    ${processLinkAnimation}
    margin: 0 auto;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background-color: #fff;
  }
`;

const tab = css`
  position: relative;
  background: inherit;
  outline: none;
  padding: 13px;
  transition: 0.3s;
  cursor: pointer;
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.5;
    }
  }
`;

const tabText = css`
  font-size: 13px;
  color: #fff;
  vertical-align: middle;
  line-height: 2px;
`;

export const UserAgentTab: React.FC<IUserAgentTabProps> = memo((props) => {
  const { text, selectUserAgent, handleClick } = props;

  return (
    <div css={selectUserAgent ? currentTab : tab} onClick={handleClick}>
      <span css={tabText}>{text}</span>
    </div>
  );
});
