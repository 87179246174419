/** @jsx jsx */
import React, { memo, useRef } from 'react';
import { ClassNames, css, jsx } from '@emotion/core';
import {
  pageTransitionFadeIn,
  pageTransitionSlideIn,
  pageTransitionSlideOut,
} from 'style/animation';
import { CSSTransition } from 'react-transition-group';

const transitionFadeIn = css(pageTransitionFadeIn);
const transitionSlideIn = css(pageTransitionSlideIn);
const transitionSlideOut = css(pageTransitionSlideOut);
const transitionContentsWrapper = css`
  width: 100%;
`;

interface IPageTransitionProps {
  // マウントフラグ
  shouldMount: boolean;
  // 初回画面フラグ(初回のみfadeInアニメーションの)
  initialMountPage?: boolean;
}

export const PageTransition: React.FC<IPageTransitionProps> = memo((props) => {
  const { shouldMount, initialMountPage } = props;

  // transitionアニメーションを参照するエレメントを参照する
  const targetRef = useRef(null);

  return (
    <ClassNames>
      {({ css: className }) => (
        <CSSTransition
          // 初回はenterが走らないので走らせるために初回画面のみappearを指定
          appear={initialMountPage}
          // マウント条件
          in={shouldMount}
          // 参照するDOMを指定
          nodeRef={targetRef}
          timeout={350}
          classNames={{
            // 初回画面のアニメーション
            appearActive: className(transitionFadeIn),
            // 初回画面以外のアニメーション
            enterActive: className(transitionSlideIn),
            exitActive: className(transitionSlideOut),
          }}
          // inがtrueになるまでマウントしない
          mountOnEnter
          // inがfalseになったらアンマウントする
          unmountOnExit
        >
          <div css={transitionContentsWrapper} ref={targetRef}>
            {props.children}
          </div>
        </CSSTransition>
      )}
    </ClassNames>
  );
});
