import { useState, useCallback, useEffect } from 'react';
import { PROCESS_TYPE } from '../constants/enum';

interface IUseUserAgent {
  userAgent: PROCESS_TYPE;
  changeAndroid: () => void;
  changeIos: () => void;
  changePrint: () => void;
}

// TODO 命名微妙かも
const useUserAgent = (): IUseUserAgent => {
  const [userAgent, setUserAgent] = useState<PROCESS_TYPE>(PROCESS_TYPE.IOS);

  const changeAndroid = useCallback(() => {
    setUserAgent(PROCESS_TYPE.ANDROID);
  }, []);

  const changeIos = useCallback(() => {
    setUserAgent(PROCESS_TYPE.IOS);
  }, []);

  const changePrint = useCallback(() => {
    setUserAgent(PROCESS_TYPE.PRINT);
  }, []);

  // 判定ロジック(Android)
  const isAndroid = useCallback((currentUserAgent: string) => {
    return /android/i.test(currentUserAgent);
  }, []);

  // 判定ロジック(iOS)
  const isIOS = useCallback((currentUserAgent: string) => {
    return /ipad|iphone/i.test(currentUserAgent);
  }, []);

  useEffect(() => {
    const currentUserAgent = navigator.userAgent.toLocaleLowerCase();
    if (isAndroid(currentUserAgent)) {
      changeAndroid();
    } else if (isIOS(currentUserAgent)) {
      changeIos();
    } else {
      changePrint();
    }
  }, [changeAndroid, changeIos, changePrint, isIOS, isAndroid]);

  return {
    userAgent,
    changeAndroid,
    changeIos,
    changePrint,
  };
};

export default useUserAgent;
