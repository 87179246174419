/** @jsx jsx
 * PC用のヘッダ
 */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { HEADER_HEIGHT_PC } from 'style/common';
import { useHistory } from 'react-router-dom';
import { linkAnimation, MDMotion } from 'style/animation';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { PAGE_PATH } from 'constants/enum';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { Logo } from './Logo';
import { LanguageSelect } from './LanguageSelect';

const wrapper = css`
  display: flex;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: calc(100vw - 40px * 2);
  padding: 0 40px;
  text-align: center;
  height: ${HEADER_HEIGHT_PC}px;
  background-color: #000000;
  transition: background-color 0.36s ${MDMotion};
  ${MEDIA_QUERY.TB} {
    display: none;
  }
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const transparentWrapper = css`
  ${wrapper}
  background-color: transparent;
`;

const logoWrapper = css`
  margin: auto 0;
`;

const menuArea = css`
  margin: auto 35px;
`;

const menuList = css`
  display: flex;
  margin: 0;
  padding: 0;
`;

const linkText = css`
  ${linkAnimation}
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.24px;
  cursor: pointer;
`;

const linkArea = css`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
`;

interface IHeaderProps {
  transparent: boolean;
  openYoutubePopup: () => void;
  openYourFlowerPopup: () => void;
}

export const PCHeader: React.FC<IHeaderProps> = (props) => {
  const history = useHistory();
  /** 多言語文言用 */
  const { t } = useTranslation();

  return (
    <div css={props.transparent ? transparentWrapper : wrapper}>
      <div css={logoWrapper}>
        <Logo />
      </div>
      <div css={menuArea}>
        <ul css={menuList}>
          <HashLink smooth to="/#top">
            <li css={linkText}>{t('header.top')}</li>
          </HashLink>
          <HashLink smooth to="/#how-to">
            <li css={linkText}>{t('header.how_to')}</li>
          </HashLink>
          <HashLink smooth to="/#textures">
            <li css={linkText} style={{ paddingRight: 0 }}>
              {t('header.pics')}
            </li>
          </HashLink>
        </ul>
      </div>
      <div css={linkArea}>
        <ul css={menuList}>
          <li
            onClick={() => props.openYoutubePopup()}
            css={linkText}
            style={{ paddingLeft: 0 }}
          >
            {t('top.watch')}
          </li>
          <li onClick={() => props.openYourFlowerPopup()} css={linkText}>
            {t('top.get')}
          </li>
          <li css={linkText} onClick={() => history.push(PAGE_PATH.PAINT)}>
            {t('top.paint')}
          </li>
        </ul>
        <LanguageSelect />
      </div>
    </div>
  );
};
