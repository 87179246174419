/** TOP: 作品の参加方法 */
import howTo1 from 'images/top/howTo/image-howto-1.jpg';
import howTo2 from 'images/top/howTo/image-howto-2.jpg';
import howTo3 from 'images/top/howTo/image-howto-3.jpg';
import howTo4 from 'images/top/howTo/image-howto-4.jpg';
import howTo5 from 'images/top/howTo/image-howto-5.jpg';
/** TOP: VIDEOサムネイル */
import mainVideoThumbnail from 'images/top/mainVideoThumbnail/main-video-thumbnail.jpg';
/** TOP: ARTWORKS */
import artwork1 from 'images/top/artworks/top_artworks1.jpeg';
/** 手順各種アイコン画像 */
import iosFirstIcon from 'images/process/ios/icon/ios-icon-1.png';
import iosThirdIcon from 'images/process/ios/icon/ios-icon-3.png';
import iosFourthIcon from 'images/process/ios/icon/ios-icon-4.png';
import androidSecondIcon from 'images/process/android/icon/android-icon-2.png';
import androidThirdIcon from 'images/process/android/icon/android-icon-3.png';

/** iOS手順画像 */
import iosFirstProcessImageEn from '../images/process/ios/en/ios-1.jpg';
import iosSecondProcessImageEn from '../images/process/ios/en/ios-2.jpg';
import iosThirdProcessImageEn from '../images/process/ios/en/ios-3.jpg';
import iosFourthProcessImageEn from '../images/process/ios/en/ios-4.jpg';
import iosFirstProcessImageJp from '../images/process/ios/ja/ios-1.jpg';
import iosSecondProcessImageJp from '../images/process/ios/ja/ios-2.jpg';
import iosThirdProcessImageJp from '../images/process/ios/ja/ios-3.jpg';
import iosFourthProcessImageJp from '../images/process/ios/ja/ios-4.jpg';

/** Android手順画像 */
import androidFirstProcessImageEn from '../images/process/android/en/android-1.jpg';
import androidSecondProcessImageEn from '../images/process/android/en/android-2.jpg';
import androidThirdProcessImageEn from '../images/process/android/en/android-3.jpg';
import androidFourthProcessImageEn from '../images/process/android/en/android-4.jpg';
import androidFirstProcessImageJp from '../images/process/android/jp/android-1.jpg';
import androidSecondProcessImageJp from '../images/process/android/jp/android-2.jpg';
import androidThirdProcessImageJp from '../images/process/android/jp/android-3.jpg';
import androidFourthProcessImageJp from '../images/process/android/jp/android-4.jpg';

/* Print手順画像 */
import printFirstProcessImage from '../images/process/print/print-1.jpg';
import printSecondProcessImage from '../images/process/print/print-2.jpg';
import printThirdProcessImage from '../images/process/print/print-3.jpg';

/** ダウンロード画像 */
import firstDownloadSource from '../images/download/download_src_1.jpg';
import secondDownloadSource from '../images/download/download_src_2.jpg';
import thirdDownloadSource from '../images/download/download_src_3.jpg';
import fourthDownloadSource from '../images/download/download_src_4.jpg';
import fifthDownloadSource from '../images/download/download_src_5.jpg';

/** ダウンロードの表示用画像 */
import firstDownloadImage from '../images/download/download_img_1.jpg';
import secondDownloadImage from '../images/download/download_img_2.jpg';
import thirdDownloadImage from '../images/download/download_img_3.jpg';
import fourthDownloadImage from '../images/download/download_img_4.jpg';
import fifthDownloadImage from '../images/download/download_img_5.jpg';

/** TOP: フラワーボミングYOUTUBE_ID */
export const YOUTUBE_VIDEO_ID = '2TqRjilNi8o';
/** TOP: MainVideoサムネイル */
export const MAIN_VIDEO_THUMBNAIL = mainVideoThumbnail;

export const YOUTUBE_LIVE_LINK = 'https://www.youtube.com/c/teamLabART/live';

/** ニックネームが入力されてなかった場合の値 */
export const ANONYMOUS = 'Anonymous';

/** 都市名が取得できなかった場合の値 */
export const SOMEWHERE = 'Somewhere';

// TOP: 作品の参加方法
export const TOP_HOW_TO_LIST = [
  {
    id: 1,
    src: howTo1,
    title: 'top.how_to_list.1.title',
    text: 'top.how_to_list.1.text',
  },
  {
    id: 2,
    src: howTo2,
    title: 'top.how_to_list.2.title',
    text: 'top.how_to_list.2.text',
  },
  {
    id: 3,
    src: howTo3,
    title: 'top.how_to_list.3.title',
    text: 'top.how_to_list.3.text',
  },
  {
    id: 4,
    src: howTo4,
    title: 'top.how_to_list.4.title',
    text: 'top.how_to_list.4.text',
  },
  {
    id: 5,
    src: howTo5,
    title: 'top.how_to_list.5.title',
    text: 'top.how_to_list.5.text',
  },
];

// TOP: ARTWORKS
export const TOP_ARTWORKS_LIST = [
  {
    id: 1,
    image: artwork1,
    youtubeVideoId: YOUTUBE_VIDEO_ID,
    title: 'top.artwotrks_list.1.title',
    text: 'top.artwotrks_list.1.text',
    domain: 'https://www.teamlab.art',
    link: '/w/flowers_bombing_home/',
  },
];

// iOS説明データ
export const iosProcess = [
  {
    id: 1,
    src: {
      en: iosFirstProcessImageEn,
      jp: iosFirstProcessImageJp,
    },
    text: 'process.ios_process_list.1.text',
    icon: iosFirstIcon,
  },
  {
    id: 2,
    src: {
      en: iosSecondProcessImageEn,
      jp: iosSecondProcessImageJp,
    },
    text: 'process.ios_process_list.2.text',
  },
  {
    id: 3,
    src: {
      en: iosThirdProcessImageEn,
      jp: iosThirdProcessImageJp,
    },
    text: 'process.ios_process_list.3.text',
    icon: iosThirdIcon,
  },
  {
    id: 4,
    src: {
      en: iosFourthProcessImageEn,
      jp: iosFourthProcessImageJp,
    },
    text: 'process.ios_process_list.4.text',
    icon: iosFourthIcon,
  },
];

// Android説明データ
export const androidProcess = [
  {
    id: 1,
    src: {
      en: androidFirstProcessImageEn,
      jp: androidFirstProcessImageJp,
    },
    text: 'process.android_process_list.1.text',
  },
  {
    id: 2,
    src: {
      en: androidSecondProcessImageEn,
      jp: androidSecondProcessImageJp,
    },
    text: 'process.android_process_list.2.text',
    icon: androidSecondIcon,
  },
  {
    id: 3,
    src: {
      en: androidThirdProcessImageEn,
      jp: androidThirdProcessImageJp,
    },
    text: 'process.android_process_list.3.text',
    icon: androidThirdIcon,
  },
  {
    id: 4,
    src: {
      en: androidFourthProcessImageEn,
      jp: androidFourthProcessImageJp,
    },
    text: 'process.android_process_list.4.text',
  },
];

// Print説明データ
export const printProcess = [
  {
    id: 1,
    src: {
      en: printFirstProcessImage,
      jp: printFirstProcessImage,
    },
    text: 'process.print_process_list.1.text',
  },
  {
    id: 2,
    src: {
      en: printSecondProcessImage,
      jp: printSecondProcessImage,
    },
    text: 'process.print_process_list.2.text',
  },
  {
    id: 3,
    src: {
      en: printThirdProcessImage,
      jp: printThirdProcessImage,
    },
    text: 'process.print_process_list.3.text',
  },
];

// ダウンロードデータ
export const downloadItemList = [
  {
    id: 1,
    src: firstDownloadSource,
    img: firstDownloadImage,
    name: 'download.download_item_list.1.name',
  },
  {
    id: 2,
    src: secondDownloadSource,
    img: secondDownloadImage,
    name: 'download.download_item_list.2.name',
  },
  {
    id: 3,
    src: thirdDownloadSource,
    img: thirdDownloadImage,
    name: 'download.download_item_list.3.name',
  },
  {
    id: 4,
    src: fourthDownloadSource,
    img: fourthDownloadImage,
    name: 'download.download_item_list.4.name',
  },
  {
    id: 5,
    src: fifthDownloadSource,
    img: fifthDownloadImage,
    name: 'download.download_item_list.5.name',
  },
];

/** 画像のスキャンの残り時間(分)を切り上げて処理するための調整変数
 * 例えばサーバーから推定処理時間が2分と返ってきた場合、
 * 実際の処理時間は2分以上3分未満であることが推定される。
 * この時、プログレスバーのアニメーション時間は3分の長さで用意しておくのが妥当であるので、
 * サーバーからの応答に +1 する必要がある。その時にこの変数を利用する */
export const scanMinuteRoundUp = 1;
