/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { pageTransitionFadeIn } from 'style/animation';
import { Footer } from 'components/partials';
import { useTranslation } from 'react-i18next';

const container = css`
  ${pageTransitionFadeIn}
  background-color: #fff;
`;

const wrapper = css`
  color: #000;
  margin: 0px;
  padding: 80px 19vw 121.5px;
  font-size: 13px;

  ${MEDIA_QUERY.SP} {
    padding: 30px 20px 99.5px;
  }
`;

const paragraphWrapper = css`
  display: grid;
  grid-template-columns: 30px 30px 30px 1fr;
  margin-bottom: 40.5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const title = css`
  grid-column: 1 / 6;
  font-size: 20px;
  margin-bottom: 20.5px;
  align-self: end;
`;

const topTitle = css`
  ${title}
  font-weight: bold;
`;

const index = css`
  grid-column: 1;
  align-self: start;
`;

const lowerIndex = css`
  ${index}
  grid-column: 2;
`;

const lowestIndex = css`
  ${index}
  grid-column: 3;
`;

const content = css`
  grid-column: 2 / 6;
  align-self: start;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const lowerContent = css`
  ${content}
  grid-column: 3 / 6;
`;

const lowestContent = css`
  ${content}
  grid-column: 4 / 6;
`;

const topContent = css`
  ${content}
  grid-column: 1 / 6;
`;

const signature = css`
  grid-column: 1 / 6;
  text-align: end;
`;

const TermsOfUse: React.FC = () => {
  /** 多言語文言用 */
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const makeNumbersBetween = (start: number, end: number): Array<number> => {
    const s = start;
    const e = end;
    const num = e - s + 1;

    // Array(数)で[empty * 数]の配列を作成する。だが、emptyのままだとindexが取り出せないので、新規作成した配列をspreadで渡し、[undefined * num]の配列にする。
    return Array(...new Array(num)).map((_, i) => {
      return s + i;
    });
  };

  const makeAlphabetsBetween = (start: string, end: string): Array<string> => {
    const s = start.charCodeAt(0);
    const e = end.charCodeAt(0);
    const num = e - s + 1;

    // Array(数)で[empty * 数]の配列を作成する。だが、emptyのままだとindexが取り出せないので、新規作成した配列をspreadで渡し、[undefined * num]の配列にする。
    return Array(...new Array(num)).map((_, i) => {
      return String.fromCharCode(s + i);
    });
  };

  return (
    <div css={container}>
      <div css={wrapper}>
        {/* Top */}
        <div css={paragraphWrapper}>
          <div css={topTitle}>{t('terms_of_use.topTitle')}</div>
          <div css={topContent}>{t('terms_of_use.topContent')}</div>
        </div>

        {/* Article 1 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_1.title')}</div>

          {makeNumbersBetween(1, 2).map((number) => (
            <React.Fragment key={`article_1-content-${number}`}>
              <div css={index}>{number}.</div>
              <div css={content}>
                {t(`terms_of_use.article_1.content.${number}.text`)}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Article 2 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_2.title')}</div>

          {makeNumbersBetween(1, 2).map((number) => {
            if (number === 1) {
              return (
                <React.Fragment key={`article_2-content-${number}`}>
                  <div css={index}>{number}.</div>
                  <div css={content}>
                    {t(`terms_of_use.article_1.content.${number}.text`)}
                  </div>

                  {makeAlphabetsBetween('a', 'b').map((alphabet) => (
                    <React.Fragment key={`article_2-lower-${alphabet}`}>
                      <div css={lowerIndex}>{alphabet}.</div>
                      <div css={lowerContent}>
                        {t(
                          `terms_of_use.article_2.content.${number}.lowerContent.${alphabet}.text`,
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={`article_2-content-${number}`}>
                <div css={index}>{number}.</div>
                <div css={content}>
                  {t(`terms_of_use.article_2.content.${number}.text`)}
                </div>
              </React.Fragment>
            );
          })}
        </div>

        {/* Article 3 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_3.title')}</div>
          <div css={content}>{t('terms_of_use.article_3.content.text')}</div>
          {makeAlphabetsBetween('a', 'q').map((alphabet) => (
            <React.Fragment key={`article_3-lower-${alphabet}`}>
              <div css={lowerIndex}>{alphabet}.</div>
              <div css={lowerContent}>
                {t(
                  `terms_of_use.article_3.content.lowerContent.${alphabet}.text`,
                )}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Article 4 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_4.title')}</div>

          {makeNumbersBetween(1, 4).map((number) => {
            if (number === 1) {
              return (
                <React.Fragment key={`article_4-content-${number}`}>
                  <div css={index}>{number}.</div>
                  <div css={content}>
                    {t(`terms_of_use.article_4.content.${number}.text`)}
                  </div>

                  {makeAlphabetsBetween('a', 'c').map((alphabet) => (
                    <React.Fragment key={`article_4-lower-${alphabet}`}>
                      <div css={lowerIndex}>{alphabet}.</div>
                      <div css={lowerContent}>
                        {t(
                          `terms_of_use.article_4.content.${number}.lowerContent.${alphabet}.text`,
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={`article_4-content-${number}`}>
                <div css={index}>{number}.</div>
                <div css={content}>
                  {t(`terms_of_use.article_4.content.${number}.text`)}
                </div>
              </React.Fragment>
            );
          })}
        </div>

        {/* Article 5 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_5.title')}</div>

          {makeNumbersBetween(1, 3).map((number) => {
            if (number === 3) {
              return (
                <React.Fragment key={`article_5-content-${number}`}>
                  <div css={index}>{number}.</div>
                  <div css={content}>
                    {t(`terms_of_use.article_5.content.${number}.text`)}
                  </div>

                  {makeAlphabetsBetween('a', 'g').map((alphabet) => (
                    <React.Fragment key={`article_5-lower-${alphabet}`}>
                      <div css={lowerIndex}>{alphabet}.</div>
                      <div css={lowerContent}>
                        {t(
                          `terms_of_use.article_5.content.${number}.lowerContent.${alphabet}.text`,
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={`article_5-content-${number}`}>
                <div css={index}>{number}.</div>
                <div css={content}>
                  {t(`terms_of_use.article_5.content.${number}.text`)}
                </div>
              </React.Fragment>
            );
          })}
        </div>

        {/* Article 6 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_6.title')}</div>

          {makeNumbersBetween(1, 5).map((number) => (
            <React.Fragment key={`article_6-content-${number}`}>
              <div css={index}>{number}.</div>
              <div css={content}>
                {t(`terms_of_use.article_6.content.${number}.text`)}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Article 7 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_7.title')}</div>

          {makeNumbersBetween(1, 2).map((number) => (
            <React.Fragment key={`article_7-content-${number}`}>
              <div css={index}>{number}.</div>
              <div css={content}>
                {t(`terms_of_use.article_7.content.${number}.text`)}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Article 8 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_8.title')}</div>

          {makeNumbersBetween(1, 5).map((number) => (
            <React.Fragment key={`article_8-content-${number}`}>
              <div css={index}>{number}.</div>
              <div css={content}>
                {t(`terms_of_use.article_8.content.${number}.text`)}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Article 9 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_9.title')}</div>
          <div css={content}>{t(`terms_of_use.article_9.content.text`)}</div>
        </div>

        {/* Article 10 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_10.title')}</div>
          <div css={content}>{t(`terms_of_use.article_10.content.text`)}</div>
        </div>

        {/* Article 11 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_11.title')}</div>
          <div css={content}>{t(`terms_of_use.article_11.content.text`)}</div>
        </div>

        {/* Article 12 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_12.title')}</div>
          <div css={content}>{t(`terms_of_use.article_12.content.text`)}</div>
          {makeAlphabetsBetween('a', 'd').map((alphabet) => (
            <React.Fragment key={`article_12-lower-${alphabet}`}>
              <div css={lowerIndex}>{alphabet}.</div>
              <div css={lowerContent}>
                {t(
                  `terms_of_use.article_12.content.lowerContent.${alphabet}.text`,
                )}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Article 13 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_13.title')}</div>

          {makeNumbersBetween(1, 2).map((number) => {
            if (number === 1) {
              return (
                <React.Fragment key={`article_13-content-${number}`}>
                  <div css={index}>{number}.</div>
                  <div css={content}>
                    {t(`terms_of_use.article_13.content.${number}.text`)}
                  </div>

                  {makeAlphabetsBetween('a', 'd').map((lowerAlphabet) => {
                    if (lowerAlphabet === 'b') {
                      return (
                        <React.Fragment
                          key={`article_13-lower-${lowerAlphabet}`}
                        >
                          <div css={lowerIndex}>{lowerAlphabet}.</div>
                          <div css={lowerContent}>
                            {t(
                              `terms_of_use.article_13.content.${number}.lowerContent.${lowerAlphabet}.text`,
                            )}
                          </div>

                          {makeAlphabetsBetween('a', 'e').map(
                            (lowestAlphabet) => {
                              return (
                                <React.Fragment
                                  key={`article_13-lowest-${lowestAlphabet}`}
                                >
                                  <div css={lowestIndex}>
                                    ({lowestAlphabet})
                                  </div>
                                  <div css={lowestContent}>
                                    {t(
                                      `terms_of_use.article_13.content.${number}.lowerContent.${lowerAlphabet}.lowestContent.${lowestAlphabet}.text`,
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            },
                          )}
                        </React.Fragment>
                      );
                    }

                    return (
                      <React.Fragment key={`article_13-lower-${lowerAlphabet}`}>
                        <div css={lowerIndex}>{lowerAlphabet}.</div>
                        <div css={lowerContent}>
                          {t(
                            `terms_of_use.article_13.content.${number}.lowerContent.${lowerAlphabet}.text`,
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={`article_13-content-${number}`}>
                <div css={index}>{number}.</div>
                <div css={content}>
                  {t(`terms_of_use.article_13.content.${number}.text`)}
                </div>
                {makeAlphabetsBetween('a', 'c').map((alphabet) => (
                  <React.Fragment key={`article_13-lower-${alphabet}`}>
                    <div css={lowerIndex}>{alphabet}.</div>
                    <div css={lowerContent}>
                      {t(
                        `terms_of_use.article_13.content.${number}.lowerContent.${alphabet}.text`,
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          })}
        </div>

        {/* Article 14 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_14.title')}</div>
          <div css={content}>{t(`terms_of_use.article_14.content.text`)}</div>
        </div>

        {/* Article 15 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_15.title')}</div>
          <div css={content}>{t(`terms_of_use.article_15.content.text`)}</div>
        </div>

        {/* Article 16 */}
        <div css={paragraphWrapper}>
          <div css={title}>{t('terms_of_use.article_16.title')}</div>

          {makeNumbersBetween(1, 2).map((number) => (
            <React.Fragment key={`article_16-content-${number}`}>
              <div css={index}>{number}.</div>
              <div css={content}>
                {t(`terms_of_use.article_16.content.${number}.text`)}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Signature */}
        <div css={signature}>
          <p>{t(`terms_of_use.signature.date`)}</p>
          <p>{t(`terms_of_use.signature.name`)}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
