import actionCreatorFactory from 'typescript-fsa';
import { PURGE } from 'redux-persist';

const actionCreator = actionCreatorFactory();

const ActionTypes = {
  SET_INITIAL: PURGE,
  SET_IMAGE_ID: 'SET_IMAGE_ID',
  SET_UPLOADED: 'SET_UPLOADED',
  SET_UPLOAD_ERROR: 'SET_UPLOAD_ERROR',
  SET_SCANNED: 'SET_SCANNED',
  SET_WAIT: 'SET_WAIT',
  INCREMENT_PROGRESS_TIME: 'SET_PROGRESS_TIME',
  SET_BOUQUET_CREATED: 'SET_BOUQUET_CREATED',
  SET_BOUQUET_WAIT: 'SET_BOUQUET_WAIT',
  INCREMENT_BOUQUET_PROGRESS_TIME: 'SET_BOUQUET_PROGRESS_TIME',
  INITIALIZE_SCAN_STATE: 'SET_INITIALIZE_SCAN_STATE',
} as const;

/** アクション定義  */
export const actions = {
  setInitial: actionCreator(ActionTypes.SET_INITIAL),
  setImageId: actionCreator<string>(ActionTypes.SET_IMAGE_ID),
  setUploaded: actionCreator<boolean>(ActionTypes.SET_UPLOADED),
  setUploadError: actionCreator<boolean>(ActionTypes.SET_UPLOAD_ERROR),
  setScanned: actionCreator<boolean>(ActionTypes.SET_SCANNED),
  setWait: actionCreator<number>(ActionTypes.SET_WAIT),
  incrementProgressTime: actionCreator(ActionTypes.INCREMENT_PROGRESS_TIME),
  setBouquetCreated: actionCreator<boolean>(ActionTypes.SET_BOUQUET_CREATED),
  setBouquetWait: actionCreator<number>(ActionTypes.SET_BOUQUET_WAIT),
  incrementBouquetProgressTime: actionCreator<number>(
    ActionTypes.INCREMENT_BOUQUET_PROGRESS_TIME,
  ),
  initializeScanState: actionCreator(ActionTypes.INITIALIZE_SCAN_STATE),
};
