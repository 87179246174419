/** @jsx jsx
 * メンテナンス中はメンテナンスページにルーティングする
 */
import { jsx } from '@emotion/core';
import { Route, Redirect } from 'react-router-dom';
import { PAGE_PATH } from 'constants/enum';

export const SupervisedRoute = ({
  children,
  path,
  isUnderMaintenance,
}: any) => {
  return (
    <Route
      path={path}
      render={({ location }) =>
        !isUnderMaintenance ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PAGE_PATH.MAINTENANCE,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
