/** @jsx jsx
 * TBサイズ用のヘッダ
 */
import React, { useState, useCallback, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { HEADER_HEIGHT_PC } from 'style/common';
import { useHistory } from 'react-router-dom';
import {
  linkAnimation,
  MDMotion,
  fadeOutToTop,
  fadeInFromTop,
} from 'style/animation';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { PAGE_PATH } from 'constants/enum';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { Logo } from './Logo';
import { LanguageSelect } from './LanguageSelect';

const header = css`
  position: fixed;
  top: 0;
  z-index: 1000;
  height: auto;
  background-color: #000000;
  transition: background-color 0.36s ${MDMotion};
  transition: height 1s ${MDMotion};
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const wrapper = css`
  display: flex;
  width: calc(100vw - 40px * 2);
  padding: 0 40px;
  text-align: center;
  height: ${HEADER_HEIGHT_PC}px;
`;

const transparent = css`
  background-color: transparent;
`;

const logoWrapper = css`
  margin: auto 0;
`;

const menuArea = css`
  display: block;
  position: sticky;
  width: calc(100vw - 25px * 2);
  padding: 0 25px 20px 25px;
  ${fadeInFromTop}
  animation: fadeInFromTop 0.36s ${MDMotion} 0s;
  animation-fill-mode: forwards;
`;

const menuAreaHidden = css`
  display: none;
  position: sticky;
  width: calc(100vw - 25px * 2);
  padding: 0 25px 20px 25px;
  ${fadeOutToTop}
  animation: fadeOutToTop 0.36s ${MDMotion} 0s;
  animation-fill-mode: forwards;
`;

const menuList = css`
  display: flex;
  margin: 0;
  padding: 0;
`;

const linkText = css`
  ${linkAnimation}
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.24px;
  cursor: pointer;
`;

const linkArea = css`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
`;
interface IHeaderProps {
  transparent: boolean;
  openYoutubePopup: () => void;
  openYourFlowerPopup: () => void;
}

export const TBHeader: React.FC<IHeaderProps> = (props) => {
  /** 現在のスクロール位置 */
  const [scrollTop, setScrollTop] = useState<number>(0);
  /** 2段目のサブヘッダを表示するかどうか */
  const [isVisibleSubHeader, setIsVibleSubHeader] = useState<boolean>(false);
  /** ヘッダの表示非表示の切り替え中かどうか */
  const [isVisibilityChanging, setIsVisibilityChanging] = useState<boolean>(
    false,
  );

  const history = useHistory();
  /** 多言語文言用 */
  const { t } = useTranslation();
  /** 現在選択中の言語 */
  // const { i18n } = useTranslation();

  /** ファーストビューの動画の透明度を変化させるためにスクロールを監視する */
  const watchScroll = useCallback(() => {
    // 現在のスクロール位置.ブラウザによって取る値が違うので全部取って最大値を算出.
    const scroll = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    );
    // スクロールが一番上のときはサブヘッダを表示する
    if (scroll < HEADER_HEIGHT_PC) {
      setIsVibleSubHeader(true);

      return;
    }

    // アニメーション中はスクロール方向での表示非表示の切り替えをしない
    if (isVisibilityChanging) {
      setScrollTop(scroll);

      return;
    }
    setIsVisibilityChanging(true);

    // 下にスクロールしたら隠して、上にスクロールしたら表示する
    if (scroll > scrollTop) {
      setIsVibleSubHeader(false);
    } else {
      setIsVibleSubHeader(true);
    }

    // アニメーション中にスクロール方向での監視が行われないようにタイムアウトを挟む
    setTimeout(() => {
      setIsVisibilityChanging(false);
    }, 360);
    // 現在のスクロール値を保持する
    setScrollTop(scroll);
  }, [isVisibilityChanging, scrollTop]);

  /**
   * スクロールを監視する
   * アンマウント時には監視を止める
   * FIX ME: スクロールのたびにListenerが再設定されるのを止めたい
   */
  useEffect(() => {
    window.addEventListener('scroll', watchScroll, true);

    return () => {
      window.removeEventListener('scroll', watchScroll, true);
    };
  }, [watchScroll]);

  useEffect(() => {
    setIsVibleSubHeader(true);
  }, []);

  return (
    <div css={css(header, props.transparent && transparent)}>
      <div css={wrapper}>
        <div css={logoWrapper}>
          <Logo />
        </div>
        <div css={linkArea}>
          <ul css={menuList}>
            <li onClick={() => props.openYoutubePopup()} css={linkText}>
              {t('top.watch')}
            </li>
            <li onClick={() => props.openYourFlowerPopup()} css={linkText}>
              {t('top.get')}
            </li>
            <li css={linkText} onClick={() => history.push(PAGE_PATH.PAINT)}>
              {t('top.paint')}
            </li>
          </ul>
          <LanguageSelect />
        </div>
      </div>
      <div css={isVisibleSubHeader ? menuArea : menuAreaHidden}>
        <ul css={menuList}>
          <HashLink smooth to="/#top">
            <li css={linkText}>{t('header.top')}</li>
          </HashLink>
          <HashLink smooth to="/#how-to">
            <li css={linkText}>{t('header.how_to')}</li>
          </HashLink>
          <HashLink smooth to="/#textures">
            <li css={linkText}>{t('header.pics')}</li>
          </HashLink>
        </ul>
      </div>
    </div>
  );
};
