// mediaQueryのbreakpoint管理
export const SSP_WIDTH = 330;
export const SP_WIDTH = 740;
export const TB_WIDTH = 1024;
// const PC_WIDTH = 1440;

export const MEDIA_QUERY = {
  SSP: `@media screen and (max-width: ${SSP_WIDTH}px)`,
  SP: `@media screen and (max-width: ${SP_WIDTH}px)`,
  TB: `@media screen and (max-width: ${TB_WIDTH - 1}px)
      and (min-width: ${SP_WIDTH + 1}px)`,
  PC: `@media screen and (min-width: ${TB_WIDTH}px)`,
};
