/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { HashLink } from 'react-router-hash-link';
import { fadeIn, MDMotion } from 'style/animation';
import { useTranslation } from 'react-i18next';
import { BouquetTextureList } from './BouquetTextureList';
import { DownLoadButton } from '../downloadButton/DownLoadButton';
import { IBouquetsResponse } from '../../../types/api/response/bouquets.d';

interface IBouquetListProps {
  bouquetsResponse: IBouquetsResponse;
}

const wrapper = css`
  ${fadeIn}
  animation: fadeIn 1s ${MDMotion} 0s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0;
  background-color: #0e0f0e;
`;

const bouquetWrapper = css`
  min-width: 740px;
  max-width: 790px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;

  & + & {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 60px;
  }

  :last-child {
    padding-bottom: 0px;
  }
`;

const bouquetImg = css`
  flex: 0 0 240px;
`;

const bouquetInfo = css`
  flex: 0 0 440px;
`;

const image = css`
  width: 100%;
`;

const subTitle = css`
  font-size: 13px;
  line-height: 1.73;
  letter-spacing: 0.36px;
  margin-bottom: 10px;
`;

const title = css`
  font-size: 20px;
  letter-spacing: 0.6px;
  margin-bottom: 30px;
`;

const downloadButtonWrapper = css`
  margin-bottom: 71px;
`;

const creditTitle = css`
  font-size: 13px;
`;

const creditWrapper = css`
  width: 100%;
`;

export const BouquetListPC: React.FC<IBouquetListProps> = memo((props) => {
  const { t } = useTranslation();

  return (
    <div css={wrapper} id="bouquets">
      {props.bouquetsResponse.bouquets.map((bouquet, index) => {
        const titleIndex = index + 1;

        return (
          <div key={bouquet.bouquetUrl} css={bouquetWrapper}>
            <div css={bouquetImg}>
              <img css={image} src={bouquet.bouquetUrl} alt="bouquet" />
            </div>
            <div css={bouquetInfo}>
              <HashLink smooth to="#bouquets">
                <div css={subTitle}>
                  {t('live.you_flower_art_title', { titleIndex })}
                </div>
                <div css={title}>
                  {bouquet.isCity
                    ? t('live.you_flower_art_city')
                    : t('live.you_flower_art_world')}
                </div>
              </HashLink>
              <div css={downloadButtonWrapper}>
                <DownLoadButton
                  targetUrl={bouquet.bouquetUrl}
                  fileName={t('live.you_flower_art_title', { titleIndex })}
                />
              </div>
              <div css={creditTitle}>{t('live.credits_title')}</div>
              <div css={creditWrapper}>
                <BouquetTextureList credits={bouquet.credits} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});
