/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { ProcessImage } from './ProcessImage';
import { ProcessContents } from './ProcessContents';

interface IProcessSectionProps {
  id: number;
  icon?: string;
  imgSrc: {
    en: string;
    jp: string;
  };
  text: string;
}

const sectionInner = css`
  width: 100%;
`;

export const ProcessSection: React.FC<IProcessSectionProps> = memo((props) => {
  const { imgSrc, text, icon, id } = props;

  return (
    <div css={sectionInner}>
      <ProcessImage imgSrc={imgSrc} processNum={id} />
      <ProcessContents text={text} icon={icon} />
    </div>
  );
});
