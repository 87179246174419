/** @jsx jsx
 * Contents
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import {
  TextureList,
  Footer,
  FixedLinkButtons,
  Links,
} from 'components/partials';
import { HashLink } from 'react-router-hash-link';
import { linkAnimation, MDMotion } from 'style/animation';
import useTexture from 'hooks/useTexture';
import { useHistory } from 'react-router-dom';
import { PAGE_PATH } from 'constants/enum';
import {
  TOP_ARTWORKS_LIST,
  MAIN_VIDEO_THUMBNAIL,
  YOUTUBE_VIDEO_ID,
} from 'constants/staticData';
import useMedia from 'hooks/useMedia';
import MainLogo from 'images/top/main-logo.png';
import { ContentsHowTo } from './ContentsHowTo';
import { Artist } from './Artist';
import { Artworks } from './Artworks';
import { Exhibition } from './Exhibition';
import { MainVideo } from './MainVideo';

// SPのファーストビュー内のリンクボタンの高さ
const SP_BUTTON_HEIGHT = 46;
// ボタン間の余白
const SP_BUTTON_MARGIN = 10;
// ボタンの個数
const SP_BUTTON_NUM = 3;
// SPのファーストビュー内のリンクボタンエリアの合計の高さ
const SP_BUTTON_AREA_HEIGHT =
  SP_BUTTON_HEIGHT * SP_BUTTON_NUM + SP_BUTTON_MARGIN * (SP_BUTTON_NUM - 1);
// SPのファーストビューの画面下部（リンクボタンエリアの下）にとるマージン
const SP_FIRST_VIEW_BOTTOM_MARGIN = 30;

const firstViewArea = css`
  position: relative;
  z-index: 2;
  height: 100%;
  text-align: center;
`;

// ロゴの位置はヘッダの下端からボタンの上端の真ん中
// 算出方法: 50% - (ボタンの上端からページの下端 / 2） - (ロゴの高さ / 2)
const logoArea = css`
  position: absolute;
  top: calc(50% - 75px - 12.5vw);
  width: calc(100% - 10px * 2);
  padding: 0 10px;
  ${MEDIA_QUERY.TB} {
    top: calc(50% - 75px - 20vw);
  }
  ${MEDIA_QUERY.SP} {
    top: calc(50% - ${SP_BUTTON_AREA_HEIGHT}px / 2 - 28.5vw);
  }
`;

// ロゴは正方形
const mainLogo = css`
  width: 25%;
  ${MEDIA_QUERY.TB} {
    width: 40%;
  }
  ${MEDIA_QUERY.SP} {
    width: 57%;
  }
`;

const firstViewAreaBottom = css`
  position: absolute;
  bottom: 35px;
  width: 100%;
  ${MEDIA_QUERY.SP} {
    bottom: 0;
    top: calc(
      100% - ${SP_BUTTON_AREA_HEIGHT}px - ${SP_FIRST_VIEW_BOTTOM_MARGIN}px
    );
  }
`;

const buttonArea = css`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.36s ${MDMotion}, visibility 0.36s ${MDMotion};
  ${MEDIA_QUERY.SP} {
    flex-direction: column;
    margin: 0 30px 28px;
  }
`;

const linkButton = css`
  width: 210px;
  height: 40px;
  margin-left: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
  background-color: #ffffff;
  &:first-of-type {
    margin-left: 0;
  }
  ${MEDIA_QUERY.SP} {
    width: 100%;
    height: ${SP_BUTTON_HEIGHT}px;
    margin-left: 0;
    &:not(:first-of-type) {
      margin-top: ${SP_BUTTON_MARGIN}px;
    }
  }
`;

const buttonText = css`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: center;
  color: #141414;
  ${MEDIA_QUERY.SP} {
    line-height: ${SP_BUTTON_HEIGHT}px;
    font-size: 14px;
  }
`;

const firstViewTexts = css`
  margin-bottom: 15px;
`;

const firstViewText = css`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: 0.36px;
  text-align: center;
  margin-left: 8px;
  &:first-of-type {
    margin-left: 0;
  }
  ${MEDIA_QUERY.SP} {
    font-size: 13px;
    line-height: 1;
    letter-spacing: 0.3px;
    margin-top: 10px;
    margin-left: 8px;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const tagArea = css`
  display: block;
  overflow-wrap: break-word;
  ${MEDIA_QUERY.SP} {
    display: inline-grid;
  }
`;

const firstViewTextTag = css`
  ${firstViewText}
  ${linkAnimation}
  margin-left: 20px;

  ${MEDIA_QUERY.SP} {
    margin-left: 0;
  }
`;

// SPの場合はタグと日付をファーストビューの外に出しているのでその分paddingを多めにとっている
const contentsWrapper = css`
  padding-top: 80px;

  ${MEDIA_QUERY.SP} {
    padding-top: 154px;
  }
`;

const summary = css`
  margin: 0 19vw 40px 21vw;
  ${MEDIA_QUERY.SP} {
    margin: 0 30px 40px;
  }
`;

const summaryTitle = css`
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.45px;
  white-space: pre-wrap;
  ${MEDIA_QUERY.SP} {
    margin-bottom: 44px;
  }
`;

const summaryContents = css`
  font-size: 15px;
  line-height: 2;
  letter-spacing: normal;
  white-space: pre-wrap;
`;

const videoWrapper = css`
  width: 100%;
  height: 670px;
  padding: 60px 0 40px;
  ${MEDIA_QUERY.TB} {
    height: auto;
    padding: 60px 0 10px;
  }
  ${MEDIA_QUERY.SP} {
    height: auto;
    padding: 40px 0 20px;
  }
`;

const contents = css`
  padding: 80px 0 40px 0;

  ${MEDIA_QUERY.SP} {
    padding: 60px 0 20px 0;
  }
`;

const contentsShort = css`
  padding-top: 80px;

  ${MEDIA_QUERY.SP} {
    padding-top: 80px;
  }
`;

const contentsWhite = css`
  ${contents}
  background-color: #fff;
  color: #000;
`;

const contentsTitle = css`
  margin-left: 8.3vw;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.45px;
  ${MEDIA_QUERY.SP} {
    margin-left: 30px;
    font-size: 17px;
    line-height: 1.41;
    letter-spacing: 0.47px;
  }
`;

const contentsInner = css`
  margin-top: 40px;
  overflow: hidden;
`;

const texturesWrapper = css`
  margin: 0 8.3vw;
`;

const artWorksTitle = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.6px;
  text-align: center;
  ${MEDIA_QUERY.SP} {
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0;
  }
`;

const exhibitionTitle = css`
  ${artWorksTitle}
`;

interface IContentsProps {
  isVisibleFixedLinkButton: boolean;
  openYoutubePopup: () => void;
  openYourFlowerPopup: () => void;
}

export const Contents: React.FC<IContentsProps> = memo((props) => {
  /** 人々が描いた花々に表示する画像 */
  const { textures } = useTexture();

  /** SPサイズかどうか */
  const { isSP } = useMedia();
  /** ページ遷移用 */
  const history = useHistory();
  /** 多言語文言用 */
  const { t } = useTranslation();

  const texturesComponent = (
    <div css={contents} id="textures">
      <HashLink smooth to="#textures">
        <h2 css={contentsTitle}>{t('top.flowers_drawn_by_people')}</h2>
      </HashLink>
      <div css={contentsInner}>
        <div css={texturesWrapper}>
          <TextureList textures={textures} firstViews={12} />
        </div>
      </div>
    </div>
  );

  /** ファーストビューのタグ */
  const tags: Array<string> = t('top.tags', { returnObjects: true });
  const tagComponent = tags.map((tag) => {
    return (
      <a
        key={tag}
        css={firstViewTextTag}
        href={`https://www.instagram.com/explore/tags/${tag}/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {`#${tag}`}
      </a>
    );
  });

  return (
    <div css={css({ height: '100%' })}>
      {isSP && (
        <FixedLinkButtons
          openYoutubePopup={props.openYoutubePopup}
          isVisible={props.isVisibleFixedLinkButton}
        />
      )}
      <div css={firstViewArea}>
        <div css={logoArea}>
          <img css={mainLogo} src={MainLogo} alt="mainLogo" />
        </div>
        <div css={firstViewAreaBottom}>
          <div css={buttonArea}>
            <div css={linkButton} onClick={() => props.openYoutubePopup()}>
              <span css={buttonText}>{t('top.watch')}</span>
            </div>
            <div css={linkButton} onClick={() => props.openYourFlowerPopup()}>
              <span css={buttonText}>{t('top.get')}</span>
            </div>
            <div css={linkButton} onClick={() => history.push(PAGE_PATH.PAINT)}>
              <span css={buttonText}>{t('top.paint')}</span>
            </div>
          </div>
          <div>
            <div css={firstViewTexts}>
              <span css={firstViewText}>
                {t('top.exhibition_content.date')}
              </span>
              <span css={firstViewText}>
                {t('top.exhibition_content.place')}
              </span>
            </div>
          </div>
          <div css={tagArea}>{tagComponent}</div>
        </div>
      </div>
      <div css={contentsWrapper}>
        <div css={summary}>
          <h1 css={summaryTitle}>{t('top.summary.title')}</h1>
          <p css={summaryContents}>{t('top.summary.contents')}</p>
        </div>
        <div css={videoWrapper}>
          <MainVideo
            videoId={YOUTUBE_VIDEO_ID}
            thumbnail={MAIN_VIDEO_THUMBNAIL}
          />
        </div>
        <div css={contents} id="how-to">
          <HashLink smooth to="#how-to">
            <h2 css={contentsTitle}>{t('top.how_to')}</h2>
          </HashLink>
          <div css={contentsInner}>
            <ContentsHowTo />
          </div>
        </div>
        <div css={contents}>
          <Links openYoutubePopup={props.openYoutubePopup} />
        </div>
        {texturesComponent}
        <div css={contentsWhite} id="exhibition">
          <HashLink smooth to="#exhibition">
            <h2 css={exhibitionTitle}>{t('top.exhibition')}</h2>
          </HashLink>
          <div css={contentsInner}>
            <Exhibition />
          </div>
        </div>
        <div css={contentsShort} id="artworks">
          <HashLink smooth to="#artworks">
            <h2 css={artWorksTitle}>{t('top.artworks')}</h2>
          </HashLink>
          <div css={contentsInner}>
            <Artworks artworksList={TOP_ARTWORKS_LIST} />
          </div>
        </div>
      </div>
      <Artist />
      <Footer />
    </div>
  );
});
