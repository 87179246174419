/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

interface IPopupButtonProps {
  handleClick: () => void;
  text: string;
}

const wrapper = css`
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  width: 100%;
  height: 45px;
  font-size: 13px;
  line-height: 45px;
  ${MEDIA_QUERY.PC} {
    cursor: pointer;
    &:hover {
      background-color: #fff;
      color: #0e0e0e;
      border-color: #fff;
    }
  }
`;

export const PopupButton: React.FC<IPopupButtonProps> = memo((props) => {
  const { handleClick, text } = props;

  return (
    <div css={wrapper} onClick={handleClick}>
      {text}
    </div>
  );
});
