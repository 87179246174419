/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';

interface IProgressTextProps {
  activeStepNum: number;
}

const stepText = css`
  &:after {
    content: '...';
  }
`;

const stepTextInner = css`
  margin-left: 15px;
`;

export const ProgressText: React.FC<IProgressTextProps> = (props) => {
  /** 多言語文言用 */
  const { t } = useTranslation();
  const { activeStepNum } = props;

  return (
    <div>
      {activeStepNum === 1 && (
        <p css={stepText}>
          STEP1 <span css={stepTextInner}>{t('live.step1')}</span>
        </p>
      )}
      {activeStepNum === 2 && (
        <p css={stepText}>
          STEP2 <span css={stepTextInner}>{t('live.step2')}</span>
        </p>
      )}
      {activeStepNum === 3 && (
        <p css={stepText}>
          STEP3 <span css={stepTextInner}>{t('live.step3')}</span>
        </p>
      )}
    </div>
  );
};
