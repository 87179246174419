/* eslint-disable no-useless-catch */
import { useCallback } from 'react';
import { postImageApi, postImageMetaApi } from 'actions/ImageAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  setWait,
  setUploaded,
  setUploadError,
  setImageId,
  initializeScanState,
} from 'store/scan/operations';
import { setImageMeta } from 'store/image/operations';
import { RootState } from 'store/store';
import { ANONYMOUS } from 'constants/staticData';

interface IUseImageUpload {
  uploadImage: (
    imageId: string,
    nickname: string,
    cityName: string,
  ) => Promise<void>;
  uploadImageMeta: (nickname: string, cityName: string) => Promise<IImageMeta>;
}

interface IImageMeta {
  imageId: string;
  nickname: string;
  cityName: string;
}

const useImageUpload = (): IUseImageUpload => {
  /** reduxのアクションを呼び出す */
  const dispatch = useDispatch();
  const imageState = useSelector((state: RootState) => state.image);

  /**
   * base64データリクエスト用に加工する(接頭辞をとる)
   * @param src 画像のデータ
   */
  const removeImagePrefix = (src: string): string => {
    return src.split(',')[1];
  };

  /**
   * ニックネームと都市名を送ってStoreに保持する
   */
  const uploadImageMeta = useCallback(
    async (nickname: string, cityName: string): Promise<IImageMeta> => {
      try {
        // アップロード前にスキャン関連の状態を初期化しておく
        dispatch(initializeScanState());

        const fixedNickname = nickname || ANONYMOUS;

        const id = await postImageMetaApi(fixedNickname, cityName);

        dispatch(setImageMeta({ nickname: fixedNickname, cityName }));
        dispatch(setImageId(id));

        return {
          imageId: id,
          cityName,
          nickname: fixedNickname,
        };
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  /**
   * サーバに画像をアップロードする
   */
  const uploadImage = useCallback(
    async (imageId, nickname, cityName): Promise<void> => {
      try {
        const wait = await postImageApi(
          imageId,
          removeImagePrefix(imageState.imageData),
          nickname,
          cityName,
        );
        dispatch(setUploaded(true));
        dispatch(setWait(wait));
      } catch (e) {
        dispatch(setUploadError(true));
      }
    },
    [dispatch, imageState],
  );

  return {
    uploadImage,
    uploadImageMeta,
  };
};

export default useImageUpload;
