/** @jsx jsx */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import {
  HeadingSection,
  FixedButton,
  PageTransition,
} from 'components/partials';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCityName } from 'store/image/operations';
import useGoogleMap from 'hooks/useGoogleMap';
import { SOMEWHERE } from 'constants/staticData';
import { GOOGLE_MAP_TYPE, PAINT_PAGE } from '../../constants/enum';
import { GoogleMapIndex } from '../../components/pages/location/GoogleMapIndex';
import { GoogleMapNavigator } from '../../components/pages/location/GoogleMapNavigator';
import { GoogleMapAutoComplete } from '../../components/pages/location/GoogleMapAutoComplete';

interface ILocationProps {
  currentPage: PAINT_PAGE;
  setCurrentPage: Dispatch<SetStateAction<PAINT_PAGE>>;
}

const container = css`
  height: 100%;
`;

const googleMapWrapper = css`
  display: flex;
  padding: 40px 20vw 180px;
  width: calc(100% - 20vw * 2);

  ${MEDIA_QUERY.TB} {
    padding: 40px 120px 180px;
    width: calc(100% - 120px * 2);
  }
  ${MEDIA_QUERY.SP} {
    padding: 30px 8vw calc(70px + 58px);
    width: calc(100% - 8vw * 2);
  }
`;

const showFixedButton = css`
  display: block;
`;

const hideFixedButton = css`
  display: none;
`;

const Location: React.FC<ILocationProps> = (props) => {
  const [currentGoogleMapType, setCurrentGoogleMapType] = useState<
    GOOGLE_MAP_TYPE
  >(GOOGLE_MAP_TYPE.INDEX);

  const { setCurrentPage, currentPage } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    resetAll,
    userAddress,
    inputAddress,
    setInputAddress,
    latLng,
    marker,
    mapsObject,
    initMap,
    geoCodeFromLatLng,
    geoCodeFromAddress,
    isGettingUserPosition,
    isGettingUserPositionError,
    getUserPosition,
  } = useGoogleMap();

  const execNext = useCallback(
    (cityName: string) => {
      dispatch(setCityName(cityName));
      setCurrentPage(PAINT_PAGE.NICKNAME);
    },
    [dispatch, setCurrentPage],
  );

  const handleSkip = useCallback(() => {
    // skip時はcityNameをSomewhereで実行
    const cityName = SOMEWHERE;
    execNext(cityName);
  }, [execNext]);

  const handleNext = useCallback(() => {
    // 未入力の場合はSomewhereにする
    const cityName = userAddress === '' ? SOMEWHERE : userAddress;
    execNext(cityName);
  }, [execNext, userAddress]);

  const googleMap = (
    <React.Fragment>
      <GoogleMapIndex
        currentGoogleMapType={currentGoogleMapType}
        setCurrentGoogleMapType={setCurrentGoogleMapType}
      />
      <GoogleMapAutoComplete
        resetAll={resetAll}
        userAddress={userAddress}
        inputAddress={inputAddress}
        setInputAddress={setInputAddress}
        latLng={latLng}
        marker={marker}
        mapsObject={mapsObject}
        initMap={initMap}
        geoCodeFromLatLng={geoCodeFromLatLng}
        geoCodeFromAddress={geoCodeFromAddress}
        currentGoogleMapType={currentGoogleMapType}
        setCurrentGoogleMapType={setCurrentGoogleMapType}
      />
      <GoogleMapNavigator
        resetAll={resetAll}
        userAddress={userAddress}
        latLng={latLng}
        marker={marker}
        mapsObject={mapsObject}
        initMap={initMap}
        geoCodeFromLatLng={geoCodeFromLatLng}
        getUserPosition={getUserPosition}
        isGettingUserPosition={isGettingUserPosition}
        isGettingUserPositionError={isGettingUserPositionError}
        currentGoogleMapType={currentGoogleMapType}
        setCurrentGoogleMapType={setCurrentGoogleMapType}
      />
    </React.Fragment>
  );

  return (
    <div css={container}>
      <PageTransition shouldMount={currentPage === PAINT_PAGE.LOCATION}>
        <HeadingSection
          title={t('location.title')}
          contents={t('location.contents')}
        />
        <div css={googleMapWrapper}>{googleMap}</div>
      </PageTransition>

      <div
        css={
          currentPage === PAINT_PAGE.LOCATION
            ? showFixedButton
            : hideFixedButton
        }
      >
        <FixedButton
          disable={isGettingUserPosition || userAddress === ''}
          handleClick={handleNext}
          hasSkip
          handleSkip={handleSkip}
        />
      </div>
    </div>
  );
};

export default Location;
