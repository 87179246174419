/**
 * 画像関連のAPI
 */
import Axios from 'utils/AxiosUtils';
import { SCAN_STATUS } from '../constants/enum';
import {
  IPostImageRequest,
  IPostMetaRequest,
} from '../types/api/request/Image.d';

/**
 * スキャン前画像のメタ情報登録API
 * @param nickname ニックネーム
 * @param cityName 都市名
 * @param status スキャンステータス(NOT_FOUND)
 */
export const postImageMetaApi = async (
  nickname: string,
  cityName: string,
): Promise<string> => {
  const requestBody: IPostMetaRequest = {
    nickname,
    cityName,
    status: SCAN_STATUS.NOT_FOUND,
  };
  try {
    const response = await Axios.post(
      `${process.env.REACT_APP_BOMING_SERVER_PREFIX}/api/images/meta`,
      requestBody,
      {
        baseURL: process.env.REACT_APP_BOMING_SERVER_HOST,
      },
    );

    return response.data.textureId;
  } catch (e) {
    return Promise.reject(new Error(e.response?.data));
  }
};

/**
 * スキャン前画像のアップロードAPI
 * @param id 画像ID（事前にサーバで発行）
 * @param image Base64エンコードした画像
 * @param nickname ニックネーム
 */
export const postImageApi = async (
  textureId: string,
  image: string,
  nickname: string,
  cityName: string,
): Promise<number> => {
  /* TODO: #60 位置情報取得してbodyにセットする */
  const requestBody: IPostImageRequest = {
    textureId,
    image,
    nickname,
    cityName,
  };
  const response = await Axios.post(
    `${process.env.REACT_APP_BOMING_SERVER_PREFIX}/api/images`,
    requestBody,
    {
      baseURL: process.env.REACT_APP_BOMING_SERVER_HOST,
    },
  );

  return response.data.wait;
};

/**
 * スキャンステータス取得
 * TODO: 型定義
 */
export const getImageStatusAPI = async (
  textureId: string,
): Promise<{ status: SCAN_STATUS }> => {
  try {
    const response = await Axios.get(
      `${process.env.REACT_APP_BOMING_SERVER_PREFIX}/api/images/${textureId}/status`,
      {
        baseURL: process.env.REACT_APP_BOMING_SERVER_HOST,
      },
    );

    return response.data;
  } catch (e) {
    return {
      status: SCAN_STATUS.NOT_FOUND,
    };
  }
};
