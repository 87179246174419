/** @jsx jsx
 * SPとPCで作品の参加方法のコンポーネントを切り替えるWrapperコンポーネント
 */
import React, { memo } from 'react';
import { jsx } from '@emotion/core';
import useMedia from 'hooks/useMedia';
import { SPContentsHowTo } from './SPContentsHowTo';
import { PCContentsHowTo } from './PCContentsHowTo';

export const ContentsHowTo: React.FC = memo(() => {
  const { isSP } = useMedia();

  if (isSP) {
    return <SPContentsHowTo />;
  }

  return <PCContentsHowTo />;
});
