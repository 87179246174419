export interface IImageState {
  imageData: string;
  nickname: string;
  cityName: string;
}

/** state初期値 */
export const imageState: IImageState = {
  imageData: '',
  nickname: '',
  cityName: '',
};
