import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

export const MDMotion = 'cubic-bezier(0.215, 0.61, 0.355, 1)';

/**
 * ページ遷移時にページ全体をスライドイン(右から左)する
 */
export const pageTransitionSlideIn = `
  animation: slideIn 0.35s linear;
  animation-fill-mode: both;
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

/**
 * ページ遷移時にページ全体をスライドアウト(右から左)する
 */
export const pageTransitionSlideOut = `
  animation: slideOut 0.35s linear;
  animation-fill-mode: both;
  @keyframes slideOut {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }
`;

/**
 * ページ遷移時にページ全体をフェードインする(Top→Download, Nickname→Live)
 */
export const pageTransitionFadeIn = `
  animation: fadeIn 1s linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const pageTransitionFadeOut = `
 animation: fadeOut 1s linear;
 @keyframes fadeOut {
   0% {
     opacity: 1;
   }
   100% {
     opacity: 0;
   }
 }
`;

/** プロセス画面でのタブ切り替えのアニメーション */
export const processLinkAnimation = `
  animation: linkAnimation 0.2s ${MDMotion};
  @keyframes linkAnimation {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100%;
      opacity: 1;
    }
  }
`;

/** プロセス画面でのコンテンツ表示アニメーション */
// FIXME 何かいいやりかた知ってたら教えて欲しい
export const processContentsAnimation = `
  @keyframes processContentsFadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  :nth-of-type(1) {
    animation-delay: 0.35s;
  }
  :nth-of-type(2) {
    animation-delay: 0.4s;
  }
  :nth-of-type(3) {
    animation-delay: 0.45s;
  }
  :nth-of-type(4) {
    animation-delay: 0.5s;
  }
`;

/** リンクホバー時に透明度を変化させる */
export const linkAnimation = `
  transition: opacity 0.36s ${MDMotion};
  opacity: 1;
  cursor: pointer;
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.5;
    }
  }
`;

/** 要素をフェードインさせる */
export const fadeIn = `
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

/** 要素を上方向からフェードインさせる */
export const fadeInFromTop = `
  @keyframes fadeInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

/** 要素を上方向にフェードインさせる */
export const fadeOutToTop = `
  @keyframes fadeOutToTop {
    0% {
      opacity: 1;
      transform: translateY(0px);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
`;

/** 要素を時計回りに回転させる */
export const loading = `
  @keyframes loading {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;
