/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { MDMotion } from 'style/animation';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_SHORTHAND } from 'constants/enum';
import useMedia from 'hooks/useMedia';

/** srcはダウンロード用, imgは表示用 */
interface IDownloadItemProps {
  src: string;
  img: string;
  name: string;
  wrapper: SerializedStyles;
}

const downloadImageWrapper = css`
  position: relative;
`;

const image = css`
  width: 100%;
  height: 100%;
`;

const spanWrapper = css`
  width: 100%;
  margin-top: 10px;
  height: 45px;
  position: relative;

  ${MEDIA_QUERY.SP} {
    margin-top: 15px;
  }
`;

const spanWrapperJp = css`
  ${spanWrapper}
  margin-top: 3px;
  height: 30px;
`;

const spanBase = css`
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  line-height: 22.5px;
  font-size: 15px;

  ${MEDIA_QUERY.TB} {
    font-size: 13px;
  }

  ${MEDIA_QUERY.SP} {
    font-size: 15px;
  }
`;

const span = css`
  ${spanBase}
  top: 0;
`;

const spanJp = css`
  ${spanBase}
  bottom: 0;

  ${MEDIA_QUERY.TB} {
    font-size: 12px;
  }
`;

const downloadButton = css`
  width: 100%;
  height: 45px;
  line-height: 45px;
  margin-top: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;

  border: solid 1px rgba(255, 255, 255, 0.3);
  text-align: center;
  cursor: pointer;
  transition: all 0.36s ${MDMotion};
  ${MEDIA_QUERY.PC} {
    &:hover {
      background-color: #fff;
      color: #0e0e0e;
      border-color: #fff;
      &:after {
        border-color: #000;
      }
    }
  }
`;

const downloadButtonText = css`
  font-size: 13px;

  ${MEDIA_QUERY.TB} {
    font-size: 11px;
  }

  ${MEDIA_QUERY.SP} {
    font-size: 13px;
  }
`;

export const DownloadItem: React.FC<IDownloadItemProps> = (props) => {
  const { t, i18n } = useTranslation();
  const isJapanese = i18n.language === LANGUAGE_SHORTHAND.JAPANESE;

  /** SP, TBサイズかどうか */
  const { isSP, isTB } = useMedia();

  return (
    <div css={props.wrapper}>
      <div css={downloadImageWrapper}>
        <img css={image} src={props.img} alt="flower_download_image" />
      </div>
      <div css={isJapanese ? spanWrapperJp : spanWrapper}>
        <span css={isJapanese ? spanJp : span}>{props.name}</span>
      </div>
      {
        // SP, TBの時は別タブで画像を開く
        isSP || isTB ? (
          <a href={props.src} target="_blank" rel="noopener noreferrer">
            <div css={downloadButton}>
              <span css={downloadButtonText}>{t('download.button')}</span>
            </div>
          </a>
        ) : (
          <a href={props.src} download={props.name}>
            <div css={downloadButton}>
              <span css={downloadButtonText}>{t('download.button')}</span>
            </div>
          </a>
        )
      }
    </div>
  );
};
