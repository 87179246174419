import { useState, useEffect } from 'react';
import { parse, isAfter, isBefore, differenceInMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

interface IUseMaintenance {
  isUnderMaintenance: boolean | null;
  remainingTime: number;
}

const useMaintenance = (): IUseMaintenance => {
  /**
   * メンテナンス中かどうか
   * NOTICE:
   * 初期値をfalseにすると、本来はメンテナンス中であるはずの時間でも初期値から値がtrueに切り替わるまでの間に
   * メンテナンス中ではないと判定されてしまう.
   * そのため初期値をnullにし、呼び出し側でnullとfalseを区別して判定するようにしている.
   */
  const [isUnderMaintenance, setIsUnderMaintenance] = useState<boolean | null>(
    null,
  );
  /** メンテナンスの残り時間 */
  const [remainingTime, setRemainingTime] = useState<number>(1);

  useEffect(() => {
    // メンテナンス開始時間(JST)
    const maintenanceStartTime = parse(
      process.env.REACT_APP_MAINTENAICE_START_TIME!,
      'yyyy/MM/dd HH:mm',
      new Date(),
    );
    // メンテナンス終了時間(JST)
    const maintenanceEndTime = parse(
      process.env.REACT_APP_MAINTENAICE_END_TIME!,
      'yyyy/MM/dd HH:mm',
      new Date(),
    );
    // JSTの現在時刻
    const JSTDateTime = utcToZonedTime(new Date(), 'Asia/Tokyo');
    // 現在時刻がメンテナンス開始から終了の間に含まれるかどうか
    const isBetween =
      isAfter(JSTDateTime, maintenanceStartTime) &&
      isBefore(JSTDateTime, maintenanceEndTime);
    setIsUnderMaintenance(isBetween);

    // メンテナンス中の場合は、メンテナンス明けまでの残り時間を計算する
    if (isBetween) {
      const differenceTime = differenceInMinutes(
        maintenanceEndTime,
        JSTDateTime,
      );
      // 10分以下の場合は残り10分とする
      if (differenceTime <= 10) {
        setRemainingTime(10);

        return;
      }
      // 11~30分は30分,31~60分は60分のように30分刻みで残り時間を表示する
      setRemainingTime(Math.ceil(differenceTime / 30) * 30);
    }
  }, []);

  return { isUnderMaintenance, remainingTime };
};

export default useMaintenance;
