/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import { DownloadItem } from './DownloadItem';
import { downloadItemList } from '../../../constants/staticData';

const wrapper = css`
  display: flex;
  flex-wrap: wrap;
`;

const downloadItemWrapper = css`
  width: calc((100% - 40px * 4) / 5);
  padding-right: 40px;
  padding-bottom: 132px;
  :nth-of-type(5n) {
    padding-right: 0px;
  }
  ${MEDIA_QUERY.SP} {
    width: calc((100% - 5.3vw) / 2);
    padding-right: 5.3vw;
    padding-bottom: 40px;
    :nth-of-type(3n) {
      padding-right: 5.3vw;
    }
    :nth-of-type(even) {
      padding-right: 0px;
    }
  }
`;

export const DownloadList: React.FC = () => {
  /** 多言語文言用 */
  const { t } = useTranslation();

  return (
    <div css={wrapper}>
      {downloadItemList.map((item, i) => {
        return (
          <DownloadItem
            key={`${item.src}${i.toString()}`}
            src={item.src}
            img={item.img}
            name={t(item.name)}
            wrapper={downloadItemWrapper}
          />
        );
      })}
    </div>
  );
};
