/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { ProgressFilter } from './ProgressFilter';

interface IProgressBarProps {
  step1Sec: number;
  step2Sec: number;
  step3Sec: number;
  activeStepNum: number;
}

const wrapper = css`
  display: flex;
`;

/** TODO: #53 PC版のデザインができたら各エレメントにstyle追加する */
const progressBar = css`
  background: #eaeaea;
  border-radius: 4.5px;
  position: relative;
  height: 3px;
  width: 32%;

  &:nth-of-type(2) {
    margin: 0 2%;
  }
`;

export const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  const { step1Sec, step2Sec, step3Sec, activeStepNum } = props;

  return (
    <div css={wrapper}>
      <div css={progressBar}>
        {activeStepNum >= 1 && <ProgressFilter progress={step1Sec} />}
      </div>
      <div css={progressBar}>
        {activeStepNum >= 2 && <ProgressFilter progress={step2Sec} />}
      </div>
      <div css={progressBar}>
        {activeStepNum >= 3 && <ProgressFilter progress={step3Sec} />}
      </div>
    </div>
  );
};
