/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import useMedia from 'hooks/useMedia';
import { Button } from './Button';

interface IFixedButtonProps {
  handleClick: () => void;
  disable?: boolean;
  hasSkip?: boolean;
  handleSkip?: () => void;
}

const wrapper = css`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 35px 0;
  background-color: #000000;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.SP} {
    border: none;
    width: calc(100% - 20px * 2);
    position: fixed;
    padding: 15px 20px;
    background-color: #222422;
    text-align: center;
  }
`;

const wrapperHasSkip = css`
  ${wrapper}
  ${MEDIA_QUERY.SP} {
    justify-content: space-between;
  }
`;

const skip = css`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  margin-left: 20vw;

  ${MEDIA_QUERY.TB} {
    margin-left: 120px;
  }
  ${MEDIA_QUERY.SP} {
    position: relative;
    margin-left: 0;
    height: auto;
  }
`;

export const FixedButton: React.FC<IFixedButtonProps> = (props) => {
  /** 多言語文言用 */
  const { t } = useTranslation();
  const { handleClick, disable, hasSkip, handleSkip } = props;
  const { isSP } = useMedia();

  // skipがある時、widthを調整
  const width = (() => {
    if (isSP) {
      return '55%';
    }

    return '22%';
  })();

  return (
    <div css={hasSkip ? wrapperHasSkip : wrapper}>
      {hasSkip && (
        <div css={skip} onClick={handleSkip}>
          <p>{t('skip')}</p>
        </div>
      )}
      {hasSkip ? (
        <Button
          text={t('next')}
          handleClick={handleClick}
          disable={disable}
          width={width}
        />
      ) : (
        <Button text={t('next')} handleClick={handleClick} disable={disable} />
      )}
    </div>
  );
};
