/**
 * スキャン後の画像関連のAPI
 */
import ITexture from 'types/Texture';
import Axios from 'utils/AxiosUtils';
import { format } from 'date-fns';
import { ITexturesRequest } from '../types/api/request/texture.d';
import { ITextureResponse } from '../types/api/response/texture.d';
import { TEXTURE_GET_LIMIT } from '../constants/api';

/**
 * スキャン後の画像一覧取得API
 * @param targetHour 何時間前まで取得するか
 */
export const getTexturesAPI = async (
  targetHour: number,
  improveCache?: boolean,
): Promise<Array<ITexture>> => {
  // params用の取得範囲の定義（直近1時間の画像を取得する仕様）
  const now: Date = improveCache ? new Date(new Date().setSeconds(0)) : new Date();
  // TODO 撮影のため一旦24時間前まで遡る
  const from: number = new Date(now).setHours(now.getHours() - targetHour);
  const to: number = now.getTime();

  const response: Array<ITexture> = await Axios.get('textures', {
    params: {
      locationId: process.env.REACT_APP_LOCATION_ID,
      scanDateFrom: Math.floor(from / 1000).toString(),
      scanDateTo: Math.floor(to / 1000).toString(),
      limit: TEXTURE_GET_LIMIT,
      offset: 0,
    } as ITexturesRequest,
  })
    .then((res) => {
      return res.data.textures.map((texture: ITextureResponse) => {
        const formattedDate: string = format(
          new Date(texture.time * 1000),
          'MM.dd hh:mm a',
        );

        return {
          id: texture.textureId,
          imageUrl: texture.textureUrl,
          date: formattedDate,
          cityName: texture.cityName,
          name: texture.nickname,
        };
      });
    })
    .catch((error) => {
      throw error;
    });

  return response;
};

/**
 * スキャン後の画像一件取得API
 */
export const getTextureAPI = async (textureId: string): Promise<ITexture> => {
  const response: ITexture = await Axios.get(`textures/${textureId}`)
    .then((res) => {
      const texture: ITextureResponse = res.data;

      const formattedDate: string = format(
        new Date(texture.time * 1000),
        'MM.dd hh:mm a',
      );

      return {
        id: texture.textureId,
        imageUrl: texture.textureUrl,
        date: formattedDate,
        cityName: texture.celebrityName,
        name: texture.nickname,
      };
    })
    .catch((error) => {
      throw error;
    });

  return response;
};
