/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

const subTitle = css`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.45px;
  color: #ffffff;
  margin-top: 35px;
  ${MEDIA_QUERY.SP} {
    font-size: 17px;
    line-height: 1.41;
    letter-spacing: 0.47px;
    margin-top: 30px;
  }
`;

export const SubTitle: React.FC = (props) => {
  return <h2 css={subTitle}>{props.children}</h2>;
};
