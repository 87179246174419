/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { useTranslation } from 'react-i18next';
import { MDMotion, processContentsAnimation } from 'style/animation';
import { ProcessSection } from './ProcessSection';
import { UserAgentTabsSection } from './UserAgentTabsSection';
import { PROCESS_TYPE } from '../../../constants/enum';
import {
  iosProcess,
  androidProcess,
  printProcess,
} from '../../../constants/staticData';

interface IProcessSectionWrapperProps {
  userAgent: PROCESS_TYPE;
  changeAndroid: () => void;
  changeIos: () => void;
  changePrint: () => void;
}

const wrapper = css`
  padding: 30px 20vw 120px;
  width: calc(100vw - 20vw * 2);

  ${MEDIA_QUERY.TB} {
    padding: 30px 120px 120px;
    width: calc(100vw - 120px * 2);
  }

  ${MEDIA_QUERY.SP} {
    padding: 20px 5.3vw 0;
    width: calc(100vw - 5.3vw * 2);
    padding-bottom: 70px;
  }
`;

const tabSection = css`
  margin-bottom: 20px;
  width: 100%;
`;

const contentsInner = css`
  ${MEDIA_QUERY.PC} {
    display: flex;
    flex-wrap: wrap;
  }
  ${MEDIA_QUERY.TB} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const showAnimationWrapper = css`
  ${processContentsAnimation}
  animation: processContentsFadeIn 0.35s ${MDMotion};
  animation-fill-mode: both;
  margin-bottom: 60px;
  width: calc((100% - 60px) / 2);
  :nth-of-type(odd) {
    margin-right: 60px;
  }
  ${MEDIA_QUERY.SP} {
    width: 100%;
  }
`;

export const ProcessSectionWrapper: React.FC<IProcessSectionWrapperProps> = (
  props,
) => {
  const { userAgent, changeAndroid, changeIos, changePrint } = props;
  /** 多言語文言用 */
  const { t } = useTranslation();

  return (
    <div css={wrapper}>
      <div css={tabSection}>
        <UserAgentTabsSection
          userAgent={userAgent}
          changeAndroid={changeAndroid}
          changeIos={changeIos}
          changePrint={changePrint}
        />
      </div>
      <div css={contentsInner}>
        {userAgent === PROCESS_TYPE.IOS &&
          iosProcess.map((data) => {
            return (
              <div css={showAnimationWrapper} key={`${data.id}${userAgent}`}>
                <ProcessSection
                  key={data.id}
                  id={data.id}
                  imgSrc={data.src}
                  text={t(data.text)}
                  icon={data.icon}
                />
              </div>
            );
          })}
        {userAgent === PROCESS_TYPE.ANDROID &&
          androidProcess.map((data) => {
            return (
              <div css={showAnimationWrapper} key={`${data.id}${userAgent}`}>
                <ProcessSection
                  key={data.id}
                  id={data.id}
                  imgSrc={data.src}
                  text={t(data.text)}
                  icon={data.icon}
                />
              </div>
            );
          })}
        {userAgent === PROCESS_TYPE.PRINT &&
          printProcess.map((data) => {
            return (
              <div css={showAnimationWrapper} key={`${data.id}${userAgent}`}>
                <ProcessSection
                  key={data.id}
                  id={data.id}
                  imgSrc={data.src}
                  text={t(data.text)}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
