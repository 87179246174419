/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import pinIcon from 'images/location/pin-icon.svg';
import searchIcon from 'images/location/search-icon.svg';
import { useTranslation } from 'react-i18next';
import { IGoogleMapIndex } from 'types/GoogleMap';
import { PageTransition } from 'components/partials';
import { GOOGLE_MAP_TYPE } from '../../../constants/enum';

const wrapper = css`
  width: 100%;
`;

const title = css`
  margin-bottom: 20px;
`;

const flex = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const iconImgWrapper = css`
  width: 12px;
  height: 16px;
  margin: 0 20px;
`;

const iconImg = css`
  width: 100%;
  height: 100%;
`;

const optionItem = css`
  ${flex}
  width: 100%;
  height: 53px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  box-sizing: border-box;
  font-size: 15px;

  &:last-of-type {
    border-top: none;
  }
`;

export const GoogleMapIndex: React.FC<IGoogleMapIndex> = (props) => {
  const { currentGoogleMapType, setCurrentGoogleMapType } = props;
  const { t } = useTranslation();

  const handleOpenNavigator = () => {
    setCurrentGoogleMapType(GOOGLE_MAP_TYPE.NAVIGATOR);
  };

  const handleOpenAutoComplete = () => {
    setCurrentGoogleMapType(GOOGLE_MAP_TYPE.AUTO_COMPLETE);
  };

  return (
    <PageTransition
      shouldMount={currentGoogleMapType === GOOGLE_MAP_TYPE.INDEX}
    >
      <div css={wrapper}>
        <div css={title}>
          <p>{t('location.how')}</p>
        </div>
        <ul>
          <li css={optionItem} onClick={handleOpenNavigator}>
            <div css={iconImgWrapper}>
              <img src={pinIcon} css={iconImg} alt="pin-icon" />
            </div>
            <p>{t('location.navigate')}</p>
          </li>
          <li css={optionItem} onClick={handleOpenAutoComplete}>
            <div css={iconImgWrapper}>
              <img src={searchIcon} css={iconImg} alt="pin-icon" />
            </div>
            <p>{t('location.auto_complete')}</p>
          </li>
        </ul>
      </div>
    </PageTransition>
  );
};
