/** @jsx jsx
 * Artist
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import teamlab from 'images/top/logo.png';
import linkIcon from 'images/top/external-link-icon.svg';
import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

const artist = css`
  margin: 100px 18.5vw 100px 16.6vw;
  ${MEDIA_QUERY.SP} {
    margin: 70px 20px;
  }
`;

const artistTitle = css`
  margin-left: 174px;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.41;
  letter-spacing: 0.47px;
  ${MEDIA_QUERY.SP} {
    margin-left: 0;
  }
`;

const artistContents = css`
  display: flex;
  ${MEDIA_QUERY.SP} {
    display: block;
  }
`;

const logoArea = css`
  margin: auto 0;
  width: 74px;
  ${MEDIA_QUERY.SP} {
    width: 60px;
    margin: 40px 0 0;
  }
`;

const logo = css`
  width: 74px;
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.7;
    }
  }
  ${MEDIA_QUERY.SP} {
    width: 60px;
  }
`;

const artistInfoArea = css`
  padding-left: 100px;
  ${MEDIA_QUERY.SP} {
    padding-left: 0;
  }
`;

const artistInfoTitle = css`
  display: block;
  margin-top: 27px;
  font-size: 15px;
  line-height: 2;
  ${MEDIA_QUERY.SP} {
    margin-top: 20px;
  }
`;

const artistInfoText = css`
  margin-top: 5px;
  font-size: 13px;
  line-height: 2;
  white-space: pre-wrap;
  ${MEDIA_QUERY.SP} {
    margin-top: 15px;
  }
`;

const artistLinkArea = css`
  font-size: 13px;
`;

const artistLink = css`
  width: fit-content;
  margin-right: 5px;
`;

const artistInfoLinkArea = css`
  display: inline-flex;
  width: fit-content;
  opacity: 1;
  cursor: pointer;
  ${MEDIA_QUERY.PC} {
    &:hover {
      opacity: 0.5;
    }
  }
`;

const artistInfoLink = css`
  display: block;
  font-size: 13px;
  line-height: 13px;
`;

// SVGアイコンが縦長なので高さを長くしつつ縦移動させて調整している
const linkIconCss = css`
  width: 20px;
  height: 27px;
  margin-left: 4px;
`;

export const Artist: React.FC = memo(() => {
  /** 多言語文言用 */
  const { t } = useTranslation();

  return (
    <div css={artist} id="artist">
      <h2 css={artistTitle}>{t('artist')}</h2>
      <div css={artistContents}>
        <div css={logoArea}>
          <a
            href={`https://www.teamlab.art/${i18n.language}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={teamlab} alt="logo" css={logo} />
          </a>
        </div>
        <div css={artistInfoArea}>
          <span css={artistInfoTitle}>{t('teamlab')}</span>
          <p css={artistInfoText}>{t('top.artist.artist_info')}</p>
          <div css={artistLinkArea}>
            <span style={{ marginRight: '5px' }}>
              {t('top.artist.artist_represented')}
            </span>
            <a
              css={artistLink}
              href="https://www.pacegallery.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div css={artistInfoLinkArea}>
                <span css={artistInfoLink}>
                  {t('top.artist.artist_pace_gallery_link')}
                </span>
                <img src={linkIcon} css={linkIconCss} alt="icon" />
              </div>
            </a>
            <a
              css={artistLink}
              href="https://www.martinbrownecontemporary.com/ArtistteamLab.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div css={artistInfoLinkArea}>
                <span css={artistInfoLink}>
                  {t('top.artist.artist_martin_browne_contemporary_link')}
                </span>
                <img src={linkIcon} css={linkIconCss} alt="icon" />
              </div>
            </a>
            <a
              css={artistLink}
              href="http://ikkan-art.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div css={artistInfoLinkArea}>
                <span css={artistInfoLink}>
                  {t('top.artist.artist_ikkan_art_international_link')}
                </span>
                <img src={linkIcon} css={linkIconCss} alt="icon" />
              </div>
            </a>
            <br />
            <br />
            <a
              css={artistLink}
              href="https://www.dropbox.com/s/1s7y8vh2b2w5td8/teamLab%20Profile%20Art%20Jp.docx?dl=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div css={artistInfoLinkArea}>
                <span css={artistInfoLink}>
                  {t('top.artist.artist_biographical_documents_link')}
                </span>
                <img src={linkIcon} css={linkIconCss} alt="icon" />
              </div>
            </a>
            <br />
            <br />
            <a
              css={artistLink}
              href={`https://www.teamlab.art/${i18n.language}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div css={artistInfoLinkArea}>
                <span css={artistInfoLink}>{t('top.artist.artist_link')}</span>
                <img src={linkIcon} css={linkIconCss} alt="icon" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
