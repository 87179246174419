/** @jsx jsx */
import React, { useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import {
  HeadingSection,
  FixedButton,
  PageTransition,
} from 'components/partials';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNickname from 'hooks/useNickname';
import { useSelector, useDispatch } from 'react-redux';
import useImageUpload from 'hooks/useImageUpload';
import { setUploadError } from 'store/scan/operations';
import { RootState } from 'store/store';
import { PAGE_PATH } from 'constants/enum';
import { NicknameForm } from '../../components/pages/nickname/NicknameForm';
import { PAINT_PAGE } from '../../constants/enum';

interface INicknameProps {
  currentPage: PAINT_PAGE;
}

const container = css`
  height: 100%;
`;

const wrapper = css`
  padding: 75px 20vw 120px;
  width: calc(100% - 20vw * 2);
  padding-bottom: 70px;

  ${MEDIA_QUERY.TB} {
    padding: 75px 120px 120px;
    width: calc(100% - 120px * 2);
  }

  ${MEDIA_QUERY.SP} {
    padding: 50px 8vw;
    width: calc(100% - 8vw * 2);
  }
`;

const showFixedButton = css`
  display: block;
`;

const hideFixedButton = css`
  display: none;
`;

const Nickname: React.FC<INicknameProps> = (props) => {
  const dispatch = useDispatch();
  const { currentPage } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const {
    nickname,
    isValidNickname,
    isLengthValidNickname,
    handleNicknameChange,
    formatNickname,
    isErrorName,
    setIsErrorName,
  } = useNickname();

  const { uploadImageMeta, uploadImage } = useImageUpload();

  const imageId = useSelector((state: RootState) => state.scan.imageId);
  const cityName = useSelector((state: RootState) => state.image.cityName);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadImageMetaError, setUploadImageMetaError] = useState<boolean>(
    true,
  );

  const execNext = useCallback(
    async (fixedNickname: string) => {
      // 送信中の連打を防ぐ
      if (isUploading) {
        return;
      }

      setIsUploading(true);

      try {
        // 画像のメタ情報をuploadする
        const imageMeta = await uploadImageMeta(fixedNickname, cityName);

        // 画像アップロードAPIはレスポンスを待たずに画面遷移する(結果の問い合わせは次の画面でpollingするため)
        uploadImage(imageMeta.imageId, imageMeta.nickname, imageMeta.cityName);
        setIsUploading(false);

        history.push(PAGE_PATH.LIVE);
      } catch (e) {
        setIsUploading(false);

        if (e?.status === 400) {
          setIsErrorName(true);
        } else {
          setUploadImageMetaError(true);
          dispatch(setUploadError(true));

          history.push(PAGE_PATH.LIVE);
        }
      }
    },
    [
      cityName,
      dispatch,
      history,
      isUploading,
      setIsErrorName,
      uploadImage,
      uploadImageMeta,
    ],
  );

  const handleClick = useCallback(() => {
    const formattedNickname = formatNickname(nickname);
    execNext(formattedNickname);
  }, [execNext, formatNickname, nickname]);

  const handleSkip = useCallback(() => {
    // skip時はニックネームを空白で実行
    const fixedNickname = '';
    execNext(fixedNickname);
  }, [execNext]);

  return (
    <div css={container}>
      <PageTransition shouldMount={currentPage === PAINT_PAGE.NICKNAME}>
        <Prompt
          // エラー後の画面遷移時にページ遷移警告を出さないためにwhenを指定
          when={!imageId && !uploadImageMetaError}
          message={t('paint_paging_alert')}
        />
        <HeadingSection
          title={t('nickname.title')}
          contents={t('nickname.contents')}
        />
        <div css={wrapper}>
          <NicknameForm
            nickname={nickname}
            handleChange={handleNicknameChange}
            isValidNickname={isValidNickname}
            isLengthValidNickname={isLengthValidNickname}
            isErrorName={isErrorName}
          />
        </div>
      </PageTransition>

      <div
        css={
          currentPage === PAINT_PAGE.NICKNAME
            ? showFixedButton
            : hideFixedButton
        }
      >
        <FixedButton
          disable={isUploading || !isValidNickname || nickname === ''}
          handleClick={handleClick}
          hasSkip
          handleSkip={handleSkip}
        />
      </div>
    </div>
  );
};

export default Nickname;
