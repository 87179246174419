import { useState, useCallback } from 'react';
import ITexture from 'types/Texture';
import { getTextureAPI } from 'actions/TextureAction';

interface IUseTextureMe {
  texture: ITexture | null;
  loadTexture: (textureId: string) => Promise<void>;
}

const useTextureMe = (): IUseTextureMe => {
  /** アップロード完了したtexture一件の情報 */
  const [texture, setTexture] = useState<ITexture | null>(null);

  /**
   * Texture一件を取得する
   */
  const loadTexture = useCallback(async (textureId: string): Promise<void> => {
    try {
      const tex = await getTextureAPI(textureId);
      setTexture(tex);
    } catch (e) {
      // noop
    }
  }, []);

  return {
    texture,
    loadTexture,
  };
};

export default useTextureMe;
