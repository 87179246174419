import actionCreatorFactory from 'typescript-fsa';
import { PURGE } from 'redux-persist';

const actionCreator = actionCreatorFactory();

const ActionTypes = {
  SET_INITIAL: PURGE,
  SET_IMAGE_DATA: 'SET_IMAGE_DATA',
  SET_IMAGE_META: 'SET_IMAGE_META',
  SET_CITY_NAME: 'SET_CITY_NAME',
} as const;

/** アクション定義  */
export const actions = {
  setInitial: actionCreator(ActionTypes.SET_INITIAL),
  setImageData: actionCreator<string>(ActionTypes.SET_IMAGE_DATA),
  setCityName: actionCreator<string>(ActionTypes.SET_CITY_NAME),
  setImageMeta: actionCreator<{
    nickname: string;
    cityName: string;
  }>(ActionTypes.SET_IMAGE_META),
};
