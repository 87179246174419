import { useState, useCallback, Dispatch, SetStateAction } from 'react';

interface IUseNickname {
  /** ニックネーム */
  nickname: string;
  /** ニックネームの文字種別が正当かどうか */
  isValidNickname: boolean;
  /** ニックネームの文字数が正当かどうか */
  isLengthValidNickname: boolean;
  /** ニックネームをバリデーション判定をしてから値を保持する */
  handleNicknameChange: (text: string) => void;
  /** ニックネームの全角英数を半角英数に変換する */
  formatNickname: (text: string) => string;
  /** サーバ側で不適切なニックネームと判定されたかどうか */
  isErrorName: boolean;
  /** サーバ側で不適切なニックネームと判定されたかどうかの値を保持する */
  setIsErrorName: Dispatch<SetStateAction<boolean>>;
}

/** ニックネームの限界文字数 */
const VALID_NICKNAME_LENGTH = 13;

const useNickname = (): IUseNickname => {
  /** ニックネーム */
  const [nickname, setNickname] = useState<string>('');

  /**
   * ニックネームのバリデーション判定
   * 未入力を許容するので初期値をtrueにしている
   **/
  const [isValidNickname, setIsValidNickname] = useState<boolean>(true);

  /** ニックネームの文字数バリデーション判定 */
  const [isLengthValidNickname, setIsLengthValidNickname] = useState<boolean>(
    false,
  );

  /** サーバで弾かれた名前かどうか */
  const [isErrorName, setIsErrorName] = useState<boolean>(false);

  /**
   * ニックネームをバリデーション判定をしてから値を保持する
   */
  const handleNicknameChange = useCallback((text: string) => {
    setNickname(text);
    if (text.match(/^[A-Za-z0-9Ａ-Ｚａ-ｚ０-９]*$/)) {
      setIsValidNickname(true);
    } else {
      setIsValidNickname(false);
    }

    if (text.length > VALID_NICKNAME_LENGTH) {
      setIsLengthValidNickname(true);
    } else {
      setIsLengthValidNickname(false);
    }
  }, []);

  /**
   * 全角英数字を半角英数字に変換する
   */
  const formatNickname = useCallback((text: string): string => {
    return text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  }, []);

  return {
    nickname,
    isValidNickname,
    isLengthValidNickname,
    handleNicknameChange,
    formatNickname,
    isErrorName,
    setIsErrorName,
  };
};

export default useNickname;
