/** @jsx jsx */
import React, { useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Paint from 'containers/Paint';
import Live from 'containers/Live';
import Top from 'containers/Top';
import TermsOfUse from 'containers/TermsOfUse';
import { Header } from 'components/partials';
import i18n from 'locales/i18n';
import { PAGE_PATH } from 'constants/enum';
import { YoutubePopup } from 'components/partials/youtubePopup/YoutubePopup';
import { YourFlowerPopup } from 'components/partials/yourFlowerPopup/YourFlowerPopup';
import { SupervisedRoute } from 'components/partials/customRouter/SupervisedRoute';
import Textures from 'containers/Textures';
import {
  HEADER_HEIGHT_PC,
  HEADER_HEIGHT_SP,
  HEADER_HEIGHT_TB,
} from 'style/common';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import Maintenance from 'containers/Maintenance';
import useMaintenance from 'hooks/useMaintenance';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const app = css`
  height: 100%;
`;

const wrapper = css`
  padding-top: ${HEADER_HEIGHT_PC}px;
  position: initial;
  height: calc(100% - ${HEADER_HEIGHT_PC}px);
  ${MEDIA_QUERY.TB} {
    padding-top: ${HEADER_HEIGHT_TB}px;
    height: calc(100% - ${HEADER_HEIGHT_TB}px);
  }
  ${MEDIA_QUERY.SP} {
    padding-top: ${HEADER_HEIGHT_SP}px;
    height: calc(100% - ${HEADER_HEIGHT_SP}px);
  }
`;

const App: React.FC = () => {
  /** メンテナンス中かどうか */
  const { isUnderMaintenance } = useMaintenance();
  /** ハンバーガーメニューが開いているかどうか */
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
  /** Youtubeを鑑賞するポップアップが開いているかどうか */
  const [isYoutubePopupOpen, setIsYoutubePopupOpen] = useState<boolean>(false);
  /** YourFlowerポップアップが開いているかどうか */
  const [isYourFlowerPopupOpen, setIsYourFlowerPopupOpen] = useState<boolean>(
    false,
  );
  /** ヘッダが透明かどうか */
  const [isHeaderTransparent, setIsHeaderTransparent] = useState<boolean>(
    false,
  );
  /** ドメイン直後に言語がつく. 日本語なら/jp */
  const baseUrl = `/${i18n.language}`;

  const { t } = useTranslation();

  /** Youtubeを鑑賞するポップアップを開く */
  const openYoutubePopupCallback = useCallback(() => {
    setIsYoutubePopupOpen(true);
  }, []);

  /** Youtubeを鑑賞するポップアップを閉じる */
  const closeYoutubePopupCallback = useCallback(() => {
    setIsYoutubePopupOpen(false);
  }, []);

  /** YourFlowerを鑑賞するポップアップを開く */
  const openYourFlowerPopupCallback = useCallback(() => {
    setIsYourFlowerPopupOpen(true);
  }, []);

  /** YourFlowerを鑑賞するポップアップを閉じる */
  const closeYourFlowerPopupCallback = useCallback(() => {
    setIsYourFlowerPopupOpen(false);
  }, []);

  return (
    <div id="top" css={app}>
      <Helmet
        title={t('meta.title')}
        meta={[
          { name: 'description', content: t('meta.description') },
          { property: 'og:title', content: t('meta.title') },
          { property: 'og:description', content: t('meta.description') },
        ]}
      />

      <BrowserRouter basename={baseUrl}>
        <Header
          isHamburgerOpen={isHamburgerOpen}
          setIsHamburgerOpen={setIsHamburgerOpen}
          transparent={isHeaderTransparent}
          setTransparent={setIsHeaderTransparent}
          openYoutubePopup={openYoutubePopupCallback}
          openYourFlowerPopup={openYourFlowerPopupCallback}
        />
        <YoutubePopup
          isVisible={isYoutubePopupOpen}
          closeYoutubePopup={closeYoutubePopupCallback}
        />
        <YourFlowerPopup
          isVisible={isYourFlowerPopupOpen}
          closeYourFlowerPopup={closeYourFlowerPopupCallback}
        />
        <div css={wrapper}>
          <Switch>
            <Route path={PAGE_PATH.MAINTENANCE} exact>
              <Maintenance />
            </Route>
            <SupervisedRoute
              path={PAGE_PATH.PAINT}
              isUnderMaintenance={isUnderMaintenance}
            >
              <Paint />
            </SupervisedRoute>
            <SupervisedRoute
              path={PAGE_PATH.LIVE}
              isUnderMaintenance={isUnderMaintenance}
            >
              <Live openYoutubePopup={openYoutubePopupCallback} />
            </SupervisedRoute>
            <Route path={PAGE_PATH.FLOWERS}>
              <Textures openYoutubePopup={openYoutubePopupCallback} />
            </Route>
            <Route path={PAGE_PATH.TERMS_OF_USE}>
              <TermsOfUse />
            </Route>
            <Route path={PAGE_PATH.TOP}>
              <Top
                setIsHeaderTransparent={setIsHeaderTransparent}
                openYoutubePopup={openYoutubePopupCallback}
                openYourFlowerPopup={openYourFlowerPopupCallback}
              />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
