/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

interface IStepBarProps {
  width: number;
}

const wrapper = css`
  position: relative;
`;

// タブレットはヘッダが2段で高さが変動するのでpositonを固定しない
const stepBarOuter = css`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.25);
  ${MEDIA_QUERY.TB} {
    position: absolute;
    z-index: 0;
  }
`;

const stepBarInner = css`
  position: fixed;
  z-index: 999;
  background: #ffffff;
  height: 2px;
  transition: width 0.5s ease-in;
  ${MEDIA_QUERY.TB} {
    position: absolute;
    z-index: 0;
  }
`;

export const StepBar: React.FC<IStepBarProps> = (props) => {
  return (
    <div css={wrapper}>
      <div css={stepBarOuter}>
        <div css={stepBarInner} style={{ width: `${props.width}%` }} />
      </div>
    </div>
  );
};
