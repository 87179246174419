import { useState, useEffect, useCallback } from 'react';
import ITexture from 'types/Texture';
import { getTexturesAPI } from 'actions/TextureAction';
import { TEXTURE_GET_HOUR_RANGE, TEXTURE_GET_LIMIT } from '../constants/api';

interface IUseTexture {
  textures: Array<ITexture>;
  loadTextures: () => Promise<void>;
}

const useTexture = (): IUseTexture => {
  /** アップロードする画像のローカルでのパス */
  const [textures, setTextures] = useState<Array<ITexture>>([]);

  /**
   * 画像の一覧を取得する
   * 花束がlimitに至るまでリクエストし続ける仕様。
   * 設定値の最後でもlimit満たせないなら最後のレスポンスをいれる。
   */
  const loadTextures = useCallback(async (improveCache = false): Promise<void> => {
    const request = async (index: number) => {
      const result = await getTexturesAPI(TEXTURE_GET_HOUR_RANGE[index], improveCache);

      // 再リクエスト
      if (
        result.length < TEXTURE_GET_LIMIT &&
        index < TEXTURE_GET_HOUR_RANGE.length - 1
      ) {
        request(index + 1);
      } else {
        setTextures(result);
      }
    };

    request(0);
  }, []);

  useEffect(() => {
    loadTextures(true);
  }, [loadTextures]);

  return {
    textures,
    loadTextures,
  };
};

export default useTexture;
