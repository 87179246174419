import { useCallback, useState } from 'react';
import { RootState } from 'store/store';
import { useSelector, useDispatch } from 'react-redux';
import { incrementBouquetProgressTime } from 'store/scan/operations';
import { IBouquetsResponse } from '../types/api/response/bouquets.d';
import { getBouquetsAPI } from '../actions/BouquetsAction';
import { BOUQUETS_STATUS } from '../constants/enum';

interface IUseBouquetsCheck {
  bouquetsResponse: IBouquetsResponse;
  bouquetsStatus: BOUQUETS_STATUS;
  bouquetsCheck: (progressMillisecond: number) => Promise<void>;
}

const useBouquetsCheck = (): IUseBouquetsCheck => {
  const dispatch = useDispatch();

  const imageId = useSelector((state: RootState) => state.scan.imageId);

  const [bouquetsResponse, setBouquetsResponse] = useState<IBouquetsResponse>({
    bouquets: [],
  });

  const [bouquetsStatus, setBouquetsStatus] = useState<BOUQUETS_STATUS>(
    BOUQUETS_STATUS.WAITING,
  );

  const bouquetsCheck = useCallback(
    async (progressMillisecond: number): Promise<void> => {
      try {
        const response = await getBouquetsAPI(imageId);

        if (response.bouquets.length > 0) {
          setBouquetsStatus(BOUQUETS_STATUS.SUCCESS);
          setBouquetsResponse(response);

          return;
        }

        if (response.bouquets.length === 0) {
          setBouquetsStatus(BOUQUETS_STATUS.WAITING);

          const progressMinutes = progressMillisecond / 1000 / 60;
          dispatch(incrementBouquetProgressTime(progressMinutes));

          return;
        }
      } catch (e) {
        // TODO エラーの時どうするか決める
        console.error('e', e);
        setBouquetsStatus(BOUQUETS_STATUS.ERROR);
      }
    },
    [dispatch, imageId],
  );

  return {
    bouquetsResponse,
    bouquetsStatus,
    bouquetsCheck,
  };
};

export default useBouquetsCheck;
