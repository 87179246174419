/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { MDMotion } from 'style/animation';

interface IHamburgerIconProps {
  onClose: boolean;
}

const wrapper = css`
  display: block;
`;

const Bar = css`
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
`;

const firstBar = css`
  ${Bar}
  transform: rotate(0deg);
  transition: top 0.24s ${MDMotion}, transform 0.24s ${MDMotion};
`;

const firstBarOnClose = css`
  ${Bar}
  top: 8px;
  transform: rotate(-135deg);
  transition: top 0.24s ${MDMotion}, transform 0.24s ${MDMotion};
`;

const secondBar = css`
  ${Bar}
  top: 8px;
  opacity: 1;
  transition: opacity 0.24s;
`;

const secondBarOnClose = css`
  ${Bar}
  top: 8px;
  opacity: 0;
  transition: opacity 0.24s ${MDMotion};
`;

const thirdBar = css`
  ${Bar}
  bottom: 0;
  transform: rotate(0deg);
  transition: bottom 0.24s ${MDMotion}, transform 0.24s ${MDMotion};
`;

const thirdBarOnClose = css`
  ${Bar}
  bottom: 8px;
  transform: rotate(135deg);
  transition: bottom 0.24s ${MDMotion}, transform 0.24s ${MDMotion};
`;

export const HamburgerIcon: React.FC<IHamburgerIconProps> = (props) => {
  return (
    <div css={wrapper}>
      <div css={props.onClose ? firstBarOnClose : firstBar} />
      <div css={props.onClose ? secondBarOnClose : secondBar} />
      <div css={props.onClose ? thirdBarOnClose : thirdBar} />
    </div>
  );
};
