/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { loading } from 'style/animation';

const load = css`
  ${loading}
  width: 40px;
  height: 40px;
  border: 2px solid #3d3d3d;
  border-left-color: #fff;
  border-radius: 50%;
  animation: loading 1s linear 0s infinite;
`;

export const Loading: React.FC = () => {
  return <div css={load} />;
};
