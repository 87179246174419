/** @jsx jsx
 * SPで画面下部に固定表示する「鑑賞する」と「絵を描く」のボタン
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { PAGE_PATH } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { MDMotion } from 'style/animation';
import useMedia from 'hooks/useMedia';

interface IFixedLinkButtons {
  openYoutubePopup: () => void;
  isVisible: boolean;
}

const buttonArea = css`
  position: fixed;
  bottom: 0;
  z-index: 500;
  display: flex;
  width: calc(100vw - 30px * 2);
  margin: 0;
  padding: 15px 30px;
  cursor: pointer;
  background-color: #222422;
  text-align: center;
  animation: slideUpBottomButton 0.36s ${MDMotion};
  @keyframes slideUpBottomButton {
    0% {
      transform: translateY(70px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

const buttonAreaHidden = css`
  ${buttonArea}
  animation: none;
  opacity: 0;
  visibility: hidden;
`;

const linkButton = css`
  width: calc((100vw - 15px - 30px * 2) / 2);
  height: 40px;
  margin-left: 15px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.28);
  background-color: #ffffff;
  &:first-of-type {
    margin-left: 0;
  }
`;

const buttonText = css`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: center;
  color: #141414;
`;

export const FixedLinkButtons: React.FC<IFixedLinkButtons> = memo((props) => {
  /** ページ遷移用 */
  const history = useHistory();
  /** 多言語文言用 */
  const { t } = useTranslation();
  /** SP, TBサイズかどうか */
  const { isSP } = useMedia();

  return (
    <div css={props.isVisible ? buttonArea : buttonAreaHidden}>
      <div css={linkButton} onClick={() => history.push(PAGE_PATH.PAINT)}>
        <span css={buttonText}>{t('top.paint')}</span>
      </div>
      <div css={linkButton} onClick={() => props.openYoutubePopup()}>
        <span css={buttonText}>
          {isSP ? t('top.watch_short') : t('top.watch')}
        </span>
      </div>
    </div>
  );
});
