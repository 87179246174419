import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actions } from './action';
import { scanState } from './scanState';

/** reducer (アクション事にcaseで分岐し定義する) */
export const reducer = reducerWithInitialState(scanState)
  .case(actions.setInitial, () => ({
    ...scanState,
  }))
  .case(actions.setImageId, (state, imageId) => ({
    ...state,
    imageId,
  }))
  .case(actions.setUploaded, (state, uploaded) => ({
    ...state,
    uploaded,
  }))
  .case(actions.setUploadError, (state, uploadError) => ({
    ...state,
    uploadError,
  }))
  .case(actions.setScanned, (state, scanned) => ({
    ...state,
    scanned,
  }))
  .case(actions.setWait, (state, wait) => ({
    ...state,
    wait,
  }))
  .case(actions.incrementProgressTime, (state) => ({
    ...state,
    progressTime: state.progressTime + 1,
  }))
  .case(actions.setBouquetCreated, (state, bouquetCreated) => ({
    ...state,
    bouquetCreated,
  }))
  .case(actions.setBouquetWait, (state, bouquetWait) => ({
    ...state,
    bouquetWait,
  }))
  .case(
    actions.incrementBouquetProgressTime,
    (state, progressMinutes: number) => ({
      ...state,
      bouquetProgressTime: state.bouquetProgressTime + progressMinutes,
    }),
  )
  .case(actions.initializeScanState, () => ({
    ...scanState,
  }));
