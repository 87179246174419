/** @jsx jsx */
import React, { useState, useMemo, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { StepBar } from 'components/partials';
import { PAINT_PAGE } from '../constants/enum';
import Download from './paint/Download';
import Process from './paint/Process';
import Upload from './paint/Upload';
import Location from './paint/Location';
import Nickname from './paint/Nickname';

const stepBar = css`
  width: 100%;
  height: 1px;
  margin-bottom: 75px;
`;

const Paint: React.FC = () => {
  const [currentPageType, setCurrentPageType] = useState<PAINT_PAGE>(
    PAINT_PAGE.DOWNLOAD,
  );

  /** ページタイプによってステップバーの進み具合を変更する */
  const progressStepBar = useMemo(() => {
    switch (currentPageType) {
      case PAINT_PAGE.DOWNLOAD:
        return 20;
      case PAINT_PAGE.PROCESS:
        return 40;
      case PAINT_PAGE.UPLOAD:
        return 60;
      case PAINT_PAGE.LOCATION:
        return 80;
      default:
        return 100;
    }
  }, [currentPageType]);

  useEffect(() => {
    // currentPageの変更のたびにスクロール位置を一番上にする
    window.scrollTo(0, 0);
  }, [currentPageType]);

  return (
    <div>
      <div css={stepBar}>
        <StepBar width={progressStepBar} />
      </div>
      <div>
        <Download
          setCurrentPage={setCurrentPageType}
          currentPage={currentPageType}
        />
        <Process
          setCurrentPage={setCurrentPageType}
          currentPage={currentPageType}
        />
        <Upload
          setCurrentPage={setCurrentPageType}
          currentPage={currentPageType}
        />
        <Location
          currentPage={currentPageType}
          setCurrentPage={setCurrentPageType}
        />
        <Nickname currentPage={currentPageType} />
      </div>
    </div>
  );
};

export default Paint;
