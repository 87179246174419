/** @jsx jsx */
import React from 'react';
import LazyLoad from 'react-lazyload';
import { css, jsx, SerializedStyles } from '@emotion/core';
import ITexture from 'types/Texture';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';

interface ITextureProps {
  tex: ITexture;
  wrapper: SerializedStyles;
}

// TBのmargin, width, heightについてはデザインが無く、確認しながら調整したので計算式に深い意味はないです。
const imageWrapper = css`
  margin: auto;
  width: calc(97 / 1440 * 100vw);
  height: calc(149 / 1440 * 100vw);

  ${MEDIA_QUERY.TB} {
    margin: auto;
    width: calc(102 / 740 * 100vw);
    height: calc(157 / 740 * 100vw);
  }

  ${MEDIA_QUERY.SP} {
    margin: auto;
    width: calc(61 / 375 * 100vw);
    height: calc(94 / 375 * 100vw);
  }
`;

const image = css`
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const spanWrapper = css`
  display: inline-grid;
  margin-top: 30px;
`;

const span = css`
  opacity: 0.5;
  font-size: 12px;
  line-height: 1.58;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Texture: React.FC<ITextureProps> = (props) => {
  const { tex, wrapper } = props;

  return (
    <div css={wrapper}>
      <LazyLoad once height={200}>
        <div css={imageWrapper}>
          <img css={image} src={tex.imageUrl} alt="texture" />
        </div>
        <div css={spanWrapper}>
          <span css={span}>{tex.date}</span>
          <span css={span}>{tex.cityName}</span>
          <span css={span}>{tex.name}</span>
        </div>
      </LazyLoad>
    </div>
  );
};
