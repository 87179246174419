import { useState, useEffect, useCallback } from 'react';
import { SP_WIDTH, TB_WIDTH } from '../utils/MediaQueryUtils';

interface IUseMedia {
  isSP: boolean;
  isTB: boolean;
}
const spMedia: MediaQueryList = window.matchMedia(`(max-width: ${SP_WIDTH}px)`);

const tbMedia: MediaQueryList = window.matchMedia(`(max-width: ${
  TB_WIDTH - 1
}px)
      and (min-width: ${SP_WIDTH + 1}px)`);

/**
 * NOTICE: 正しいサイズの値がStateの値に入るまでに一瞬の間があることに注意する
 * 基本的には「SPのみこの要素を表示する」といった使い方を推奨
 */
const useMedia = (): IUseMedia => {
  /** SPサイズかどうか */
  const [isSP, setIsSP] = useState<boolean>(spMedia.matches);
  /** TBサイズかどうか */
  const [isTB, setIsTB] = useState<boolean>(tbMedia.matches);

  const checkWidth = useCallback(() => {
    setIsSP(spMedia.matches);
    setIsTB(tbMedia.matches);
  }, []);

  useEffect(() => {
    checkWidth();
    // 画面サイズが変わったときにサイズ判定を再評価する
    window.addEventListener('resize', checkWidth);
  }, [checkWidth]);

  return { isSP, isTB };
};

export default useMedia;
