/** @jsx jsx */
import React, { memo, useRef } from 'react';
import { ClassNames, css, jsx } from '@emotion/core';
import { pageTransitionFadeIn, pageTransitionFadeOut } from 'style/animation';
import { CSSTransition } from 'react-transition-group';

const transitionFadeIn = css(pageTransitionFadeIn);
const transitionFadeOut = css(pageTransitionFadeOut);
const transitionContentsWrapper = css`
  width: 100%;
  height: 100%;
`;

interface IFadeInOutTransitionProps {
  // マウントフラグ
  shouldMount: boolean;
  // 初回画面フラグ(初回のみfadeInアニメーションの)
  shouldAppear?: boolean;
}

export const FadeInOutTransition: React.FC<IFadeInOutTransitionProps> = memo(
  (props) => {
    const { shouldMount, shouldAppear } = props;

    // transitionアニメーションを参照するエレメントを参照する
    const targetRef = useRef(null);

    return (
      <ClassNames>
        {({ css: className }) => (
          <CSSTransition
            appear={shouldAppear}
            // マウント条件
            in={shouldMount}
            // 参照するDOMを指定
            nodeRef={targetRef}
            timeout={1000}
            classNames={{
              appearActive: className(transitionFadeIn),
              enterActive: className(transitionFadeIn),
              exitActive: className(transitionFadeOut),
            }}
            // inがtrueになるまでマウントしない
            mountOnEnter
            // inがfalseになったらアンマウントする
            unmountOnExit
          >
            <div css={transitionContentsWrapper} ref={targetRef}>
              {props.children}
            </div>
          </CSSTransition>
        )}
      </ClassNames>
    );
  },
);
