/** @jsx jsx */
import React, { memo, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import useMaintenance from 'hooks/useMaintenance';
import { useHistory } from 'react-router-dom';
import { PAGE_PATH } from 'constants/enum';
import { MEDIA_QUERY } from 'utils/MediaQueryUtils';
import { MDMotion } from 'style/animation';

const wrapper = css`
  margin-top: 86px;
  ${MEDIA_QUERY.SP} {
    margin-top: 60px;
  }
`;

const textArea = css`
  text-align: center;
  ${MEDIA_QUERY.SP} {
    margin: 0 30px;
  }
`;

const text = css`
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 2;
`;

const button = css`
  margin: 30px auto 0;
  width: 170px;
  height: 45px;
  border: solid 1px rgba(255, 255, 255, 0.3);
  text-align: center;
  ${MEDIA_QUERY.PC} {
    transition: all 0.36s ${MDMotion};
    cursor: pointer;
    &:hover {
      background-color: #fff;
      color: #0e0e0e;
      border-color: #fff;
    }
  }
`;

const buttonText = css`
  line-height: 45px;
  font-size: 13px;
  letter-spacing: 0.35px;
`;

const Maintenance: React.FC = memo(() => {
  /** メンテナンス中かどうか, 残り時間 */
  const { isUnderMaintenance, remainingTime } = useMaintenance();
  /** 多言語文言用 */
  const { t } = useTranslation();
  /** ページ遷移用 */
  const history = useHistory();

  /** メンテナンス中ではない時はメンテナンスページを開かない */
  useEffect(() => {
    /**
     * NOTICE:
     * isUnderMaintenanceは初期値にnullが入り、メンテナンス中ではないと判定されたあとはfalseが入る
     * そのため「メンテナンス中ではない」状態はnullではないかつfalseのとき
     */
    if (isUnderMaintenance !== null && !isUnderMaintenance) {
      history.push(PAGE_PATH.TOP);
    }
  }, [history, isUnderMaintenance]);

  return (
    <div css={wrapper}>
      <div css={textArea}>
        <span css={text}>{t('maintenance_error', { remainingTime })}</span>
      </div>
      <div css={button} onClick={() => history.push(PAGE_PATH.TOP)}>
        <span css={buttonText}>{t('back_to_top')}</span>
      </div>
    </div>
  );
});

export default Maintenance;
