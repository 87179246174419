/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

interface IProgressFilterProps {
  progress: number;
}

/** TODO: #53 PC版のデザインができたら各エレメントにstyle追加する */
const filter = (progress: number) => css`
  background: #000;
  border-radius: inherit;
  height: 100%;
  animation: move ${progress}s linear;
  animation-fill-mode: both;
  @keyframes move {
    0% {
      width: 0;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }
`;

// propで%の値をもらってwidth当ててあげる
export const ProgressFilter: React.FC<IProgressFilterProps> = (props) => {
  const { progress } = props;

  /* progressの値の変更でsnackBarの進捗を表す(80%を最大値とする) */
  return <div css={filter(progress)} />;
};
