/** @jsx jsx
 * Exhibition
 */
import React, { memo } from 'react';
import { jsx, css } from '@emotion/core';
import linkIcon from 'images/top/external-link-icon-gray.svg';
import { useTranslation } from 'react-i18next';

const content = css`
  width: 68%;
  margin: 0 auto;
`;

const divider = css`
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const grid = css`
  margin: 60px 0;
  display: grid;
  grid-template-columns: 23% 18% 1fr;
`;

const primaryItem = css`
  font-size: 17px;
  font-weight: bold;
  grid-column: 1;
`;

const secondaryItem = css`
  font-size: 15px;
  font-weight: bold;
  grid-column: 2;
`;

const tertiaryItem = css`
  font-size: 15px;
  grid-column: 3;
`;

const tertiaryItemBold = css`
  ${tertiaryItem}
  font-weight: bold;
`;

const margin = css`
  grid-column: 1 / 4;
  height: 40px;
`;

const flex = css`
  display: flex;
  align-items: center;
`;

const linkIconCss = css`
  width: 12px;
  height: 12px;
  margin-left: 7px;
`;

const hover = css`
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.5;
  }
`;

export const PCExhibition: React.FC = memo(() => {
  const c = 'top.exhibition_content';
  const { t } = useTranslation();

  return (
    <div css={content}>
      <div css={divider} />
      <div css={grid}>
        <div css={primaryItem}>{t(`${c}.info`)}</div>
        <div css={secondaryItem}>{t(`${c}.label_1`)}</div>
        <div css={tertiaryItem}>
          <div>{t(`${c}.name_1`)}</div>
        </div>
        <div css={tertiaryItemBold}>
          <div>{t(`${c}.name_2`)}</div>
        </div>
        <div css={margin} />
        <div css={secondaryItem}>{t(`${c}.label_2`)}</div>
        <div css={tertiaryItem}>
          <div>{t(`${c}.date`)}</div>
        </div>
      </div>
      <div css={divider} />
      <div css={grid}>
        <div css={primaryItem}>{t(`${c}.inquiry`)}</div>
        <div css={secondaryItem}>{t(`${c}.label_3`)}</div>
        <a
          href="https://www.dropbox.com/sh/djtx2ixfg2depad/AAC2PolKFYeXipEmADAJ9d4Ta?dl=0"
          target="_blank"
          rel="noopener noreferrer"
          css={hover}
        >
          <div css={tertiaryItem}>
            <div css={flex}>
              <span>{t(`${c}.press_kit`)}</span>
              <img src={linkIcon} css={linkIconCss} alt="icon" />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
});
